import React, { useEffect,useState} from 'react'
import { useNavigate } from "react-router-dom"
import { getRequest } from '../services/requests'
import moment from "moment";
const MembershipHomePage = () =>{
    const [userDetails,setUserDetails] = useState(null)
    const navigate = useNavigate()

    useEffect(() =>{
        getAuth()
    },[])

    const getAuth =  async() =>{
        try {
          let authUser = await getRequest("get-authenticated-user-membership") 
            if(authUser.data.status === false){
                navigate('/membership')
            }else{
                setUserDetails(authUser.data.data2)
            }
        } catch (error) {
            navigate('/membership')
        }
}
    return (
        <React.Fragment>
          
               <div className='row g-0'>
                    <div className='col-md-6'>
                        <div>
                        <div className='home-page-info-box'>
                            <h5 style={{'marginTop':'10px','color':'white'}}>Welcome {userDetails !== null ? userDetails.firstName+" "+userDetails.lastName : '..........'}</h5>
                        </div>
                            <div className='home-page-info-box'>
                            <span><b>Membership Plan: </b>{userDetails !== null ? userDetails.MembershipPlan.membershipPlan : ''}</span>
                            </div>
                            <div className='home-page-info-box'>
                            <span><b>Membership Status: </b> {userDetails !== null ? userDetails.membershipStatus === 0 ? <span style={{'color':'red'}}>Inactive</span> : <span style={{'color':'pink'}}>Active</span> : ''} </span>
                            </div>
                            <div className='home-page-info-box'>
                            <span><b>Membership Expiration: </b> <br/> {userDetails !== null ? moment(userDetails.membershipExpiration).format('MMMM Do YYYY')  : '..........'}</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='membership-home d-none d-sm-none d-md-block d-lg-block d-xl-block'>
                            
                        </div>
                    </div>
               </div>
        </React.Fragment>
    )
}

export default MembershipHomePage