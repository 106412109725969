import { useRef } from "react"
import { useState } from "react"
export const AdminResponsiveSideNav = (props) =>{
    const [navigationIcon,setNavigationIcon] = useState(false)
    const thisSideNav = useRef(null)

    const handleNavigationChange = () =>{
        if(navigationIcon === false){
            setNavigationIcon(true)
            thisSideNav.current.style.width = "200px"
        }else{
            setNavigationIcon(false)
            thisSideNav.current.style.width = "0"
        }
    }
    return(
        <div className="admin-side-nav-responsive" ref={thisSideNav}>
            <div className="admin-side-options" onClick={props.switch.home}><i className="bi bi-house"></i> Home</div>
            <div className="admin-side-options" onClick={props.switch.chalets}><i className="bi bi-houses"></i> Chalets</div>
            <div className="admin-side-options" onClick={props.switch.reservations}><i className="bi bi-journals"></i> Reservations</div>
            <div className="admin-side-options" onClick={props.switch.payments}><i className="bi bi-credit-card-fill"></i> Payments</div>
            <div className="admin-side-options" onClick={props.switch.events}><i className="bi bi-calendar-event"></i> Events</div>
            <div className="admin-side-options" onClick={props.switch.food}><i className="bi bi-cup-straw"></i> Food Menu</div>
            <div className="admin-side-options" onClick={props.switch.mealOrder}><i className="bi bi-list"></i> Meal Orders</div>
            <div className="admin-side-options" onClick={props.switch.visit}><i className="bi bi-list-stars"></i> Visist Reservations</div>
            <div className="admin-side-options"><i className="bi bi-scooter"></i> Activities</div>
            <div className="admin-side-options"><i className="bi bi-gear"></i> Settings</div>
            <div className="admin-side-options"><i className="bi bi-power"  onClick={props.logout}></i> Sign Out</div>
            <div id="floating-menu-icon" className="d-block d-sm-block d-md-block d-lg-none d-xl-none">
                    {navigationIcon === false ? <i className="bi bi-list d-block d-sm-block d-md-block d-lg-none d-xl-none" id="menu-button-admin" onClick={handleNavigationChange}></i> : <i onClick={handleNavigationChange} className="bi bi-x-lg d-block d-sm-block d-md-block d-lg-none d-xl-none"></i>}
            </div>
        </div>
    )
}