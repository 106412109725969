import cogoToast from "cogo-toast"
import { useEffect, useRef, useState } from "react"
import { getRequest } from "../../services/requests"
import { SkeletonLoader } from "../../components/skeletonLoader"
import { useNavigate } from "react-router-dom"

const PaymentPage = () =>{
    const navigate = useNavigate()
    
    const [payments,setPayments] = useState(null)
    const [lastRecord,setLastRecord] = useState(null)
    const loadButton = useRef()

    useEffect(()=>{
        getAuth()
        fetchPaymentRecords()
    },[])

    const getAuth = async () =>{
        try {
          let authUser = await getRequest("get-authenticated-user") 
            if(authUser.data.status === false){
                navigate('/admin/index/')
            }
        } catch (error) {
            navigate('/admin/index/')
        }
    }

    const fetchPaymentRecords = () =>{
        getRequest('fetch-payment-records').then((response)=>{
            if(response.data.status === true){
                let responseData = response.data.reservations
                let lastElement = responseData[responseData.length -1]
                setLastRecord(lastElement.id)
                setPayments(response.data.reservations)
            }else{
                cogoToast.error(response.data.message,{
                    position:'bottom-right'
                })
            }
        }).catch((error)=>{
            if(error.response.data.status === false){
                cogoToast.error(error.response.data.message,{
                    position:'bottom-right'
                             }); 
            }
        })
    }

    const loadMore = () =>{
        if(lastRecord > 1){
                
            loadButton.current.childNodes[0].style.display = "none"
            loadButton.current.childNodes[1].style.display = "block"

            getRequest('load-more-payment-records',lastRecord).then((response)=>{
                if(response.data.status === true){
                    loadButton.current.childNodes[0].style.display = "block"
                    loadButton.current.childNodes[1].style.display = "none"
                    setPayments([...payments,...response.data.reservations])
                    let lastElement = response.data.reservations[response.data.reservations.length -1]
                setLastRecord(lastElement.id)
                }else{
                    loadButton.current.childNodes[0].style.display = "block"
                    loadButton.current.childNodes[1].style.display = "none"
                }
            }).catch(()=>{
                loadButton.current.childNodes[0].style.display = "block"
                loadButton.current.childNodes[1].style.display = "none"
            })
        }
    }
    let Naira = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
      });
        return (
            <div className="admin-views-container">
            <div className="row">
                <div className="col-md-12">
                <div className="inner-admin-container">
                    <br/>
                    <h3>Payments</h3>
                    <hr/>
                    <input type="text" placeholder="search"/>
                    <div className="admin-tables-box">
                    <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    {/* <th>SN</th> */}
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email Address</th>
                                <th>Phone Number</th>
                                <th>Payment Reference</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Currency</th>
                                <th>Channel</th>
                                <th>Date Inititated</th>
                                </tr>
                            </thead>
                            <tbody>
                                {payments === null ? 
                                   <>
                                   <td> <SkeletonLoader spec={{"count":10}}/></td>
                                   <td> <SkeletonLoader spec={{"count":10}}/></td>
                                   <td> <SkeletonLoader spec={{"count":10}}/></td>
                                   <td> <SkeletonLoader spec={{"count":10}}/></td>
                                   <td> <SkeletonLoader spec={{"count":10}}/></td>
                                   <td> <SkeletonLoader spec={{"count":10}}/></td>
                                   <td> <SkeletonLoader spec={{"count":10}}/></td>
                                   <td> <SkeletonLoader spec={{"count":10}}/></td>
                                   <td> <SkeletonLoader spec={{"count":10}}/></td>
                                   <td> <SkeletonLoader spec={{"count":10}}/></td>
                               </>
                                : payments.map((item,key)=>{
                                    return <tr> 
                                        {/* <td>{}</td> */}
                                         <td>
                                        {item.firstName}
                                    </td>
                                    <td>
                                        {item.lastName}
                                    </td>
                                    <td>
                                        {item.email}
                                    </td>
                                    <td>
                                        {item.phoneNumber}
                                    </td>
                                    <td>
                                        <span style={{"color":"#41176e","fontWeight":"bold"}}>{item.Payment.paymentReference}</span>
                                    </td>
                                    <td>
                                        {Naira.format(item.Payment.amount)}
                                    </td>
                                    <td>
                                        {item.Payment.status === "completed" ? <i className="bi bi-check-circle" style={{"color":"green","fontWeight":"bold"}}> {item.Payment.status}</i>: <span><i className="bi bi-clock" style={{"color":"#574904","fontWeight":"bold"}}> {item.Payment.status}</i></span>}
                                    </td>
                                    <td>
                                    {item.Payment.currency}
                                    </td>
                                    <td>
                                    {item.Payment.channel}
                                    </td>

                                    <td>{new Date(item.createdAt).toDateString()}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                        <div ref={loadButton}>
                        <i className="bi bi-arrow-clockwise load-more-button" onClick={loadMore} id="paymentLoadMore">Load More</i>
                        <div className="spinner-border" role="status" style={{"display":"none"}}><span className="visually-hidden">Loading...</span></div>
                        </div>
                        <br/><br/>
                </div>
                </div>
                </div>
                </div>
        )
}

export default PaymentPage