import {Routes,Route, BrowserRouter} from 'react-router-dom'
import App from '../App'
import LandingPage from '../pages/landingPage'
import ReservationsPage from '../pages/reservationsPage'
import AdminIndex from '../Admin/pages'
import Dashboard from '../Admin/pages/dashboard'
import { Activities } from '../pages/activitiesPage'
import { ChaletsPage } from '../pages/chaletsPage'
import {RestaurantAndBar} from '../pages/restaurantAndBar'
import { ContactUs } from '../pages/contactUs'
import TablesReservationPage from '../pages/TablesReservationPage'
import { Eventspage } from '../pages/eventsPage'
import { ConfirmAttendance } from '../pages/eventReservationConfirmation'
import { OrganizerConfirmAttendance } from '../pages/organizerConfirmAttendance'
import { EventContribution } from '../pages/eventContribution'
import MenuPage from '../pages/menuPage'
import Membership from '../pages/membership'
import { MembershipDashboard } from '../pages/membershipDashboard'
import VisitYolo from '../pages/visitYolo'
import { CurrentEvent } from '../pages/currentEvent'

const RouteComponent = () =>{
    return(
        <BrowserRouter>
             <Routes>
        <Route path='/' index element={ <LandingPage/> }/>
        <Route path="/reservations/" element={<ReservationsPage/>}/>
        <Route path="/tables/" element={<TablesReservationPage/>}/>
        <Route path="/events/" element={<Eventspage/>}/>
        <Route path='/admin/index/' element={<AdminIndex/>}/>
        <Route path="/admin/dashboard/" element={<Dashboard/>}/>
        <Route path='/activities' element={<Activities/>}/>
        <Route path='/chalets' element={<ChaletsPage/>}/>
        <Route path='/contact-us' element={<ContactUs/>}/>
        {/* <Route path='/menu' element={<MenuPage/>}/> */}
        <Route path='/membership' element={<Membership/>}/>
        <Route path='/visit-yolo' element={<VisitYolo/>}/>
        <Route path='/restaurantandbar' element={<RestaurantAndBar/>}/>
        <Route path='/confirm_attendance/:eventId' element={<ConfirmAttendance/>}/>
        <Route path='/organizer_confirm_attendance/:emailAddress/:emailAddress2/:eventId/:eventId2' element={<OrganizerConfirmAttendance/>}/>
        <Route path='/event-contribution/:eventId' element={<EventContribution/>}/>
        <Route path='/membership-dashboard' element={<MembershipDashboard/>}/>
        {/* <Route path='/current-event' element={<CurrentEvent/>}/> */}
            </Routes>
        </BrowserRouter>
    )
}

export default RouteComponent  