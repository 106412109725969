import React,{useEffect,useState,useRef} from "react"
import moment from 'moment/moment';
import defaultProfileImage from '../images/profile.jpg'
import { getRequest, postRequest } from "../services/requests";
import cogoToast from "cogo-toast";
import DatePicker from "react-datepicker";

const ProfilePage = (props) =>{
    
    const [userDetails,setUserdetails] = useState(null)

    const [controlledProfileData,setControlledProfileData] = useState({
        'firstName':'',
        'middleName':'',
        'lastName':'',
        'dataOfBirth':''
    })

    const updateLoaderRef = useRef(null)
    const updateButtonRef = useRef(null)
    const [DOB, setDOB] = useState(null);

    const [gender,setGender] = useState(null)

        useEffect(() =>{
        setUserdetails(props.profile)
        fetchGender()
    },[])

const fetchGender = async () =>{
    try {
        let gender = await getRequest('fetch-gender')
        if(gender.data.status){
            setGender(gender.data.gender)
        }else{
            cogoToast.error(gender.data.message)
        }
    } catch (error) {
        cogoToast.error(gender.data.message)
    }
}

const updateProfileAction = async (e) =>{
        e.preventDefault()
       let finalData = {
            firstName: !e.target.firstName.value ? userDetails.firstName  : e.target.firstName.value,
            middleName: !e.target.middleName.value ? userDetails.middleName : e.target.middleName.value,
            lastName: !e.target.lastName.value ? userDetails.lastName : e.target.lastName.value,
            dateOfBirth: !DOB ? userDetails.dateOfBirth  : DOB,
            gender: !userDetails.gender ? e.target.gender.value === 'Select gender' ? null :  e.target.gender.value : userDetails.gender
        }
        try {
            updateLoaderRef.current.style.display = "block"
            updateButtonRef.current.style.display = 'none'
            let update = await postRequest('update-membership-profile',finalData)
            if(update.data.status){
                updateLoaderRef.current.style.display = "none"
                updateButtonRef.current.style.display = 'block'
                setUserdetails(update.data.user)
                cogoToast.success(update.data.message)
            }else{
                updateLoaderRef.current.style.display = "none"
                updateButtonRef.current.style.display = 'block'
                cogoToast.error(update.data.message)
            }
        } catch (error) {
            updateLoaderRef.current.style.display = "none"
                updateButtonRef.current.style.display = 'block'
            cogoToast.error(error.response.data.message)
        }
}

    return (
        <React.Fragment>
            <br/><br/>
            <div className="row">
                <div className="col-md-12">
                    <div style={{'marginLeft':'20px','cursor':'pointer'}}>
                    <i className="bi bi-pencil-square sub-member-action" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> Update Profile</i>
                    <hr/>
                    </div>
              
                </div>
            </div>
            <div className="row">
                    <div className="col-md-12">
                        <div className="profile-pic-background">
                        <div className="profilePicture" style={{"backgroundImage":`url(${defaultProfileImage})`,"backgroundSize":"cover"}}>

                            </div>
                        </div>
                        <br/><br/>
                    </div>
            </div>
            <div className="row">
            
                <div className="col-md-3">
                    <div className="profile-items">
                    <b>First Name</b><br/>
                    {userDetails !== null ? userDetails.firstName : ''}
                    </div>
                </div>
                <div className="col-md-3">
                <div className="profile-items">
                <b>Middle Name</b><br/>
                {userDetails !== null ? userDetails.middleName : ''}
                    </div>
                </div>
                <div className="col-md-3">
                <div className="profile-items">
                <b>Last Name</b><br/>
                {userDetails !== null ? userDetails.lastName : ''}
                </div>
                </div>
                <div className="col-md-3">
                <div className="profile-items">
                <b>Email Address</b><br/>
                {userDetails !== null ? userDetails.emailAddress : ''}
                </div>
                </div>
            </div>
            <br/>
            <div className="row">
                <div className="col-md-3">
                    <div className="profile-items">
                    <b>Phone Number</b><br/>
                    {userDetails !== null ? userDetails.phoneNumber : ''}
                    </div>
                </div>
                <div className="col-md-3">
                <div className="profile-items">
                <b>Title</b><br/>
                {userDetails !== null ? userDetails.title.titel : ''}
                </div>
                </div>
                <div className="col-md-3">
                <div className="profile-items">
                <b>Nationality</b><br/>
                {userDetails !== null ? userDetails.Country.name : ''}
                </div>
                </div>
                <div className="col-md-3">
                <div className="profile-items">
                <b>Date Of Birth</b><br/>
                    {userDetails !== null ? moment(userDetails.dateOfBirth).format("DD/MM/YYYY") : ''}
                </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-3">
                    <div className="profile-items">
                    <b>Gender</b><br/>
                    {userDetails !== null ? userDetails.Gender !== null ? userDetails.Gender.gender : null : ''}
                    </div>
                </div>
            </div>


                {/* Update profile modal */}
                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Update Profile</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={updateProfileAction}>
                            <small>Fist Name</small>
                            <input type="text" className="form-control" name="firstName" placeholder={userDetails !== null ? userDetails.firstName : '' }/><br/>
                            <small>Middle Name</small>
                            <input type="text" className="form-control"  name="middleName" placeholder={userDetails !== null ? userDetails.middleName : '' }/><br/>
                            <small>Last Name</small>
                            <input type="text" className="form-control"  name="lastName" placeholder={userDetails !== null ? userDetails.lastName : '' }/><br/>
                            <small>Date Of Birth</small>
                            <DatePicker
                                    className="form-control"
                                    dateFormat="MMMM d, yyyy"
                                    placeholderText="Select date of birth"
                                    selected={DOB}
                                    dropdownMode="select"
                                    showMonthDropdown 
                                    showYearDropdown
                                    onChange={(date) => setDOB(date)}
                                    />
                                    <br/> <br/>
                            <small>Gender</small>
                            <select className="form-select" name="gender">    
                            <option value="Select gender">Select gender</option>
                            {
                                gender !== null ? gender.map((item) =>{
                                    return <option key={item.id} value={item.id}>{item.gender}</option>
                                }) : ''
                            }
                            </select>
                            <br/>
                            <div className='spinner-border' role='status' ref={updateLoaderRef} style={{"display":"none"}}></div>
                            <button className='small-button' ref={updateButtonRef}>Update</button>
                        </form>
                    </div>
                    </div>
                </div>
                </div>
        </React.Fragment>
    )
}

export default ProfilePage