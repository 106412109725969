import React, { useReducer, useRef } from "react"
import { useParams } from "react-router-dom";
import { getRequest, postRequest } from "../services/requests";
import cogoToast from "cogo-toast";
import { useState } from "react";
import { useEffect } from "react";
import { usePaystackPayment } from "react-paystack";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from "react-router-dom";

export const EventContribution = () =>{
    let { eventId } = useParams();
    const [eventDetails,setEventDetails] = useState(null)
    //const [paymentDetails, setPaymentDetails] = useState(null)
    const navigate = useNavigate();
    const [contributonDetails,setContributionDetails] = useState()
    const MySwal = withReactContent(Swal)
    const contributionLoader = useRef(null)
    const contributionButton = useRef(null)

    const [paymentConfig,setPaymentConfig] = useState({
        reference: '',
        email: '', 
        amount: '', //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        publicKey: "pk_live_044580246ef8dd0a173b39730fbaca80b133a26b",
      })

    useEffect(()=>{
        fetchEventDetails()
    },[])

    useEffect(()=>{
        initializePayment(onSuccess,onClose)
    },[paymentConfig])

    const initializePayment = usePaystackPayment(paymentConfig);

    const onClose = () => {
        console.log("closed");
    }

    const onSuccess = (reference) =>{
        contributionButton.current.style.display = "none"
        contributionLoader.current.style.display = 'block'
      postRequest('confirm-contribution-payment',{
        "reference":reference,"contribution":contributonDetails
      }).then((response) =>{
        if(response.data.status === true){
            contributionButton.current.style.display = "block"
                                 contributionLoader.current.style.display = 'none'
            Swal.fire({
                icon: 'success',
                title: 'Contribution Complete',
                text: 'Your contribution has been made successfully, please check your email address for more details.',
              }).then((response)=>{
                  if(response.isConfirmed){
                    navigate("/")
                  }
              })
        }else{
            contributionButton.current.style.display = "block"
                                 contributionLoader.current.style.display = 'none'
            cogoToast.error(response.data.message)
        }
      }).catch((error)=>{
        contributionButton.current.style.display = "block"
                                 contributionLoader.current.style.display = 'none'
            cogoToast.error(error.response.data.message)
      })
    }
    const fetchEventDetails = async () =>{
        if(eventId !== null){
            try {
                let eventDetails = await getRequest('get-event-details',eventId)
                if(eventDetails){
                    setEventDetails(eventDetails.data.details)
                }
             } catch (error) {
                 cogoToast.error("Error Fetching Event Details")
             }
        }
    }

    const inititalizePayment = async (e) =>{
        e.preventDefault()
        let emailAddress = e.target.emailAddress.value
        let contributionValue = e.target.amount.value 
        if(emailAddress == null || emailAddress === ''){
            cogoToast.error("Please enter your correct email address")
        }else if(contributionValue == null || contributionValue === ''){
            cogoToast.error("Please enter an amount")
        }else{
            if(emailAddress !== null && contributionValue !== null){
                MySwal.fire({
                    title: <p></p>,
                    html:`<h4>You're about to make a contribution with the following details.</h4><br/><b>Event Name:</b>${eventDetails.eventName}</span><br/><br/><span><b>Event Date: </b>${eventDetails.date}</span><br/><br/><span><b>Email Address: </b>${emailAddress}</span><br/><br/><br/><b>Total: </b>${Naira.format(contributionValue)}`,
                    showCancelButton: true,
                    confirmButtonColor: '#1d4028',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Pay!',
                    didOpen: () => {
                        
                    },
                  }).then( async (response)=>{
                    if(response.isConfirmed){
                        contributionButton.current.style.display = "none"
                        contributionLoader.current.style.display = 'block'
                        try {
                            let initPayment = await postRequest('initialize-contribution',{
                                "amount":contributionValue,
                                "eventId":eventDetails.id,
                                "emailAddress":emailAddress
                            })
                            if(initPayment){
                                if(initPayment.data.status === true){
                                    contributionButton.current.style.display = "block"
                                     contributionLoader.current.style.display = 'none'
                                    setContributionDetails(initPayment.data.contribution)
                                    setPaymentConfig(paymentConfig =>({
                                        ...paymentConfig,
                                        ...{'email': initPayment.data.contribution.emailAddress,
                                        'amount': contributionValue,'reference':initPayment.data.paymentRef   
                                        }
                                    }))
                                }else{
                                    contributionButton.current.style.display = "block"
                                     contributionLoader.current.style.display = 'none'
                                    cogoToast.error(initPayment.data.message)
                                }
                            }
                        } catch (error) {
                            contributionButton.current.style.display = "block"
                                     contributionLoader.current.style.display = 'none'
                            cogoToast.error(error.response.data.message)
                        }
                    }
                  })     
            }
        }
    }
    let Naira = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
      });
    return(
        <React.Fragment>
              <div className='container-fluid'>
                <div className='row'>
                <div className='col-md-4'>

                </div>
                <div className='col-md-4'>
                <div className='confirmation-form'>
                    {eventDetails === null ? <div style={{"marginLeft":"auto","marginRight":"auto","width":"100px","marginTop":"120px"}}>
                                      <div className="spinner-border" style={{"width": "5rem", "height": "5rem"}} role="status">
                          <span className="visually-hidden">Loading...</span>
                                            </div>
                      </div> : <div>
                    {
                        eventDetails !== null ? <div>
                            <h4>You can contribute funds for this event titled {eventDetails.eventName}</h4>
                        </div>: ""
                    }
                    <hr/>
                    <br/>
                    <form onSubmit={inititalizePayment}>
                        <input type="text" placeholder="Enter Email Address" name="emailAddress" className="form-control"/>
                        <br/>
                        <input type="text" placeholder="Enter Amount" name="amount" className="form-control"/>
                        <br/>
                        <div className='spinner-border' role='status' ref={contributionLoader} style={{"display":"none"}}></div>
                        <button className="book-button" ref={contributionButton}>Inititalize Payment</button>
                    </form>
                    </div>}
                </div>
                </div>
                </div>
                </div>
        </React.Fragment>
    )
}

