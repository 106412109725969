import React, {useRef} from 'react';
import logo from '../images/logo.png'
import { useNavigate } from "react-router-dom";

const NavBar = () =>{
  const navigate = useNavigate();
  const navRef = useRef(null)
  const showSideBar = () =>{
    navRef.current.style.width = "250px"
  }

  const hideSideNav = () =>{
    navRef.current.style.width = "0"
  }
  
  return <React.Fragment>
    <div className="navbar-custom">
        <div style={{"width":"100%","margin":"auto"}} id="nav-bar-inner">
        <a href="#home" className='d-block d-sm-block d-md-none'><i onClick={showSideBar} className="bi bi-list d-block nav-ham" style={{"fontSize":"40px","color":"white","marginTop":"-10px"}}></i> </a>
        <a href="#home"><img src={logo} alt="logo" onClick={() => navigate("/")} className='nav-logo'/></a>
        <div className='d-none d-sm-none d-md-block'>
        <a href="/" onClick={() => navigate("/")}>Home</a>
        <div className="dropdown-custom">
      <button className="dropbtn-custom">Reservations 
       <i className="bi bi-caret-down-fill" style={{"marginTop":"3px!important"}}></i>
      </button>
      <div className="dropdown-content-custom">
      <a href="/reservations">Chalets</a>
      <a href="/visit-yolo">Visit Yolo</a>
        {/* <a href="/tables">Tables</a> */}
        {/* <a href="/events">Events</a> */}
        <a href="https://www.secure-booking-engine.com/accounts/-6uQEKs5nCdn9-6Iu4CLRQ/properties/tu17DnD7VEuctHBOC_fC1A/outlets/_k8r3Kpu5lJ8VzMP_3j20A/web-menu/cart/95FSRyjRp9a3Q38Z74BHjw/#/items">Menu</a>
        {/* <a href="/current-event">Easter Events</a> */}
      </div>
    </div> 
    <a href="/activities">Activities</a>
    {/* <a href="/visit-yolo">Visit Yolo</a> */}
    <a href="/chalets">Chalets</a>
    <a href="/membership">Membership</a>
    <a href="/restaurantandbar">Restaurant & Bar</a>
    <a href="/contact-us">Contact Us</a>
  
    {/* <div className="dropdown-custom">
      <button className="dropbtn-custom">Gallery
        <i className="fa fa-caret-down"></i>
      </button>
      <div className="dropdown-content-custom">
        <a href="#link1">Link 1</a>
        <a href="#link2">Link 2</a>
        <a href="#link3">Link 3</a>
      </div>
    </div> */}
        </div>
        </div>
  </div>
  <div className='side-nav' ref={navRef}>
  <i className="bi bi-x-lg" onClick={hideSideNav} style={{"fontSize":"40px","color":"white","float":"right","marginRight":"20px"}}></i>
  <br/><br/>
  <div style={{"marginTop":"20px","width":"100%","padding":"20px"}}>
  <div className='landing-side-nav-options' onClick={() => navigate("/")}>Home</div>
  <hr/>
  <div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item accordion-nav">
    <h2 className="accordion-header accordion-nav-header" id="flush-headingOne">
      <button className="accordion-button collapsed accordion-nav-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
        Reservations
      </button>
    </h2>
    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">
        <br/>
        <span  onClick={() => navigate("/reservations")}>Chalets</span><br/><br/>
        {/* <span  onClick={() => navigate("/events")}>Events</span><br/><br/> */}
        <span  onClick={() => navigate("/visit-yolo")}>Visit Yolo</span><br/><br/>
        {/* <span  onClick={() => navigate("/tables")}>Tables</span><br/><br/> */}
        <span><a style={{'color':'white','textDecoration':'none'}} href="https://www.secure-booking-engine.com/accounts/-6uQEKs5nCdn9-6Iu4CLRQ/properties/tu17DnD7VEuctHBOC_fC1A/outlets/_k8r3Kpu5lJ8VzMP_3j20A/web-menu/cart/95FSRyjRp9a3Q38Z74BHjw/#/items">Menu</a></span><br/><br/>
        {/* <span  onClick={() => navigate("/current-event")}>Easter Events</span> */}
      </div>
    </div>
  </div>
</div>
  {/* <div className='landing-side-nav-options' onClick={() => navigate("/reservations")}>Reservations</div> */}
  <hr/>
    <div className='landing-side-nav-options' onClick={() => navigate("/chalets")}>Chalets</div>
    {/* <hr/>
    <div className='landing-side-nav-options' onClick={() => navigate("/visit-yolo")}>Visit Yolo</div> */}
    <hr/>
    <div className='landing-side-nav-options' onClick={() => navigate("/activities")}>Activities</div>
    <hr/>
    <div className='landing-side-nav-options' onClick={() => navigate("/membership")}>Membership</div>
    <hr/>
    <div className='landing-side-nav-options' onClick={() => navigate("/contact-us")}>Contact Us</div>
    
  </div>
  </div>
  </React.Fragment>
 
}

export default NavBar