import cogoToast from 'cogo-toast';
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from "react-router-dom";
import { getRequest, postRequest } from '../services/requests';
import moment from 'moment/moment';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from "react-router-dom";
import { usePaystackPayment } from "react-paystack";

export const ConfirmAttendance = (props) =>{
    let { eventId } = useParams();
    const [details,setDetails] = useState({
        'firstName':'',
        'lastname':'',
        'emailAddress':'',
        'confirmEmail':'',
        'phoneNumber':'',
        'eventAccess':''
    })
    const [paymentConfig,setPaymentConfig] = useState({
        reference: '',
        email: '', 
        amount: '', //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        publicKey: "pk_live_044580246ef8dd0a173b39730fbaca80b133a26b",
    })
    const attendanceRef = useRef(null)
    const MySwal = withReactContent(Swal)
    const [eventDetails,setEventDetails] = useState(null)
    const navigate = useNavigate();
    const loaderRef = useRef(null)
    const stage1Ref = useRef(null)
    const stage2Ref = useRef(null)
    const contributionLoader = useRef(null)
    const contributionButton = useRef(null)
    const [contributonDetails,setContributionDetails] = useState()
    const [accesses,setAccesses] = useState(null)
    useEffect(()=>{
        fetchEventDetails()
        fetcEventAccess()
    },[])

    useEffect(()=>{
        initializePayment(onSuccess,onClose)
    },[paymentConfig])

    // useEffect(()=>{
    //     if(eventDetails)
    // },[eventDetails])
    const fetcEventAccess = async() =>{

        try {
           let acceses = await getRequest('fetch-event-accesses')
           if(acceses.data.status){
                setAccesses(acceses.data.access)
           }else{
            cogoToast.error(acceses.data.message)
           }
        } catch (error) {
            cogoToast.error(error.response.data.message)
        }
      
    }
    const onClose = () => {
        console.log("closed");
     }
     const onSuccess = (reference) =>{
        contributionButton.current.style.display = "none"
        contributionLoader.current.style.display = 'block'
      postRequest('confirm-contribution-payment',{
        "reference":reference,"contribution":contributonDetails,"attendeeDetails":details
      }).then((response) =>{
        if(response.data.status === true){
            contributionButton.current.style.display = "block"
                                 contributionLoader.current.style.display = 'none'
            Swal.fire({
                icon: 'success',
                title: 'Payment Complete',
                text: response.data.message,
              }).then((response)=>{
                  if(response.isConfirmed){
                    navigate("/")
                  }
              })
        }else{
            contributionButton.current.style.display = "block"
                                 contributionLoader.current.style.display = 'none'
            cogoToast.error(response.data.message)
        }
      }).catch((error)=>{
        contributionButton.current.style.display = "block"
                                 contributionLoader.current.style.display = 'none'
            cogoToast.error(error.response.data.message)
      })
     }
    const initializePayment = usePaystackPayment(paymentConfig);
    const handleDetailsChange = (e) =>{
        setDetails({...details,[e.target.name]:e.target.value})
    }
    const fetchEventDetails = async () =>{
        if(eventId !== null){
            try {
                let eventDetails = await getRequest('get-event-details',eventId)
                if(eventDetails){
                    setEventDetails(eventDetails.data.details)
                }
             } catch (error) {
                 cogoToast.error("Error Fetching Event Details")
             }
        }
    }
    const checkStage = () =>{
        stage1Ref.current.style.display = 'none'
        stage2Ref.current.style.display = 'block'
    }
    const submitDetails = async (e) =>{
        e.preventDefault()
        details.eventId = eventId
        details.contributionPriority = eventDetails.contrubutionPriority
        if(details.firstName.trim() === null || details.firstName.trim() === ''){
            cogoToast.error('Input firstname')
        }else if(details.lastname.trim() === null || details.lastname.trim() === ''){
            cogoToast.error('Input lastname')
        }else if(details.emailAddress.trim() === null || details.emailAddress.trim() === ''){
            cogoToast.error('Input email address')
        }else if(details.confirmEmail.trim() === null || details.confirmEmail.trim() === ''){
            cogoToast.error('Confirm email address')
        }else if(details.confirmEmail.trim() !== details.emailAddress.trim()){
            cogoToast.error('Email address does not match')
        }else if(details.phoneNumber.trim() === null || details.phoneNumber === ''){
            cogoToast.error('Input phone number')
        }else if(!details.eventAccess || details.eventAccess == 0){
            cogoToast.error("Select access")
        }else if(details.eventId === null){
            cogoToast.error('Unable to get Event ID')
        }else{
            loaderRef.current.style.display = "block"
                attendanceRef.current.style.display = 'none'
            try {

              let attendance = await postRequest('confirm-attendance',details)
              if(attendance){
               if(attendance.data.status === true){
                loaderRef.current.style.display = "none"
                attendanceRef.current.style.display = 'block'
                if(attendance.data.compulsory === true){
                    stage1Ref.current.style.display = 'none'
                    stage2Ref.current.style.display = 'block'
                }else{
                     MySwal.fire({
                    title: <p>SUCCESS!</p>,
                    html:attendance.data.message,
                    icon: 'success',
                    confirmButtonColor: '#1d4028',
                    confirmButtonText: 'Okay!',
                  }).then(async (response)=>{
                    if(response.isConfirmed){
                        //navigate("/")
                       stage1Ref.current.style.display = 'none'
                       stage2Ref.current.style.display = 'block'
                      
                 }
                 }) 
                }
               
               }else{
                loaderRef.current.style.display = "none"
                attendanceRef.current.style.display = 'block'
                cogoToast.error(attendance.data.message)
               }
              }
            } catch (error) {
                loaderRef.current.style.display = "none"
                attendanceRef.current.style.display = 'block'
                cogoToast.error("Error sending request.")
            }
           
        }
    }

    const inititalizePayment = async (e) =>{
        e.preventDefault()
        let emailAddress = e.target.emailAddress.value
        let contributionValue = null
        if(eventDetails.contrubutionPriority === "compulsory"){
            contributionValue = eventDetails.feeValue
        }else{
            contributionValue = e.target.amount.value 
        }
        
        if(emailAddress == null || emailAddress === ''){
            cogoToast.error("Please enter your correct email address")
        }else if(contributionValue == null || contributionValue === ''){
            cogoToast.error("Please enter an amount")
        }else{
            if(emailAddress !== null && contributionValue !== null){
                MySwal.fire({
                    title: <p></p>,
                    html:`<h4>You're about to make a contribution with the following details.</h4><br/><b>Event Name:</b>${eventDetails.eventName}</span><br/><br/><span><b>Event Date: </b>${eventDetails.date}</span><br/><br/><span><b>Email Address: </b>${emailAddress}</span><br/><br/><br/><b>Total: </b>${Naira.format(contributionValue)}`,
                    showCancelButton: true,
                    confirmButtonColor: '#1d4028',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Pay!',
                    didOpen: () => {
                    
                    },
                  }).then( async (response)=>{
                    if(response.isConfirmed){
                        contributionButton.current.style.display = "none"
                        contributionLoader.current.style.display = 'block'
                        try {
                            let initPayment = await postRequest('initialize-contribution',{
                                "amount":contributionValue,
                                "eventId":eventDetails.id,
                                "emailAddress":emailAddress,
                            })

                            if(initPayment){
                                if(initPayment.data.status === true){
                                    contributionButton.current.style.display = "block"
                                     contributionLoader.current.style.display = 'none'
                                    setContributionDetails(initPayment.data.contribution)
                                    setPaymentConfig(paymentConfig =>({
                                        ...paymentConfig,
                                        ...{'email': initPayment.data.contribution.emailAddress,
                                        'amount': contributionValue * 100,'reference':initPayment.data.paymentRef   
                                        }
                                    }))
                                }else{
                                    contributionButton.current.style.display = "block"
                                     contributionLoader.current.style.display = 'none'
                                    cogoToast.error(initPayment.data.message)
                                }
                            }else{
                                cogoToast.error("Unable to initialize payment")
                            }
                        } catch (error) {
                            contributionButton.current.style.display = "block"
                                     contributionLoader.current.style.display = 'none'
                            cogoToast.error(error.response.data.message)
                        }
                    }
                  })     
            }
        }
    }
    const closePage = () =>{
        navigate("/")
    }
    let Naira = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
      });
    return (
        <React.Fragment>
            <div className='container-fluid'>
                <div className='row'>
                <div className='col-md-4'>

                </div>
                <div className='col-md-4'>
                    
                <div className='confirmation-form'>
                        {eventDetails !== null ? 
                        <div>
                            <div ref={stage1Ref}>
                            <h4>Enter your details to confirm your attendance for {eventDetails.eventName}</h4>
                        <h5 style={{"textAlign":"center"}}>({eventDetails.date})</h5>
                        {/* <div className='alert alert-warning'>
                            If You have filled this form before, for this particular event, please click the next button to access the next stage<br/>
                            <button className='btn btn-primary' onClick={checkStage}>NEXT</button>
                        </div> */}
                        <form onSubmit={submitDetails}>
                            <input type='text' className='form-control' onChange={handleDetailsChange} name='firstName' placeholder='Firstname'/><br/>
                            <input type='text' className='form-control' onChange={handleDetailsChange} name='lastname' placeholder='Lastname'/><br/>
                            <input type='text' className='form-control' onChange={handleDetailsChange} name='emailAddress' placeholder='Email Address'/><br/>
                            <input type='text' className='form-control' onChange={handleDetailsChange} name='confirmEmail' placeholder='Confirm Email Address'/><br/>
                            <input type='text' className='form-control' onChange={handleDetailsChange} name='phoneNumber' placeholder='Phone Number'/><br/>
                            <select className='form-select' name='eventAccess' onChange={handleDetailsChange}> 
                                <option value="0">Select Access</option>
                                {accesses !== null ? accesses.map((item) =>{
                                    return <option value={item.id} key={item.id}>{item.eventAccess}</option>
                                }) : ''}
                            </select>
                            <br/>
                            <div className='spinner-border' role='status' ref={loaderRef} style={{"display":"none"}}></div>
                                <button className="book-button"  ref={attendanceRef}>Confirm Attendance</button>
                        </form>
                            </div>
                            {/* event finance related form starts here*/}
                            {eventDetails === null ? <div style={{"marginLeft":"auto","marginRight":"auto","width":"100px","marginTop":"120px"}}>
                                      <div className="spinner-border" style={{"width": "5rem", "height": "5rem"}} role="status">
                          <span className="visually-hidden">Loading...</span>
                                            </div>
                      </div> : <div style={{"display":"none"}} ref={stage2Ref}>
                    {
                        eventDetails !== null ? <div>
                            <i className="bi bi-x-lg" style={{'cursor':'pointer'}} onClick={closePage}>Exit</i>
                            <hr/>
                            <h4>You can make payments for this event titled {eventDetails.eventName}</h4>
                            {eventDetails.contrubutionPriority !== "compulsory" ? <p style={{"fontWeight":"bold","color":"red","textAlign":"center"}}>(Optional,this is just a support fee.)</p> : ''}
                        </div>: ""
                    }
                    <hr/>
                    <br/>
                    <form onSubmit={inititalizePayment}>
                        {details.emailAddress === null || details.emailAddress === ''  ? <input type="text" placeholder="Enter Email Address" value={details.emailAddress} name="emailAddress" className="form-control"/> :  <input type="text" placeholder="Enter Email Address"  disabled='disabled' value={details.emailAddress} name="emailAddress" className="form-control"/>}
                       
                        <br/>
                        {eventDetails.contrubutionPriority === "compulsory" ? <h5>Amount Due: {Naira.format(eventDetails.feeValue)}</h5> : <input type="text" placeholder="Enter Amount" name="amount" className="form-control"/>}
                        <br/>
                        <div className='spinner-border' role='status' ref={contributionLoader} style={{"display":"none"}}></div>
                     <button className="book-button" ref={contributionButton} style={{"margin":"0"}}>Initialize Payment</button>
                    </form>
                    </div>}
                            {/* Event finance related form ends here */}
                        <div>

                        </div>
                        </div>
                      : 
                      <div style={{"marginLeft":"auto","marginRight":"auto","width":"100px","marginTop":"120px"}}>
                                      <div className="spinner-border" style={{"width": "5rem", "height": "5rem"}} role="status">
                          <span className="visually-hidden">Loading...</span>
                                            </div>
                      </div>
                        }
                       
                    </div>
                  
                </div>
                <div className='col-md-4'>

                </div>
                </div>
            </div>
        </React.Fragment>
    )
}