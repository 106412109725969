import axios from "axios";
//const baseUrl = "http://127.0.0.1:3001/api/"
export const baseUrl = "https://yolo.ng/api/"
export const imageUrl = "https://yolo.ng/images/"
//export const imageUrl = "http://127.0.0.1:3001/images/"

export const postRequest = (route,postData) =>{
   axios.defaults.withCredentials = true
   return axios.post(baseUrl+route, postData)
}

export const getRequest = (route,getData = null) =>{
  axios.defaults.withCredentials = true
    if(getData !== null){
     return axios.get(baseUrl+route+'/'+getData)
    }else{
      return axios.get(baseUrl+route)
    }

}