import React, { useEffect, useState,useRef } from "react"
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../services/requests";
import cogoToast from "cogo-toast";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const OrganizerConfirmAttendance = () =>{
    let { eventId,emailAddress,emailAddress2,eventId2 } = useParams();
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const [attendeeDetails,setattendeeDetails] = useState(null)
    const approvalLoader = useRef(null)
    const approvalButton = useRef(null)
    useEffect(() =>{
      fetchAttendanceDetails()
    },[])

    const checkForParams = () =>{
        if(eventId == null && emailAddress == null){
            navigate("/")
        }
    }
    const fetchAttendanceDetails = async () =>{
        try {
           let findAttendance = await postRequest('find_attendance_record',{'eventId':eventId,'emailAddress':emailAddress,'emailAddress2':emailAddress2,'eventId2':eventId2})
            if(findAttendance){
                if(findAttendance.data.status === true){
                    setattendeeDetails(findAttendance.data.details)
                }else{
                    cogoToast.error(findAttendance.data.message)
                }
            }
        } catch (error) {
            cogoToast.error("Error Fetching Attendee's Details")
        }
    }
    const acceptAttendance = async () =>{
        try {
            approvalLoader.current.style.display = "block"
            approvalButton.current.style.display = 'none'
            let accept = await postRequest('accept-attendance',{
                "Id":attendeeDetails.id
            })
            if(accept){
                if(accept.data.status === true){
                    approvalLoader.current.style.display = "none"
                    approvalButton.current.style.display = 'block'
                    MySwal.fire({
                        title: <p>SUCCESS!</p>,
                        html:accept.data.message,
                        icon: 'success',
                        confirmButtonColor: '#1d4028',
                        confirmButtonText: 'Okay!',
                      }).then(async (response)=>{
                        if(response.isConfirmed){
                            navigate("/")
                        }
                      }) 
                }else{
                    approvalLoader.current.style.display = "none"
                    approvalButton.current.style.display = 'block'
                    cogoToast.error(accept.data.message)
                }
            }
        } catch (error) {
            approvalLoader.current.style.display = "none"
            approvalButton.current.style.display = 'block'
            cogoToast.error("Error making request")
        }
    }

    const declineAttendance = async () =>{
        try {
            approvalLoader.current.style.display = "block"
            approvalButton.current.style.display = 'none'
            let accept = await postRequest('decline-attendance',{
                "Id":attendeeDetails.id
            })
            if(accept){
                if(accept.data.status === true){
                    approvalLoader.current.style.display = "none"
                    approvalButton.current.style.display = 'block'
                    MySwal.fire({
                        title: <p>SUCCESS!</p>,
                        html:accept.data.message,
                        icon: 'success',
                        confirmButtonColor: '#1d4028',
                        confirmButtonText: 'Okay!',
                      }).then(async (response)=>{
                        if(response.isConfirmed){
                            navigate("/")
                        }
                      }) 
                }else{
                    approvalLoader.current.style.display = "none"
                    approvalButton.current.style.display = 'block'
                    cogoToast.error(accept.data.message)
                }
            }
        } catch (error) {
            approvalLoader.current.style.display = "none"
            approvalButton.current.style.display = 'block'
            cogoToast.error("Error making request")
        }
    }
    return(
        <React.Fragment>
             <div className='container-fluid'>
                <div className='row'>
                <div className='col-md-4'>

                </div>
                <div className='col-md-4'>
                <div className='confirmation-form'>
                        {attendeeDetails !== null ? 
                        <div>
                            <h4 style={{"textAlign":"left"}}>Accept or reject this attendee's attendance confirmation for your event with event id {attendeeDetails.eventId}</h4>
                            <hr/>
                            <span><b>Firstname:</b> {attendeeDetails.firstName}</span><br/><br/>
                            <span><b>Lastname:</b> {attendeeDetails.lastName}</span><br/><br/>
                            <span><b>Email Address:</b> {attendeeDetails.emailAddress}</span><br/><br/>
                            <span><b>Phone Number:</b> {attendeeDetails.phoneNumber}</span><br/><hr/>
                            <div className='spinner-border' role='status' ref={approvalLoader} style={{"display":"none"}}></div>
                            {attendeeDetails.status === 0 ? <div ref={approvalButton}>
                                <button className="attendance-actions" style={{"backgroundColor":"#1d4028"}} onClick={acceptAttendance}>Accept</button> <button  className="attendance-actions" onClick={declineAttendance} style={{"backgroundColor":"red"}}>Reject</button>
                                </div>: <button ref={approvalButton} onClick={declineAttendance} className="attendance-actions" style={{"backgroundColor":"red"}}>Reject</button>}
                            
                        </div>
                      : 
                      <div style={{"marginLeft":"auto","marginRight":"auto","width":"100px","marginTop":"120px"}}>
                                      <div className="spinner-border" style={{"width": "5rem", "height": "5rem"}} role="status">
                          <span className="visually-hidden">Loading...</span>
                                            </div>
                      </div>
                        }
                       
                    </div>
                  
                </div>
                <div className='col-md-4'>

                </div>
                </div>
            </div>
        </React.Fragment>
    )
}