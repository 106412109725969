import cogoToast from "cogo-toast"
import { useEffect, useState,useRef } from "react"
import { getRequest, postRequest } from "../../services/requests"
import { imageUrl } from "../../services/requests"

const FoodMenuPage = () =>{
    const [image,setImage] = useState(null)
    const [categories,setCategories] = useState(null)
    const [menu,setMenu] = useState(null)
    const addMealLoader = useRef(null)
    const addMealButton = useRef(null)

    useEffect(()=>{
        fetchCategories()
        fetchMeals()
    },[])
    const fetchCategories = async() =>{
        try {
          let category = await getRequest('fetch-menu-category')
          if(category.data.status === true){
            setCategories(category.data.data)
          }else{
            cogoToast.error(category.data.message)
          }
        } catch (error) {
            cogoToast.error(error.response.data.message)
        }
       
    }
    const fetchMeals = async () =>{
        try {
           const addedMenu = await getRequest('fetch-added-food-menu')
           console.log(addedMenu)
           if(addedMenu.data.status === true){
                setMenu(addedMenu.data.data)
           }else{
            cogoToast.error(addedMenu.data.message)
           }
        } catch (error) {
            cogoToast.error(error.response.data.message)
        }
    }

    const addMeal = async (e) =>{
        e.preventDefault()
      let title = e.target.title.value
      let category = e.target.category.value
      let description = e.target.description.value
      let price = e.target.price.value

        if(!title.trim()){
            cogoToast.error('Input a title')
        }else if(!category || category === "Select Category"){
            cogoToast.error('Select a category')
        }else if(!price.trim()){
            cogoToast.error("Input Price Value")
        }else if(!description.trim()){
            cogoToast.error("Input a description")
        }else if(image == null){
            cogoToast.error("Select and Image")
        }else{
            addMealLoader.current.style.display = "block"
            addMealButton.current.style.display = 'none'
            const formData = new FormData()
            formData.append("title",title)
            formData.append("category",category)
            formData.append("price",price)
            formData.append("description",description)
            formData.append("image",image)
            try {
             let meal = await postRequest('add-meal-menu',formData)
             if(meal.data.status === true){
                setMenu(meal.data.data)
                addMealLoader.current.style.display = "none"
            addMealButton.current.style.display = 'block'
                cogoToast.success(meal.data.message)
             }else{
                addMealLoader.current.style.display = "none"
            addMealButton.current.style.display = 'block'
                cogoToast.error(meal.data.message)
             }
            } catch (error) {
                addMealLoader.current.style.display = "none"
            addMealButton.current.style.display = 'block'
                cogoToast.error(error.response.data.message)
            }
        }

    }
    const removeMenuItem = async (e) =>{
       let itemId = e.target.getAttribute("item-id")
       e.target.innerHTML = "Removing......." 
       try {
            const removeItem = await postRequest('remove-menu-item',{
                itemId:itemId
               })
               if(removeItem.data.status){
                setMenu(removeItem.data.meals)
                    cogoToast.success(removeItem.data.message)
               }else{
                cogoToast.error(removeItem.data.message)
               }
        } catch (error) {
            cogoToast.error(error.response.data.message)
        }
    }
    const setPicture = (e) =>{
        setImage(e.target.files[0])
    }
    let Naira = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
      });
    return (
        <div className="admin-views-container">
        <div className="row">
            <div className="col-md-12">
            <div className="inner-admin-container">
                <div className="row">
                        <div className="col-md-4">
                            <div className="add-edible-box">
                                <h4>Add Food/Drink</h4>
                            <form onSubmit={addMeal}>
                        <input type="text" placeholder="Title" name="title" className="form-control"/><br/>
                        <select className="form-select" name="category">
                            <option>Select Category</option>
                          {categories !== null ? categories.map((item) =>{
                            return <option key={item.id} value={item.id}>{item.category}</option>
                          }) : ''}
                        </select>
                        <br/>
                        <input type="text" name="price" placeholder="Price" className="form-control"/><br/>
                        <textarea className="form-control" name="description" placeholder="Description"></textarea><br/>
                        <input type='file' name="picture" className="form-control form-control-admin" onChange={setPicture}/>
                        <br/>
                        <div className='spinner-border' role='status' ref={addMealLoader} style={{"display":"none"}}></div>
                        <button ref={addMealButton} className="small-button">Add +</button>
                    </form>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="add-edible-box" style={{'width':'100%','min-height':'100vh'}}>
                            <h4>Menu</h4>
                            <hr/>
                            <div className="row">
                            {menu !== null ? menu.map((item) =>{
                                return <div className="col-md-6">
                                             <div className="admin-menu">
                                        <div className="row g-0">
                                        <div className="col-md-6">
                                        <div>
                                        <div className="menu-image">
                                                <img src={`${imageUrl+item.image}`} alt='menu-pic' />
                                            </div>
                                        </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div style={{"padding":"10px"}}>
                                        <b style={{'fontSize':'20px'}}>{item.title}</b><br/>
                                            <span>{item.description}</span><br/><br/>
                                        </div>
                                        <span className="menu-price"><b>{Naira.format(item.price)}</b></span> <span className="category-display"><b>{item.MenuCategory.category}</b></span> <b><span style={{cursor:"pointer"}} onClick={removeMenuItem} className="category-display" item-id={item.id}>Remove</span></b>
                                        </div>
                                        </div>                                        
                                </div>
                                    </div>
                            }) : ''}  
                            </div>
                            </div>
                        </div>
                </div>
                </div>
                </div>
                </div>
                </div>
    )
}

export default FoodMenuPage