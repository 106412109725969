import { useEffect, useState } from "react"
import { getRequest } from "../../services/requests"
import { useNavigate } from "react-router-dom"
export const DashboardHome = () =>{
    const [reservationCount, setReservationCount] = useState(null)
    const navigate = useNavigate()
    useEffect(()=>{
        getAuth()
        totalReservations()
    },[])
    const getAuth = async () =>{
        try {
          let authUser = await getRequest("get-authenticated-user") 
            if(authUser.data.status === false){
                navigate('/admin/index/')
            }
        } catch (error) {
            navigate('/admin/index/')
        }
    }
    const totalReservations = async () => {
        try {
            let totalReservations = await getRequest('total-reservations')
            if(totalReservations.data.status === true){
                setReservationCount(totalReservations.data)
            }
        } catch (error) {
            
        }
    }
    return(
        <div className="admin-views-container">
        <div className="row">
            <div className="col-md-12">
            <div className="inner-admin-container">
                <h3>Home</h3>
                <hr/>
                <div className="row">
                    <div className="col-md-5">
                        <div className="row">
                            <h5>Reservations</h5>
                            <div className="col-md-6 col-sm-6">
                                <div className="admin-boxes">
                                <br/><br/>
                                    {reservationCount !== null ? <h1>{reservationCount.lifetimeReservations}</h1>: <h1>0</h1>}
                                    <br/>
                                    <small>Lifetime Reservations</small> 
                                </div>
                               
                            </div>
                            <div className="col-md-6 col-sm-6">
                            <div className="admin-boxes">
                            <br/><br/>
                                    {reservationCount !== null ? <h1>{reservationCount.activeReservations}</h1>: <h1>0</h1>}

                                    <br/>
                                    <small>Active Reservations</small>
                            </div>
                            
                            </div>
            <div className="col-md-6 col-sm-6">
            <div className="admin-boxes">
            <br/><br/>
            {reservationCount !== null ? <h1>{reservationCount.inactiveReservations}</h1>: <h1>0</h1>}
            <br/>
            <small>Inactive Reservations</small>
</div>
            </div>
            <div className="col-md-6 col-sm-6">
                                        <div className="admin-boxes">
            <br/><br/>
            {reservationCount !== null ? <h1>{reservationCount.pendingReservations}</h1>: <h1>0</h1>}
            <br/>
            <small>Pending Reservations</small>
</div>
                                        </div>
                                    </div>
                    </div>
                    <div className="col-md-7">
                    <div id='admin-side-image' className="d-none d-sm-none d-md-block">

                    </div>
                    </div>
                </div>
                </div>
                </div>
                </div>
                </div>
    )
}