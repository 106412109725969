import React from "react";
import logo from "../images/logo.png";
const Footer = () => {
  return (
      <div className="footer">
            <div className="container">
            <div className="row">
        <div className="col-md-3">
          
          <div className="footer-div" style={{"marginTop":"10px"}}>
          <img src={logo} alt='footer-logo'/>
            <p>
            Nested on a private island amidst serene clear blue beach water of the Atlantic Ocean is where you will find the best all-inclusive beach resort in Nigeria.
            </p>
       
          </div>
        </div>
        <div className="col-md-3">
          <div className="footer-div">
            <h3>Explore</h3>
            <hr/>
            <ul>
                <li><a href="/" className="footer-links">Home</a></li>
                <li><a href="/chalets" className="footer-links">Chalets</a></li>
                <li><a href="/activities" className="footer-links">Activities</a></li>
                <li><a href="/reservations" className="footer-links">Reservations</a></li>
            </ul>
          </div>
        </div>
        <div className="col-md-3">
          <div className="footer-div">
            <h3>Contact</h3>
            <hr/>
            <ul>
                <li>09044000292</li>
                <li>08038279464</li>
                <li>info@yolo.ng</li>
                <li>admin@yolo.ng</li>
                <li>Ibeshe Island, Lagos</li>
            </ul>
          </div>
        </div>
        <div className="col-md-3">
          <div className="footer-div">
            <h3>Subscribe</h3>
            <hr/>
            <a href="https://www.facebook.com/yoloislandng?mibextid=ZbWKwL" className='social-links'><span className='socials'><i className="bi bi-facebook"></i></span></a>
          <a href="https://twitter.com/yoloislandng?s=09" className='social-links'><span className='socials'><i className="bi bi-twitter"></i></span></a>  
          <a href="https://instagram.com/yoloislandng?igshid=MzRlODBiNWFlZA==" className='social-links'><span className='socials'><i className="bi bi-instagram"></i></span></a>  
          <a href="https://www.tiktok.com/@yoloisland.ng?_t=8gCcnyFAOU2&_r=1" className='social-links'><span className='socials'><i className="bi bi-tiktok"></i></span></a>
            <div>
              <br/>
            Subscribe to our newsletter and be the first to receive new updates
            <input type='text' className="form-control" placeholder="Email Address"/>
            </div>
          </div>
        </div>
      </div>
      
            </div>
           
          </div>
  );
};

export default Footer;
