import Footer from "../components/footer";
import NavBar from "../components/NavBar";
import activity1 from "../images/activity1.jpg";
import activity2 from "../images/activity2.jpg";
import activity5 from "../images/activity5.jpg";
import activity4 from "../images/activity4.jpg";
import { useEffect } from "react";
export const Activities = () => {
  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <NavBar />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="page-header">
            <div className="page-inner-header">
              <p className="page-heading">Recreational Activities</p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p style={{ fontSize: "18px" }}>
            The recreational activities offered by YOLO 
              Island such as ATV quad bikes, volleyball, 
              beach soccer, badminton and mini golf 
              provide guests with a variety of options to 
              keep entertained during their stay at the 
              resort. ATV quad biking allows guests to 
              explore the resort's property and the 
              surrounding areas in a unique and exciting 
              way. Volleyball and beach soccer are beach 
              activities that allow guests to enjoy the 
              sunshine and the ocean while playing sports. 
              These activities are perfect for guests 
              looking for an active way to enjoy their 
              vacation.
              Badminton and air hockey are games 
              that can be played indoors or outdoors 
              and are a great way for guests to spend 
              time together. They are perfect for 
              guests looking for a fun and friendly 
              competition. Mini golf is a miniature golf 
              course that allows guests to play golf in a 
              fun and relaxed setting. It is a great 
              activity for guests of all ages and skill 
              levels.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="card activity-card" style={{ width: "18rem;" }}>
              <div
                className="activities-card-images"
                style={{
                  backgroundImage: `url(${activity1})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
              <div className="card-body">
                <h3>Beach Soccer</h3>
                <p className="card-text">
                  
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card activity-card" style={{ width: "18rem;" }}>
              <div
                className="activities-card-images"
                style={{
                  backgroundImage: `url(${activity4})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
              <div className="card-body">
                <h3>Quad Bikes</h3>
                <p className="card-text">
           
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card activity-card" style={{ width: "18rem;" }}>
              <div
                className="activities-card-images"
                style={{
                  backgroundImage: `url(${activity5})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
              <div className="card-body">
                <h3>Horse Riding</h3>
                <p className="card-text">
        
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="card activity-card" style={{ width: "18rem;" }}>
              <div
                className="activities-card-images"
                style={{
                  backgroundImage: `url(${activity2})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
              <div className="card-body">
                <h3>Jet Ski</h3>
                <p className="card-text">
              
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4"></div>
          <div className="col-md-4"></div>
          <div className="col-md-4"></div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
