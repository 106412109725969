import React,{useEffect, useState, useRef} from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/footer'
import DatePicker from "react-datepicker";
import cogoToast from 'cogo-toast';
import { getRequest, postRequest } from '../services/requests';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Modal from 'react-bootstrap/Modal';
import { usePaystackPayment } from "react-paystack";
import { useNavigate } from "react-router-dom";

const TablesReservationPage = () => {
    const [startDate, setStartDate] = useState(null);
    const [numberOfGuests,setNumberOfGuests] = useState(1)
    const [chalets,setChalets] = useState(null)
    const [selectedChalet,setSelectedChalet] = useState(null)
    const [reservationInProgress,setReservationInProgress] = useState(null)
    const [tablePackages,setTablesPackages] = useState(null)
    const [selectedPackage,setSelectedPackage] = useState(null)
    const bookRef = useRef(null)
    const bookLoader = useRef(null)

    const [reservationDetails,setReservationDetails] = useState({
        "emailAddress":'',
        "phoneNumber":'',
        "firstName":'',
        "lastName":'',
        "selectedPackage":''
    })
    const [tableDetails,setTableDetails] = useState(null)
    const [paymentConfig,setPaymentConfig] = useState({
        reference: '',
        email: '', 
        amount: '', //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        publicKey: "pk_live_044580246ef8dd0a173b39730fbaca80b133a26b",
    })

    const [show, setShow] = useState(false);

    useEffect(()=>{
        initializePayment(onSuccess,onClose)
    },[paymentConfig])

    const handleClose = () =>{
        setShow(false)
        setChalets(null)
        setSelectedChalet(null)
    }
    const navigate = useNavigate();
    const handleShow = () => {
    
    } 
    const MySwal = withReactContent(Swal)
    const increase = () =>{
        let currentNumber = numberOfGuests
        if(currentNumber < 10){
            currentNumber = numberOfGuests + 1
        setNumberOfGuests(currentNumber)
        }
      }
    
      const decrease = () =>{
        let currentNumber = numberOfGuests
        if(currentNumber > 1){
            currentNumber = numberOfGuests - 1
            setNumberOfGuests(currentNumber)
        }
      }
      const handleReservationFormChange = (e) =>{
        setReservationDetails({...reservationDetails,[e.target.name]:e.target.value})
        if(e.target.name === "selectedPackage"){
            tablePackages.forEach((item) =>{
                if(item.id == e.target.value){
                       setSelectedPackage(item) 
                }
            })
        }
      }
    //   useEffect(()=>{

    //   },[chalets])
      useEffect(()=>{
       // fetchTablesDetails()
       fetchPackages()
      },[])
      const fetchCahletWithChoice = async () =>{
        try {
           let chalets = await postRequest('get-chalets-available-tables',{
            "date":startDate})
            if(chalets.data.status === true){
                setChalets(chalets.data.chalets)
            }else{
                cogoToast.error(chalets.data.message)
            }
        } catch (error) {
            cogoToast.error(error.response.data.message)
        }
        
      }
      const initReservation = (e) =>{
        e.preventDefault()
        let finalData = {
            "emailAddress":reservationDetails.emailAddress,
            "firstName":reservationDetails.firstName,
            "lastName":reservationDetails.lastName,
            "phoneNumber":reservationDetails.phoneNumber,
            "date":startDate,
            "selectedPackage":reservationDetails.selectedPackage,
           // "visitors":numberOfGuests
        }
        if(finalData.firstName === null || finalData.firstName === ""){
            cogoToast.error("Enter your first name")
        }else if(finalData.lastName === null || finalData.lastName === ""){
            cogoToast.error("Enter your last name")
        }else if(finalData.emailAddress === null || finalData.emailAddress === ""){
            cogoToast.error("Enter your email address")
        }else if(finalData.phoneNumber === null || finalData.phoneNumber === ""){
            cogoToast.error("Enter your phone number")
        }else if(finalData.date === null || finalData.date === ""){
            cogoToast.error("Select a date")
        }else{
            // setShow(true)
            // fetchCahletWithChoice()
          
        }
      }
      const selectChalet = (e) =>{
        let selected = e.target.value
        let roomName = e.target.options[e.target.selectedIndex].text
        setSelectedChalet({
            "roomId":selected,
            "roomName":roomName
        })

      }
      const fetchTablesDetails = async () =>{
        try {
            const fetchedTables = await postRequest('fetch-tables-details',{})
            if(fetchedTables.data.status === true){
                setTableDetails(fetchedTables.data.tables)
            }else{
                cogoToast.error(fetchedTables.data.message)
            }
        } catch (error) {
            cogoToast.error(error.response.data.message)
        }
      }
      const makeReservation = async (e) =>{
        e.preventDefault()
        let finalData = {
            "emailAddress":reservationDetails.emailAddress,
            "firstName":reservationDetails.firstName,
            "lastName":reservationDetails.lastName,
            "phoneNumber":reservationDetails.phoneNumber,
            "date":startDate,
            "selectedPackage":reservationDetails.selectedPackage,
            //"visitors":numberOfGuests
        }
        if(finalData.firstName === null || finalData.firstName === ""){
            cogoToast.error("Enter your first name")
        }else if(finalData.lastName === null || finalData.lastName === ""){
            cogoToast.error("Enter your last name")
        }else if(finalData.emailAddress === null || finalData.emailAddress === ""){
            cogoToast.error("Enter your email address")
        }else if(finalData.phoneNumber === null || finalData.phoneNumber === ""){
            cogoToast.error("Enter your phone number")
        }else if(finalData.date === null || finalData.date === ""){
            cogoToast.error("Select a date")
        }else if(!finalData.selectedPackage || finalData.selectedPackage == "0"){
            cogoToast.error("Select a package")
        }else{
            // setShow(true)
            // fetchCahletWithChoice()
            let finalData = {
                "emailAddress":reservationDetails.emailAddress,
                "firstName":reservationDetails.firstName,
                "lastName":reservationDetails.lastName,
                "phoneNumber":reservationDetails.phoneNumber,
                "date":startDate,
                "selectedPackage":reservationDetails.selectedPackage,
            }
            var dayOfWeek = startDate.getDay();
            var isWeekend = (dayOfWeek === 6) || (dayOfWeek  === 0)
            
              MySwal.fire({
                    title: <p></p>,
                    html:`<span><b>Fist name:</b>${finalData.firstName}</span><br/><br/><br/><span><b>Last Name:</b>${finalData.lastName}</span><br/><br/><br/><span><b>Email Address: </b>${finalData.emailAddress}</span><br/><br/><span><br/><span><b>phone Number: </b>${finalData.phoneNumber}</span><br/><br/><br/><b>Total Price: </b> ${isWeekend ? Naira.format(selectedPackage.weekendPrice) : Naira.format(selectedPackage.price)}`,
                    showCancelButton: true,
                    confirmButtonColor: '#1d4028',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Pay!',
                    didOpen: () => {
                        
                    },
                  }).then( async (response)=>{
                    if(response.isConfirmed){
                        bookRef.current.style.display = "none"
                        bookLoader.current.style.display = 'block'
                        try {
                            let reservation = await postRequest('make-tables-reservation',finalData)
                            if(reservation.data.status === true){
                                bookRef.current.style.display = "block"
                                 bookLoader.current.style.display = 'none'
                                setPaymentConfig(paymentConfig =>({
                                    ...paymentConfig,
                                    ...{'email': reservation.data.data.emailAddress,
                                    'amount': reservation.data.paymentRef.amount,'reference':reservation.data.paymentRef.paymentReference   
                                    }
                            }))
                            setReservationInProgress(reservation.data.data)
                            }else{
                                bookRef.current.style.display = "block"
                                 bookLoader.current.style.display = 'none'
                                cogoToast.error(response.data.message,{
                                    hideAfter:10
                                })
                            }
                        } catch (error) {
                            bookRef.current.style.display = "block"
                                 bookLoader.current.style.display = 'none'
                            cogoToast.error(error.response.data.message,{
                                hideAfter:10
                            })
                        }
                    }
                  })
        }  
      }
      const initializePayment = usePaystackPayment(paymentConfig);
      const onClose = () => {
          console.log("closed");
      }

      const onSuccess = (reference) =>{
        bookRef.current.style.display = "none"
        bookLoader.current.style.display = 'block'
        postRequest('finalize-tables-reservation',{reference:reference,"bookingDetails":reservationInProgress}).then((response)=>{
            if(response.data.status === true){
                bookRef.current.style.display = "block"
                bookLoader.current.style.display = 'false'
                Swal.fire({
                    icon: 'success',
                    title: 'Reservation Complete',
                    text: 'Your reservation has been made successfully, please check your email address for more details.',
                  }).then((response)=>{
                      if(response.isConfirmed){
                        navigate("/")
                      }
                  })
            }else{
                bookRef.current.style.display = "block"
                bookLoader.current.style.display = 'false'
                cogoToast.error(response.data.message,{
                    hideAfter:15
                })
            }
        }).catch((error )=>{
            bookRef.current.style.display = "block"
                bookLoader.current.style.display = 'false'
            cogoToast.success(error.response.data.message,{
                position:"bottom-right"
        })
        })
    }

    const fetchPackages = async () =>{
        try {
           let packages = await getRequest('fetch-tables-packages')
           if(packages.data.status){
                setTablesPackages(packages.data.data)
           }else{
            cogoToast.error(packages.data.message)
           }
        } catch (error) {
            cogoToast.error(error.response.data.message)
        }
    }
    let Naira = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
      });
    return <React.Fragment>
            <div className='container-fluid'>
                <div className='row'>
                <div className="col-md-12">
                    <NavBar/>
                </div>
                </div>
                <div className="row g-0">
                    <div className='col-md-1'>

                    </div>
                    <div className='col-md-10'>
                        <div className='booking-div'>
                            <h4>Table Reservation</h4>
                            <hr/>
                            <div className='row'>
                            <div className='col-md-4'>
                            <h5>Please Read</h5>
                            <p>
                             
                              <ol>
                                <li>Our guests are not allowed to bring any food or drink</li>
                                <li>Yolo boat services will coordinate transportation with time slots</li>
                                <li>Time frame 11am - 6pm</li>
                                </ol>  
                            </p>
                            <div className='d-none d-sm-none d-md-block'>
                            {
                                selectedPackage !== null ? <p><br/><br/><br/> <h5>Package Features</h5> <hr/>{
                                   <span style={{'color':'green'}}>{selectedPackage.benefits}</span> 
                                    }</p> : ''
                            }
                            </div>
                            </div>
                            <div className='col-md-4'>
                                <div>
                                <small><b>Enter Contact Information</b></small>
                                <form>
                                <DatePicker
                     selected={startDate}
                      className="form-control"
                      onChange={(date) => setStartDate(date)}
                      dateFormat="d MMMM, yyyy h:mm aa"
                      timeFormat="HH:mm"
                      minDate={Date.now()}
                      timeIntervals={15}
                      timeCaption="time"
                      showTimeSelect
                      placeholderText="Select walk-in date"
                    />
                    <br/><br/>
                                    <input type='text' name='firstName' className='form-control' onChange={handleReservationFormChange} placeholder='Enter first name'/><br/>
                                    <input type='text' name='lastName' className='form-control' onChange={handleReservationFormChange} placeholder='Enter last name'/><br/>
                                    <input type='text' className='form-control' name='emailAddress' onChange={handleReservationFormChange} placeholder='Enter email address'/><br/>
                                    <input type='text' className='form-control' name='phoneNumber' onChange={handleReservationFormChange} placeholder='Enter phone number'/><br/>
                                    <select className='form-select' name="selectedPackage" onChange={handleReservationFormChange}>
                                        <option value="0">Select Package</option>
                                        {tablePackages !== null ? tablePackages.map((item) =>{
                                             return <option value={item.id} key={item.id}>{item.package} - {Naira.format(item.price)}</option>   
                                        }) : ''}
                                    </select>
                                    <div className='row'>
                                        <div className='col-6'>
            {/* <div>
            <small>Number of guests</small><br/>
                            <span className='number-counters'><FontAwesomeIcon onClick={decrease
                            } icon={faMinusSquare}/></span><span className="occupants-value">{numberOfGuests}</span> <span className='number-counters'><FontAwesomeIcon icon={faPlusSquare} onClick={increase} /></span> 
            </div> */}
            
                                        </div>
                                        <div className='col-6'>
                                                {/* <div>
                                                <br/>
                                                <input className="form-check-input" onChange={handleReservationFormChange} type="checkbox" value="yes" id="flexCheckDefault"/> <label className="form-check-label" for="flexCheckDefault">
                                                            Room Access?
                                                    </label> 
                                                </div> */}
                                                    </div>
                                    </div>
                      <br/>
                                    
                                </form>
                                <div className='d-block d-sm-none d-md-none d-lg-none' style={{"marginBottom":"10px"}}>
                                {
                                selectedPackage !== null ? <p><br/> <h5>Package Features</h5> <hr/>{
                                   <span style={{'color':'green'}}>{selectedPackage.benefits}</span> 
                                    }</p> : ''
                            }
                                    <h5><b>Reservation Summary</b></h5>
                                    <br/>
                                    <span><b>Walk-in Date: </b>{startDate === null ? '' : startDate.toLocaleString('en-US',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</span><br/><br/>
                                    <span><b>Email Address: </b>{reservationDetails.emailAddress}</span><br/><br/>
                                    <span><b>Number of Guests: </b>{numberOfGuests}</span><br/><br/>
                                    <span><b>Price:</b> {selectedPackage !== null ? Naira.format(selectedPackage.price) : ''}</span><br/><br/>
                                    <span><b>Weekend Price:</b> {selectedPackage !== null ? Naira.format(selectedPackage.weekendPrice) : ''}</span><br/>
                                </div>
                                <button className='small-button' ref={bookRef} onClick={makeReservation}>Proceed</button>
                                <div className='spinner-border' role='status' ref={bookLoader} style={{"display":"none"}}></div>
                                </div>
                                
                            </div>
                            <div className='col-md-4'>
                                <div className='d-none d-sm-block d-md-block d-lg-block d-xl-block'>
                                    <h5><b>Reservation Summary</b></h5>
                                    <br/>
                                    <span><b>Walk-in Date: </b>{startDate === null ? '' : startDate.toLocaleString('en-US',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</span><br/><br/>
                                    <span><b>Email Address: </b>{reservationDetails.emailAddress}</span><br/><br/>
                                    <span><b>Number of Guests: </b>{numberOfGuests}</span><br/><br/>
                                    <span><b>Price:</b> {selectedPackage !== null ? Naira.format(selectedPackage.price) : ''}</span><br/><br/>
                                    <span><b>Weekend Price:</b> {selectedPackage !== null ? Naira.format(selectedPackage.weekendPrice) : ''}</span><br/>
                                </div>
                            </div>
                        </div>
                        </div>
                        <br/><br/>
                    </div>
                    <div className='col-md-1'>

                    </div>
                </div>
                <Footer/>
                
            </div>
           
            <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Room Access</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Please select any of our availble chalets for an extra fee of {Naira.format(20000)}<br/>
            <select className='form-select' onChange={selectChalet}>
            <option>{chalets == null ? 'Loading chalets .....' : 'Select Chalets'}</option>
            {chalets !== null ? chalets.map((item,key)=>{
                
                return <option value={item.id}>{item.chaletName}</option>
            }) :''}
            </select>
          
        </Modal.Body>
        <Modal.Footer>
          <button className='book-button' style={{'margin':"0"}} onClick={makeReservation}>Make Reservation</button>
        </Modal.Footer>
      </Modal>
        </React.Fragment>
}

export default TablesReservationPage