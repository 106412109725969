import React, { useRef } from "react"
import { useState } from "react"
import { useEffect } from "react"
import { getRequest, postRequest } from "../../services/requests"
import cogoToast from 'cogo-toast'
import { SkeletonLoader } from "../../components/skeletonLoader"
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom"
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import moment from 'moment/moment';

export const Reservations = () =>{
    const [reservations,setReservation] = useState(null)
    const [lastRecord,setLastRecord] = useState(null)
    const [reservationInFocus,setReservationInFocus] = useState(null)
    const loadButton = useRef()
    const [editReservationState,setEditReservationState] = useState(false)
    const editChalet = useRef()
    const editReservation = useRef()
    const [optionalServiceId, setOptionalServiceId] = useState([]);
    const [specificUser, setSpecificUser] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [minCheckoutDateState,setMinCheckoutDateState] = useState(null);
    const [chalets,setChalets] = useState(null)
    const [newReservationModal, setNewReservationModal] = useState(false)
    const [occupants,setOccupants] = useState([])
    const [finalReservationDetails,setFinalReservationDetails] = useState(null)
    const [services,setServices] = useState(null)
    const [totalPrice,setTotalPrice] = useState(null)
    const optionalServicesRef = useRef(null)
    const [period,setPeriod] = useState(null)
    const [transportation,setTransportation] = useState(null)
    const [selectedTransportation,setSelectedTransportation] = useState(null)
    const availabilityRef = useRef(null)
    const MySwal = withReactContent(Swal)

    const navigate = useNavigate()

    useEffect(()=>{
        getAuth()
        fetchReservations()
        //fetchChalets()
        minCheckoutDate()
        fetchServices()
        fetchTransportation()
    },[])

 

    useEffect(()=>{
        if(reservations !== null){
            let lastElement = reservations[reservations.length -1]
                    setLastRecord(lastElement.id)
        }
    },[reservations])

    const fetchTransportation = async () =>{
        try {
            let response = await getRequest("fetch-transportation-admin");
            if (response.data.status === true) {
              setTransportation(response.data.transport);
            }
          } catch (error) {
            cogoToast.error(error.response.data.message, {
              position: "bottom-right",
            });
          }
    }
    const fetchServices = async () => {
        try {
          let fetchedServices = await getRequest("fetch-services");
          if (fetchedServices.data.status === true) {
            setServices(fetchedServices.data.services);
          }
        } catch (error) {
          cogoToast.error(error.response.data.message, {
            position: "bottom-right",
          });
        }
      };
    const handleTransportationChange = (e) =>{
        setSelectedTransportation(e.target.value)
    }
    const getAuth = async () =>{
        try {
          let authUser = await getRequest("get-authenticated-user") 
            if(authUser.data.status === false){
                navigate('/admin/index/')
            }
        } catch (error) {
            navigate('/admin/index/')
        }
    }
    const handleCloseReservationModal = () =>{
        setNewReservationModal(false)
        setOccupants([])
        setSelectedTransportation(null)
        setSpecificUser(null)
        setOptionalServiceId(null)
        setPeriod(null)
    }
    const shownewReservationModal = () =>{
        setNewReservationModal(true)
    }
    const fetchReservations = () =>{
        getRequest("get-reservations").then((response)=>{
            if(response.data.status === true){
                let responseData = response.data.reservations
                let lastElement = responseData[responseData.length -1]
                setLastRecord(lastElement.id)
                setReservation(response.data.reservations)
            }else{
                cogoToast.error(response.data.message,{
                    position:'bottom-right'
                })
            }
        }).catch((error)=>{
            if(error.response.data.status === false){
                cogoToast.error(error.response.data.message,{
                    position:'bottom-right'
                             });
            }
        })
    }
    const loadMore = () =>{
        if(lastRecord > 1){
            loadButton.current.childNodes[0].style.display = "none"
            loadButton.current.childNodes[1].style.display = "block"
            postRequest('load-more-reservations',{'id':lastRecord}).then((response)=>{
                if(response.data.status === true){
                    loadButton.current.childNodes[0].style.display = "block"
            loadButton.current.childNodes[1].style.display = "none"
                    setReservation([...reservations,...response.data.reservations])

                }else{
                    loadButton.current.childNodes[0].style.display = "block"
                    loadButton.current.childNodes[1].style.display = "none"
                }
            }).catch(()=>{
                loadButton.current.childNodes[0].style.display = "block"
                loadButton.current.childNodes[1].style.display = "none"
            })
        }
    }

    const handleCloseEditReservationState = () =>{
        setEditReservationState(false)
    }

    const handleOpenCloseEditReservationState = async (e) =>{
        setEditReservationState(true)
        let reservationId = e.target.getAttribute("res_id")
        try {
           let resDetails = await postRequest('get-reservation-details',{'id':reservationId})
           if(resDetails.data.status === true){
                resDetails.data.data.checkInDate = setDateTime(resDetails.data.data.checkInDate)
                resDetails.data.data.checkOutDate = setDateTime(resDetails.data.data.checkOutDate)
                setReservationInFocus(resDetails.data.data)
           }else{    
                cogoToast.error(resDetails.data.message,{
                    position:'bottom-right'
                             })
           }
        } catch (error) {
            cogoToast.error(error.response.data.message,{
                position:'bottom-right'
            })
        }
       
    }
    const setDateTime = (datetime) => {
        const dt = new Date(datetime);
        dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
        return dt.toISOString().slice(0, 16);
    }
    const fetchChalets = async () =>{
        try {
         const chalets = await getRequest('fetch-chalets-admin')
        if(chalets){
            if(chalets.data.status === true){
                setChalets(chalets.data.rooms)
            }else{
                cogoToast.error("An unkonwn error has occured",{
                    position:'bottom-right'
                })
            }
        }
        } catch (error) {
            cogoToast.error(error.response.data.message,{
                position:'bottom-right'
            })
        }
    }

    useEffect(()=>{
        console.log(specificUser)
    },[specificUser])

    const specificUserValue = (e) => {
        let specificUser = e.target.id;
        setSpecificUser(specificUser);
      };
    const minCheckoutDate = () => {
        let date = new Date()
        let minCheckoutDateVal = date.setDate(date.getDate() + 1)
        setMinCheckoutDateState(minCheckoutDateVal)
      }
    const updateReservationChange = (e) =>{
        const {name,value} = e.target
        setReservationInFocus({...reservationInFocus,[name]:value})
    }

    const makeEdit = async (e) =>{
        e.preventDefault()
        let finalData = {
            "reservationId":reservationInFocus.id,
            "firstName":reservationInFocus.firstName,
            "lastName":reservationInFocus.lastName,
            "emailAddress":reservationInFocus.email,
            "phoneNumber":reservationInFocus.phoneNumber,
            "checkInDate":reservationInFocus.checkInDate,
            "checkOutDate":reservationInFocus.checkOutDate
        }
        if(finalData.firstName === null || finalData.firstName === ''){
            cogoToast.error("Firstname is missing.",{
                position:'bottom-right'
            })
        }else if(finalData.lastName === null || finalData.lastName === ''){
            cogoToast.error("Lastname is missing.",{
                position:'bottom-right'
            })
        }else if(finalData.emailAddress === null || finalData.emailAddress === ''){
            cogoToast.error("Email address is missing.",{
                position:'bottom-right'
            })
        }else if(finalData.phoneNumber === null || finalData.phoneNumber === ''){
            cogoToast.error("Phone number is missing.",{
                position:'bottom-right'
            })
        }else if(finalData.checkInDate === null || finalData.checkInDate === ''){
            cogoToast.error("Select a check-in date",{
                position:'bottom-right'
            })
        }else if(finalData.checkOutDate === null || finalData.checkOutDate === ''){
            cogoToast.error("Select a checkout-date",{
                position:'bottom-right'
            })
        }else{
            try {
                editReservation.current.setAttribute('disabled',true)
                editReservation.current.childNodes[0].style.display = "none"
                editReservation.current.childNodes[1].style.display = "block"
               let updatedRes = await postRequest("/edit-reservation",finalData)  
               if(updatedRes.data.status === true){
                editReservation.current.childNodes[0].style.display = "block"
                editReservation.current.childNodes[1].style.display = "none"
                cogoToast.success(updatedRes.data.message,{
                    position:'bottom-right'
                })
                setReservation(updatedRes.data.reservations)
                setLastRecord(null)
                handleCloseEditReservationState()
               }else{
                editReservation.current.childNodes[0].style.display = "block"
                editReservation.current.childNodes[1].style.display = "none"
                cogoToast.error(updatedRes.data.message,{
                    position:'bottom-right'
                })
               }
            } catch (error) {
                editReservation.current.childNodes[0].style.display = "block"
                editReservation.current.childNodes[1].style.display = "none"
                cogoToast.error(error.data.message,{
                    position:'bottom-right'
                })
            }
        }
    }

    const checkAvailability = async (e) =>{
        let date1 = startDate
        let date2 = endDate
        //setRooms(null)
        try {
          if(date1 !== null && date2 !== null){
            availabilityRef.current.innerText = "Fetching Available Chalets..........."
            availabilityRef.current.style.display = 'block'
            availabilityRef.current.style.color = 'darkblue'
            let ac = await postRequest('fetch-available-chalets-conditions-admin',{
              'startDate':date1,
              'endDate':date2
            })
            if(ac.data.status === true){
              availabilityRef.current.innerText = "Done!"
              availabilityRef.current.style.color = 'darkblue'
              setTimeout(() => {
                availabilityRef.current.style.display = 'none'
              }, 3000);
              setChalets(ac.data.chalets)
            }else{
              setTimeout(() => {
                availabilityRef.current.style.display = 'none'
              }, 3000);
              cogoToast.error("Error finding available chalets")
            }
          }
        } catch (error) {
          setTimeout(() => {
            availabilityRef.current.style.display = 'none'
          }, 3000);
          cogoToast.error(error.response.data.message)
        }
       
      }
    const addOccupant = (e) =>{
        e.preventDefault()
        let firstName = e.target.firstName.value
        let lastName = e.target.lastName.value
        let emailAddress = e.target.emailAddress.value
        let phoneNumber = e.target.phoneNumber.value

        if(!firstName){
            cogoToast.error("Input firstname")
        }else if(!lastName){
            cogoToast.error("Input lastname")
        }else if(!emailAddress){
            cogoToast.error("Input Email Address")   
        }else if(!phoneNumber){
            cogoToast.error("Input phone Number")  
        }else{
            if(occupants.length < 2){
                setOccupants(occupants => [...occupants, {
                    'firstName':firstName,
                    'lastName':lastName,
                    'emailAddress':emailAddress,
                    'phoneNumber':phoneNumber
                }])
            }else{
                cogoToast.error("Maximum Occupants Reached")
            }
      
        }
        e.target.reset()
    }
    const reservationDetails = (e) =>{
        let reservationDetails = {
            'selectedChalet': e.target.value,
            'checkInDate':startDate,
            'checkOutDate':endDate,
        }
       setFinalReservationDetails(reservationDetails)
    }
    const periodChange = (e) =>{
        setPeriod(e.target.checked ? 1 : 0)
    }
   
    const optionalServicesFunction = (e) => {
        let service = e.target.checked;
        if (service === true) {
          let serviceId = e.target.value;
          let price = null;
          services.forEach((item) => {
            if (item.id === parseInt(serviceId)) {
              price = item.price;
            }
          });
          setOptionalServiceId([
            ...optionalServiceId,
            {
              serviceId: serviceId,
              userId: specificUser,
              price: price,
            },
          ]);
        } else {
          let serviceId = e.target.value;
          setOptionalServiceId(
            optionalServiceId.filter((item) => {
              return (
                item.userId !== parseInt(specificUser) &&
                serviceId !== item.serviceId
              );
            })
          );
        }
      }
      
      const makeReservation = async () =>{
        if(!startDate){
            cogoToast.error('Select a check-in date')
        }else if(!endDate){
            cogoToast.error('Select a check-out date')
        }else if(finalReservationDetails == null || !finalReservationDetails.selectedChalet || finalReservationDetails.selectedChalet == '0'){
            cogoToast.error('Select a challet')
        }else if(occupants.length < 1){
            cogoToast.error('Add an occupant')
        }else{
            let totalPrice = 0
            let date1 = moment(startDate)
            let date2 = moment(endDate)
            let lodgeDays = date2.diff(date1,'days')
            chalets.forEach((item)=>{
                if(item.id == finalReservationDetails.selectedChalet){
                    if(period == 1){
                        totalPrice = totalPrice + parseInt(item.overNight) * lodgeDays
                    }else{
                        totalPrice = totalPrice + parseInt(item.dayTime) * lodgeDays
                    }
                   
                }
            })
            if(optionalServiceId.length > 0){
                optionalServiceId.map((item) =>{
                    totalPrice = totalPrice + parseInt(item.price)
                })
            }
         
            if(selectedTransportation !== null && selectedTransportation !== 0){
                transportation.forEach((item)=>{
                    if(item.id == selectedTransportation){
                        totalPrice = totalPrice + parseInt(item.price)
                    }
                })
            }
            setTotalPrice(totalPrice)
            let finalDetails = {
                'meta':finalReservationDetails,
                'occupants':occupants,
                'period':period == null ? 0 : period,
                'optionalServicesId':optionalServiceId,
                'transport':selectedTransportation
            }
            MySwal.fire({
                title: <p></p>,
                html:`<h4>Total Price.</h4><br/>
                    <h5>${Naira.format(totalPrice)}</h5><br/>
                `,
                showCancelButton: true,
                confirmButtonColor: '#1d4028',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Proceed',
                didOpen: () => {
                
                
                },
              }).then(async(response) =>{
                if(response.isConfirmed){
                    try {
                        let response = await postRequest('admin-chalet-reservation',finalDetails)
                        if(response.data.status){
                            handleCloseReservationModal()
                            setOccupants([])
                            setSelectedTransportation(null)
                            setSpecificUser(null)
                            setOptionalServiceId([])
                            setStartDate(null)
                            setEndDate(null)
                            setPeriod(null)
                            cogoToast.success(response.data.message)
                        }else{
                          cogoToast.error(response.message)
                        }
                    } catch (error) {
                        cogoToast.error(error.response.data.message)
                    }
                
                }
              })
        }
            
      }

    const viewMoreDetails = async (reservationId,e) =>{
        e.target.innerText = "Loading...."
        try {
            
            let details = await postRequest('admin-fetch-optional-services',{
                "id":reservationId
            })
            
            let displayItems = ""

            if(details.data.data.length < 1){
                displayItems = "NO OPTIONAL SERVICE SELECTED."
            }else{
                details.data.data.map((item) =>{
                    return displayItems = displayItems + "<span>"+item.Service.name+"</span>, "
                })
            }
          
            
            if(details.data.status){
                e.target.innerText = "View More Details"
                MySwal.fire({
                    title: <p></p>,
                    html:`<div>
                    <b style="font-size:26px;">Other Services Selected</b><br/><br/>
                    <div style='text-align:left;'>
                    ${displayItems}
                    </div>
                    </div>
                    `,
                    didOpen: () => {
                    
                    
                    },
                  }).then(async(response) =>{
                    if(response.isConfirmed){
                        
                    }
                  })
            }else{
                e.target.innerText = "View More Details"
                cogoToast.error("Unable to load details")
            }
        } catch (error) {
            e.target.innerText = "View More Details"
            cogoToast.error("Unable to load details")
        }
      
    }
    let Naira = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
      });
      
    return(
        <div className="admin-views-container">
            <div className="row">
                <div className="col-md-12">
                        <div className="inner-admin-container">
                        <br/>
                        <br/>
                        <i className="bi bi-plus-circle-fill add-challet-icon" onClick={shownewReservationModal}>New Reservation</i>
                        <hr/>
                            <input type="text" placeholder="search"/>
                            <div className="admin-tables-box">
                            <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email Address</th>
                                <th>Phone Number</th>
                                <th>Room</th>
                                <th>Checkin Date</th>
                                <th>Checkout Date</th>
                                <th>Edit</th>
                                <th>View More</th>
                                <th>Checkout</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reservations === null ? 
                                <>
                                    <td> <SkeletonLoader spec={{"count":10}}/></td>
                                    <td> <SkeletonLoader spec={{"count":10}}/></td>
                                    <td> <SkeletonLoader spec={{"count":10}}/></td>
                                    <td> <SkeletonLoader spec={{"count":10}}/></td>
                                    <td> <SkeletonLoader spec={{"count":10}}/></td>
                                    <td> <SkeletonLoader spec={{"count":10}}/></td>
                                    <td> <SkeletonLoader spec={{"count":10}}/></td>
                                    <td> <SkeletonLoader spec={{"count":10}}/></td>
                                    <td> <SkeletonLoader spec={{"count":10}}/></td>
                                    <td> <SkeletonLoader spec={{"count":10}}/></td>
                                </>
                                 : reservations.map((item,key)=>{
                                    return <tr> 
                                         <td>
                                        {item.firstName}
                                    </td>
                                    <td>
                                        {item.lastName}
                                    </td>
                                    <td>
                                        {item.email}
                                    </td>
                                    <td>
                                        {item.phoneNumber}
                                    </td>
                                    <td>
                                        {item.Chalet.chaletName}
                                    </td>
                                    <td>
                                        {new Date(item.checkInDate).toLocaleString()}
                                    </td>
                                    <td>
                                        {new Date(item.checkOutDate).toLocaleString()}
                                    </td>
                                    <td>
                                    <div ref={editChalet}><i className="bi bi-pencil-square chalet-icons"  res_id={item.id} onClick={handleOpenCloseEditReservationState}>Edit Reservation</i></div>
                                    </td>
                                    <td>
                                    <div ref={editChalet}><i className="bi bi-eye" style={{"cursor":"pointer"}} ref={optionalServicesRef} onClick={(e) =>{
                                        viewMoreDetails(item.id,e)
                                    }}>View More Details</i></div>
                                    </td>
                                    <td>
                                    <i className="bi bi-box-arrow-left" style={{"color":"red"}}>Checkout</i>
                                    </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                            </div>
                        <div ref={loadButton}>
                        <i className="bi bi-arrow-clockwise load-more-button" onClick={loadMore} id="reservationsLoadMore">Load More</i>
                        <div className="spinner-border" role="status" style={{"display":"none"}}><span className="visually-hidden">Loading...</span></div>
                        </div>
                        <br/><br/>
                        </div>
                </div>
            </div>

            <Modal show={editReservationState} onHide={handleCloseEditReservationState} animation={false}>
        <Modal.Header closeButton>
            Edit Reservation
        </Modal.Header>
        <Modal.Body>
        <form onSubmit={makeEdit}>
            {reservationInFocus !== null ? <div>
                <small>Firstname</small>
                <input type='text' onChange={updateReservationChange} className="form-control form-control-admin" value={reservationInFocus.firstName} name="firstName" placeholder="First Name"/><br/>
                <small>Lastname</small>
                <input type='text' className="form-control form-control-admin" onChange={updateReservationChange} value={reservationInFocus.lastName} name="lastName" placeholder="Last Name"/><br/>
                <small>Email Address</small>
                <input type='text' className="form-control form-control-admin" onChange={updateReservationChange} value={reservationInFocus.email} name="email" placeholder="Email Address"/><br/>
                <small>Phone Number</small>
                <input type='text' className="form-control form-control-admin" onChange={updateReservationChange} value={reservationInFocus.phoneNumber} name="phoneNumber" placeholder="Phone Number"/><br/>
            </div>:''}
                <small>Check-in Date</small>
                <input type="datetime-local"  name="checkInDate" value={reservationInFocus !== null ? reservationInFocus.checkInDate : ''} onChange={updateReservationChange} placeholder="Check-In Date" className="form-control"/>
                    <br/>
                        <small>Check-out Date</small>
                        <input type="datetime-local" name="checkOutDate" onChange={updateReservationChange} placeholder="Check-Out Date" value={reservationInFocus !== null ? reservationInFocus.checkOutDate : ''}  className="form-control"/>
                    <br/><br/>
                    <button ref={editReservation} onClick={makeEdit} className="small-button" style={{"textAlign":"center"}}>
                    <span>Update</span>
                        <div className="spinner-border" role="status" style={{"display":"none","textAlign":"center"}}><span className="visually-hidden">Loading...</span></div>
                    </button>
        </form>
        </Modal.Body>
      </Modal>

      <Modal show={newReservationModal} size="xl" onHide={handleCloseReservationModal} animation={false}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                    <div className="col-md-6">
                    <h5>Occupant Details</h5>
                        <div>
                            <form onSubmit={addOccupant}>
                                <input className="form-control" name="firstName" placeholder="Firstname"/><br/>
                                <input className="form-control" name="lastName" placeholder="Lastname"/><br/>
                                <input className="form-control" name="emailAddress" placeholder="Email Address"/><br/>
                                <input className="form-control" name="phoneNumber" placeholder="Phone Number"/><br/>
                                <button className="small-button">Add Occupant</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6">
                    <h5>Chalet & Date</h5>
                        <div>
            <DatePicker
            selected={startDate}
             className="form-control"
             onChange={(date) => {
               setStartDate(date)
               checkAvailability()
             }}
             showTimeSelect
             dateFormat="MMMM d, yyyy h:mm aa"
             timeFormat="HH:mm"
             timeIntervals={15}
             minDate={Date.now()}
             placeholderText="Select check-in date"
           />
               <br/><br/>
               <DatePicker
             selected={endDate}
             className="form-control"
             onChange={(date) => {
               setEndDate(date)
               checkAvailability()
             }}
             showTimeSelect
             dateFormat="MMMM d, yyyy h:mm aa"
             timeFormat="HH:mm"
             timeIntervals={15}
             minDate={minCheckoutDateState}
             placeholderText="Select check-out date"
           />
               <br/>
               
               <br/>
               <div id="availability-notification" ref={availabilityRef} style={{'display':'none','fontWeight':'bold','marginBottom':'10px'}}>

</div>
                        <select className="form-select" name="selectedChalet" onChange={reservationDetails}>
       <option value='0'>Select Chalet</option>
       {
           chalets !== null ? chalets.map((item) =>{
               return <option key={item.id} value={item.id}>{item.chaletName }</option>
           }): ''
       }
   </select>
   <br/>
 
               {/* <button className="small-button">Add Chalet</button> */}
                <div className="form-check">
                <input className="form-check-input" type="checkbox" onChange={periodChange} value="1" name="period" id="flexCheckChecked"/>
                <label className="form-check-label">
                    Overnight?
                </label>
                </div>
                    </div>
            </div>
            </div>
            <div className="row">
            <div className="col-md-4">
                <hr/>
                        <div className="admin-booking-summary">
                        <h5>Occupants Details</h5>
                            {occupants.length > 0 ? occupants.map((item,index) =>{
                                return <p style={{'fontSize':'14px'}} key={item.index}>
                                    <b>Firstname: </b> {item.firstName}<br/>
                                    <b>Lastname: </b> {item.lastName}<br/>
                                    <b>Email Address: </b> {item.emailAddress}<br/>
                                    <b>Phone Number: </b> {item.phoneNumber}<br/>
                                    <i className="bi bi-x-lg" style={{'color':'red','cursor':'pointer'}} id={index}>Remove</i>
                                </p>   
                            }) : 
                                <span>NO OCCUPANT ADDED</span>
                            }
                        </div>
                    </div>
                    <div className="col-md-4">
                    <hr/>
                    <div className="admin-booking-summary">
                        <h5>Optional Services</h5>
                        <div>
                            {
                                occupants.length > 0 ?
                                <select className="form-select" onChange={handleTransportationChange}>
                                <option value='0'>Select Transportation Service</option>
                                {transportation !== null ? transportation.map((item,index) =>{
                                return <option value={item.id} key={index}>{item.name}-{Naira.format(item.price)}</option>
                            }): '' }
                            </select>
                                : ''
                            }
                            <br/>
                        <div className="accordion" id="accordion1">
        {occupants.map((item,index) => {
          return (
            <div>
              <small>
                <b>Select other optional services for:</b>
              </small>
              <div
                className="accordion-item"
                key={index}
                style={{ marginBottom: "40px" }}
              >
                <h2
                  className="accordion-header"
                  id={"headingOne" + index}
                >
                  <button
                    className="accordion-button"
                    onClick={specificUserValue}
                    id={index}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={
                      "#collapse" + index
                    }
                    aria-controls="flush-collapse"
                  >
                    Select Services - {item.firstName + " " + item.lastName}
                  </button>
                </h2>
                <div
                  id={"collapse" + index}
                  className="accordion-collapse collapse"
                  aria-labelledby={
                    "headingOne" + index
                  }
                  data-bs-parent="#accordion1"
                >
                  <div className="accordion-body">
                    <br />

                    {services === null
                      ? ""
                      : services.map((item) => {
                          return (
                            <div
                              className="form-check form-check-inline"
                              key={item.id}
                              onChange={optionalServicesFunction}
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={item.id}
                                onChange={optionalServicesFunction}
                              />
                              <label className="form-check-label">
                                {item.name} - {Naira.format(item.price)}
                              </label>
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
                        </div>
                        </div>
                    
                    </div>
                    <div className="col-md-4">
                        <hr/>
                        <div className="admin-booking-summary">
                        <h5>Reservation Summary</h5>
                        <b><span>Check-in Date:  </span></b> { finalReservationDetails !== null ? moment(finalReservationDetails.checkInDate).format("dddd, MMMM Do YYYY, h:mm:ss a") : 'Select Check-in Date'}<br/>
                        <b><span>Check-out Date: </span></b> { finalReservationDetails !== null ? moment(finalReservationDetails.checkOutDate).format("dddd, MMMM Do YYYY, h:mm:ss a") : 'Select Check-in Date'}<br/>
                        {
                            chalets !== null && finalReservationDetails !== null ? chalets.map((item) =>{
                                if(item.id == finalReservationDetails.selectedChalet){
                                    return   <div>
                                        <b><span>DayTimePrice: </span></b> {Naira.format(item.dayTime)} <br/>
                                    <b><span>OverNightPrice: </span></b> {Naira.format(item.overNight)}<br/>
                                    
                                    </div>     
                                }
                            }) : <div>
                             
                            <b><span>Price: </span></b> Select Chalet <br/>
                        <b><span>Weekend Price: </span></b> Select Chalet<br/>
                        </div> 
                        }
                        <b><span>Number of Occupants: </span></b> {occupants !== null ? occupants.length : "Input Occupants"}<br/>
                        </div>
                    </div>
            </div>
            <div className="row">
                    <div className="col-md-12">
                        <div>
                            <hr/>
                            <button className="book-button" onClick={makeReservation}>Proceed</button>
                        </div>
                    </div>
            </div>
        </Modal.Body>
      </Modal>
        </div>
    )
}