import React, { useEffect, useState,useRef } from "react"
import { getRequest, postRequest } from "../services/requests"
import SubMembersPage from "./subMembersPage"
import ProfilePage from "./membershipProfilePage"
import { useNavigate } from "react-router-dom"
import MembershipHomePage from "./membershipHomePage"
import cogoToast from "cogo-toast"

const MembershipDashboard = () =>{
    const navigate = useNavigate();
    const [userDetails,setUserDetails] = useState(null)
    const [activeOption,setActiveOption] = useState(<MembershipHomePage/>)
    const [navigationIcon,setNavigationIcon] = useState(false)
    const thisSideNav = useRef(null)
    
    const logoutLoader = useRef(null)
    const logoutButton = useRef(null)
    useEffect(() =>{
        getAuth()
    },[])
   

    const handleNavigationChange = () =>{
        if(navigationIcon === false){
            setNavigationIcon(true)
            thisSideNav.current.style.width = "200px"
        }else{
            setNavigationIcon(false)
            thisSideNav.current.style.width = "0"
        }
    }

    const getAuth =  async() =>{
            try {
              let authUser = await getRequest("get-authenticated-user-membership") 
                if(authUser.data.status === false){
                    navigate('/membership')
                }else{
                    setUserDetails(authUser.data.data2)
                    setActiveOption(<MembershipHomePage userDetails={authUser.data.data2}/>)
                }
            } catch (error) {
                navigate('/membership')
            }
    }
    
    const showProfilePage = (mobile = false) =>{
        setActiveOption(<ProfilePage profile={userDetails}/>)
        if(mobile){
            thisSideNav.current.style.width = "0"
            setNavigationIcon(false)
        }
    }
    const showMembersPage = (mobile = false) =>{
        setActiveOption(<SubMembersPage membershipPlan={userDetails.membershipPlan}/>)
        if(mobile){
            thisSideNav.current.style.width = "0"
            setNavigationIcon(false)
        }
    }
    const showMembershipHomePage = (mobile = false) =>{
        setActiveOption(<MembershipHomePage membershipPlan={userDetails.membershipPlan}/>)
        if(mobile){
            thisSideNav.current.style.width = "0"
            setNavigationIcon(false)
        }
    }
    const logOut = async () =>{
        cogoToast.loading('Loging out .......').then( async () => {
            try {
                let logOut = await postRequest('membership-logout',{})
                if(logOut.data.status === true){
                    cogoToast.success(logOut.data.message);
                    navigate("/membership")
                }else{
                    cogoToast.error(logOut.data.message);
                }
            } catch (error) {
                cogoToast.error(error.response.data.message);
            }
          });
         
    }
    return(   
        <React.Fragment>
            <div className="container-fluid">
                <div className="row g-0">
                    <div className="col-md-2">
                        <div className="membership-side-nav d-sm-none d-none d-md-block d-lg-block d-xl-block">
                            <div style={{'marginTop':'50px','padding':'10px'}}>
                            <div className="m-sourounding" onClick={showMembershipHomePage} >
                                <span className="m-side-nav-options"  onClick={showMembershipHomePage}><i className="bi bi-house"></i> Home</span>
                                </div>
                                <br/>
                                <div className="m-sourounding" onClick={showProfilePage}>
                                <span className="m-side-nav-options"  onClick={showProfilePage}><i className="bi bi-person-circle"></i> Profile</span>
                                </div>
                            <br/>
                            <div className="m-sourounding" onClick={showMembersPage}>
                            <span className="m-side-nav-options" onClick={showMembersPage}><i className="bi bi-people"></i> My Members</span>    
                            </div>
                            <br/>
                            <div className='spinner-border' role='status' ref={logoutLoader} style={{"display":"none"}}></div>
                            <div className="m-sourounding" ref={logoutButton} onClick={logOut}><span onClick={showMembershipHomePage} className="m-side-nav-options"><i className="bi bi-box-arrow-left"></i> Logout</span></div><br/><br/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="membership-box">
                        {activeOption}
                        </div>
                    
                    </div>
                </div>
                
            </div>
            <div className="admin-side-nav-responsive" ref={thisSideNav}>
            <div className="m-sourounding" onClick={showMembershipHomePage} >
                                <span className="m-side-nav-options"  onClick={showMembershipHomePage}><i className="bi bi-house"></i> Home</span>
                                </div>
                                <br/>
                                <div className="m-sourounding" onClick={showProfilePage}>
                                <span className="m-side-nav-options"  onClick={showProfilePage}><i className="bi bi-person-circle"></i> Profile</span>
                                </div>
                            <br/>
                            <div className="m-sourounding" onClick={showMembersPage}>
                            <span className="m-side-nav-options" onClick={showMembersPage}><i className="bi bi-people"></i> My Members</span>    
                            </div>
                            <br/>
                            <div className="m-sourounding" onClick={logOut}><span onClick={showMembershipHomePage} className="m-side-nav-options"><i className="bi bi-box-arrow-left"></i> Logout</span></div><br/><br/>
            <div id="floating-menu-icon" className="d-block d-sm-block d-md-block d-lg-none d-xl-none">
                    {navigationIcon === false ? <i className="bi bi-list d-block d-sm-block d-md-block d-lg-none d-xl-none" id="menu-button-admin" onClick={handleNavigationChange}></i> : <i onClick={handleNavigationChange} className="bi bi-x-lg d-block d-sm-block d-md-block d-lg-none d-xl-none"></i>}
            </div>
        </div>
        </React.Fragment>
    )
}

export{
    MembershipDashboard
}