import cogoToast from 'cogo-toast'
import React,{useRef,useEffect,useState} from 'react'
import { postRequest} from '../services/requests'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const SubMembersPage = (props) =>{
    const addMemberLoaderRef = useRef(null)
    const addMemberButton = useRef(null)
    const [fetchedSubMembers,setFetchedSubmembers] = useState(null)
    const MySwal = withReactContent(Swal)

    useEffect(() =>{
        if(props.membershipPlan){
            fetchMembers()
        }
    },[])
    
    const fetchMembers = async () =>{
        try {
            let subMembers = await postRequest('fetch-sub-members',{
                'membershipId':props.membershipPlan
            })
            if(subMembers.data.status === true){
                setFetchedSubmembers(subMembers.data.members)
            }else{
                cogoToast.error(subMembers.data.message)
            }
        } catch (error) {
            cogoToast.error(error.response.data.message)
        }
    }
    
    const addMember = async (e) =>{
        e.preventDefault()
        let finalData = {
            'firstName':e.target.firstName.value,
            'middleName':e.target.middleName.value,
            'lastName':e.target.lastName.value,
            'emailAddress':e.target.emailAddress.value,
            'phoneNumber':e.target.phoneNumber.value
        }
        if(!finalData.firstName.trim()){
            cogoToast.error("First name is required.")
        }else if(!finalData.lastName.trim()){
            cogoToast.error('Last name is required.')
        }else if(!finalData.emailAddress.trim()){
            cogoToast.error('Email Address is required.')
        }else if(!finalData.phoneNumber.trim()){
            cogoToast.error('Phone number is required')
        }else{
            try {
                addMemberLoaderRef.current.style.display = "block"
                addMemberButton.current.style.display = 'none'
                let member = await postRequest('add-sub-member',finalData)
                if(member.data.status === true){
                    addMemberLoaderRef.current.style.display = "none"
                addMemberButton.current.style.display = 'block'
                setFetchedSubmembers(member.data.members)
                    cogoToast.success(member.data.message)
                }else{
                    addMemberLoaderRef.current.style.display = "none"
                addMemberButton.current.style.display = 'block'
                    cogoToast.error(member.data.message)
                }
            } catch (error) {
                addMemberLoaderRef.current.style.display = "none"
                addMemberButton.current.style.display = 'block'
                    cogoToast.error(error.response.data.message)
            }
            
        }
    }
    const removeMember = async (e) =>{
        let id = e.target.getAttribute('id')
        MySwal.fire({
            title: <p></p>,
            html:`Are you sure you want to remove this member from list?`,
            showCancelButton: true,
            confirmButtonColor: '#1d4028',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!',
            didOpen: async () => {
         
            },
          }).then( async (response)=>{
            if(response.isConfirmed){
                try {
                    let removed = await postRequest('remove-sub-members',{
                         recordId:id
                     })  
                     if(removed.data.status === true){
                         setFetchedSubmembers(removed.data.members)
                         cogoToast.success(removed.data.message)
                     } else{
                         cogoToast.error(removed.data.message)
                     }
                 } catch (error) {
                     cogoToast.error(error.response.data.message)
                 }
            }
        })    
    }
    return(
        
        <React.Fragment>
            <div className='row'>
                <div className='col-md-12'>
                    <div style={{'padding':'10px','width':'100%'}}>
                        <br/>
            <div className="row">
                <div className="col-md-12">
                    <div style={{'cursor':'pointer'}}>
                    <i className="bi bi-person-add sub-member-action" data-bs-toggle="modal" data-bs-target="#formModal"> Add Members</i>
                    </div>
              
                </div>
            </div>
            <hr/>
            <div style={{'overflowX':'auto'}}>
            <table class="table">
  <thead>
    <tr>
      <th scope="col">First Name</th>
      <th scope="col">Middle Name</th>
      <th scope="col">Last Name</th>
      <th scope="col">Email Address</th>
      <th scope="col">Phone Number</th>
      <th scope="col">Remove Member</th>
    </tr>
  </thead>
  <tbody>
   {fetchedSubMembers !== null ? fetchedSubMembers.map((item)=>{
    return <tr>
        <td>{item.firstName}</td>
        <td>{item.middleName}</td>
        <td>{item.lastName}</td>
        <td>{item.emailAddress}</td>
        <td>{item.phoneNumber}</td>
        <td><i className="bi bi-x sub-member-action" id={item.id} onClick={removeMember} style={{'color':'red'}}>Remove Member</i></td>
    </tr>
   }) : ''}
  </tbody>
</table>
            </div>
    
                    </div>
                </div>
            </div>

{/* Modal */}
<div class="modal fade" id="formModal" tabindex="-1" aria-labelledby="formModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="formModalLabel">Modal title</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <form onSubmit={addMember}>
                <input type='text' className='form-control' name='firstName' placeholder='First Name'/><br/>
                <input type='text' className='form-control' name='middleName' placeholder='Middle Name'/><br/>
                <input type='text' className='form-control' name='lastName' placeholder='Last Name'/><br/>
                <input type='text' className='form-control' name='emailAddress' placeholder='Email Address'/><br/>
                <input type='text' className='form-control' name='phoneNumber' placeholder='Phone Number'/><br/>
                <div className='spinner-border' role='status' ref={addMemberLoaderRef} style={{"display":"none"}}></div>
                <button className='small-button' ref={addMemberButton}>Add</button>
            </form>
      </div>
    </div>
  </div>
</div>
        </React.Fragment>
    )
}

export default SubMembersPage