import { Fade } from "react-awesome-reveal";
import Footer from "../components/footer";
import NavBar from "../components/NavBar";
import chalet from "../images/image5.jpg";
import room1 from "../images/room1.jpg";
import room2 from "../images/room2.jpg";
import room3 from "../images/room3.jpg";
import room4 from "../images/room4.jpg";
import room5 from "../images/room5.jpg";
import room6 from "../images/room6.jpg";
import { useEffect } from "react";

export const ChaletsPage = () => {
  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <NavBar />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div
            className="page-header"
            style={{ backgroundImage: `url(${chalet})` }}
          >
            <div className="page-inner-header">
              <p className="page-heading">Chalets</p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p style={{ fontSize: "18px" }}>
              Discover luxury villas in YOLO with private terraces and seemingly
              endless Atlantic Ocean views, natural splendor and much more
              abound. A wash in custom-made furniture, neutral whites, natural
              light and warm materials, the pared-back and minimalist interiors
              deliver a truly relaxing atmosphere. There’s a real sense of being
              miles from your everyday life but somehow cozy in a familiar
              luxury with all the things you need to stay connected. We’re
              talking king-size beds, rainfall showers, Apple TV and, High-Speed
              Internet, not to mention refillable luxury organic bath amenities
            </p>
            <p style={{ color: "brown" }}>
              Our chalets come in various various and they are...
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <Fade triggerOnce={true}>
              <div
                className="chalet-circle"
                style={{ backgroundImage: `url(${room1})` }}
              ></div>
              <br />
              <div className="inner-chalet-boxes-page">
                <h5>Cancun</h5>
                <hr />
                Step into the vibrant world of Cancun, right here on our island paradise. The Cancun Chalet is a spacious A-frame duplex that can comfortably accommodate up to 20 guests. With skylights that usher in the tropical sun and a private swimming pool, your stay here promises to be as vibrant as the Mexican city it's named after.
              </div>
            </Fade>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <Fade delay={1000} triggerOnce={true}>
              <div
                className="chalet-circle"
                style={{ backgroundImage: `url(${room2})` }}
              ></div>
              <br />
              <div className="inner-chalet-boxes-page">
                <h5>Bora Bora</h5>
                <hr />
                Experience the serenity of Bora Bora in our luxurious chalet. This beachfront haven boasts the same capacity for 20 guests, skylights to stargaze at night, and its own private swimming pool. Let the tranquil waters of Bora Bora inspire your island getaway.
              </div>
            </Fade>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <Fade delay={2000} triggerOnce={true}>
              <div
                className="chalet-circle"
                style={{ backgroundImage: `url(${room3})` }}
              ></div>
              <br />
              <div className="inner-chalet-boxes-page">
                <h5>Ibiza</h5>
                <hr />
                For those who seek the vibrant nightlife and cultural richness of Ibiza, our chalet is an ideal choice. Dance the night away in your private paradise, then relax in the comfort of your spacious duplex. Skylights and a private pool complete this Ibiza-inspired haven.
              </div>
            </Fade>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <Fade delay={3000} triggerOnce={true}>
              <div
                className="chalet-circle"
                style={{ backgroundImage: `url(${room4})` }}
              ></div>
              <br />
              <div className="inner-chalet-boxes-page">
                <h5>Madagascar</h5>
                <hr />
                Embark on an exotic journey with the Madagascar Chalet. The duplex's skylights allow you to bask in the sun by day and stargaze by night. With a capacity for 20 guests, it's perfect for group getaways. Dive into your private pool and let the spirit of Madagascar enchant you.
              </div>
            </Fade>
          </div>
          <div className="col-md-4">
            <Fade delay={4000} triggerOnce={true}>
              <div
                className="chalet-circle"
                style={{ backgroundImage: `url(${room5})` }}
              ></div>
              <br />
              <div className="inner-chalet-boxes-page">
                <h5>Santorini</h5>
                <hr />
                Recreate the magic of Greece's Santorini in our chalet. With its characteristic white and blue decor, skylights for natural light, and a private pool, you'll feel like you've been transported to the Aegean Sea. Accommodating up to 20 guests, it's the perfect blend of elegance and comfort.

              </div>
            </Fade>
          </div>
          <div className="col-md-4">
            <Fade delay={5000} triggerOnce={true}>
              <div
                className="chalet-circle"
                style={{ backgroundImage: `url(${room6})` }}
              ></div>
              <br />
              <div className="inner-chalet-boxes-page">
                <h5>Fiji</h5>
                <hr />
                Escape to the tropical paradise of Fiji without leaving the Atlantic shores. Our Fiji Chalet offers an immersive experience with its skylights, room for 20 guests, and a private swimming pool. Feel the island vibes and unwind in style.
              </div>
            </Fade>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
};
