import React, { useEffect } from "react";
import Footer from "../components/footer";
import NavBar from "../components/NavBar";
import restaurantImage from "../images/restaurant.jpg"
import barImage from "../images/bar.jpg"

export const RestaurantAndBar = () => {
  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])
  return (
    <React.Fragment>
           <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <NavBar />
        </div>
      </div>
    </div>
     <div className="container">
     <div className="row">
       <div className="col-md-6">
         <div className="restaurant-bar-div">
            <h4>Yolo Restaurant</h4>
           The restaurant and bar services at YOLO Island offer guests a
           variety of dining and drinking options to enhance their stay at the
           resort. The restaurant serves a range of cuisines including local
           and international options to cater to the diverse tastes of guests.
           The menu offers a variety of options to choose from, whether it be a
           quick snack or a full-course meal. The restaurant offers fine dining
           options for guests looking for a more upscale dining experience. The
           restaurant is typically well-decorated and has a pleasant atmosphere
           which creates a great ambiance for guests to enjoy their meals. The
           restaurant also offers a range of vegetarian and vegan options, as
           well as catering to any dietary restrictions or allergies guests may
           have.<br/><br/>
           The restaurant and bar services also offer 
a range of options for breakfast, with 
guests having the option to choose from 
buffet, chalet or floating pool breakfast. 
This allows guests to choose the type of 
breakfast that best suits their preferences, 
whether they prefer a traditional buffet or 
something more intimate and private
<br/><br/>
<a href="/menu">click here to view our menu</a>
         </div>
       </div>

       <div className="col-md-6">
            <div className="restaurant-bar-div" style={{
                      backgroundImage: `url(${restaurantImage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}>

            </div>
       </div>
     </div>
     <div className="row">
       <div className="col-md-6">
         <div className="restaurant-bar-div">
            <h4>Yolo Bar</h4>
            The bar is open 24 hours a day, providing 
guests with a variety of drink options 
throughout the day and night. The bar 
serves a wide range of alcoholic and 
non-alcoholic beverages, including local 
and international beers, wines, cocktails, 
and mocktails. The bar also offers an 
extensive list of signature drinks, special 
offers, and happy hours throughout the 
day.<br/><br/>
The restaurant and bar services also offer 
a range of options for breakfast, with 
guests having the option to choose from 
buffet, chalet or floating pool breakfast. 
This allows guests to choose the type of 
breakfast that best suits their preferences, 
whether they prefer a traditional buffet or 
something more intimate and private
<br/><br/>
<a href="/menu">click here to view our menu</a>
         </div>
       </div>

       <div className="col-md-6">
            <div className="restaurant-bar-div" style={{
                      backgroundImage: `url(${barImage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}>

            </div>
       </div>
     </div>
     <br/><br/><br/>
     </div>
     <Footer/>
    </React.Fragment>
 
  );
};
