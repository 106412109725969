import React from "react";
import LandingPage from "./pages/landingPage";
import RouteComponent from "./services/router";
function App() {
  return (
    <RouteComponent/>
  );
}

export default App;
