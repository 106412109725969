import { useEffect, useState } from "react"
import SideNav from "../components/sideNav"
import Chalets from "../components/chalets"
import { Reservations } from "../components/reservations"
import { AdminResponsiveSideNav } from "../components/adminSideNav"
import PaymentPage from "../components/payments"
import { DashboardHome } from "../components/dashboardHome"
import { getRequest, postRequest } from "../../services/requests"
import { useNavigate } from "react-router-dom"
import FoodMenuPage from "../components/foodMenuPage"
import MealOrder from "../components/mealOrder"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import cogoToast from "cogo-toast"
import VisitReservations from "../components/visitReservations"
import EventPage from "../components/eventsPage"

const Dashboard = () =>{
    const [view,setView] = useState(<DashboardHome/>)
    const MySwal = withReactContent(Swal)

    const navigate = useNavigate()
    useEffect(()=>{
        getAuth()
    },[])

    const getAuth = async () =>{
        try {
          let authUser = await getRequest("get-authenticated-user") 
            if(authUser.data.status === false){
                navigate('/admin/index/')
            }
        } catch (error) {
            navigate('/admin/index/')
        }
    }
    const showReservations = () =>{
        setView(<Reservations/>)
    }

    const showChaletView = () =>{
        setView(<Chalets/>)
    }

    const showPayments = () =>{
        setView(<PaymentPage/>)
    }

    const showHome = () =>{
        setView(<DashboardHome/>)
    }
    const showFood = () =>{
        setView(<FoodMenuPage/>)
    }
    const showMealOrders = () =>{
        setView(<MealOrder/>)
    }

    const showVisitReservations = () =>{
        setView(<VisitReservations/>)
    }

    const showEvents = () =>{
        setView(<EventPage/>)
    }
    const logout = async () =>{
        cogoToast.loading('Logging Out............').then(async() => {
            try {
                let response = await postRequest('logout-admin',{})
                if(response.data.status){
                    cogoToast.success(response.data.message)
                    navigate("/admin/index");
                }else{
                    cogoToast.error(response.data.message)
                }   
            } catch (error) {
                cogoToast.error(error.response.data.message)   
            }
        });
    }
    return (
        <div className="container-fluid">
            
            <div className="row g-0">
                <div className="col-lg-2">
                                    <SideNav switch={{"reservations":showReservations,"chalets":showChaletView,'payments':showPayments,'home':showHome,'food':showFood, "mealOrder":showMealOrders,'visit':showVisitReservations,'events':showEvents}} logout={logout}/>    
                </div>
                <div className="col-lg-10">
                <div className="admin-content">
                {view}
              <AdminResponsiveSideNav switch={{"reservations":showReservations,"chalets":showChaletView,'payments':showPayments,'home':showHome,'food':showFood, "mealOrder":showMealOrders,'visit':showVisitReservations,'events':showEvents}} logout={logout}/>
                </div>
                </div>
             
                </div>
        </div>
    )
}

export default Dashboard