import React, { useState } from "react";
import { useEffect } from "react";
import cogoToast from "cogo-toast";
import { BookingSummary } from "./bookingSummary";
import { OptionalServices } from "./optionalServices";

const GuestDetails = (props) => {
  const [forms, setForms] = useState([]);
  const [showServices, setShowServices] = useState(false);
  const [optionals, setOptionals] = useState(null);
  const [transport, setTransport] = useState(null);

  useEffect(() => {
    if (props.occupants.action === "i") {
      setForms([
        ...forms,
        {
          firstName: "",
          lastName: "",
          emailAddress: "",
          phoneNumber: "",
          id: props.occupants.value,
        },
      ]);
    } else if (props.occupants.action === "d") {
      const rows = [...forms];
      rows.splice(-1);
      setForms(rows);
    }
  }, [props.occupants.value, props.occupants.action]);
  useEffect(()=>{

  },[props])
  const submitForms = (e) => {
    e.preventDefault();
    let formValidation = forms.every((element) => {
      if (element.firstName === "") {
        cogoToast.error(`Please input a first name for occupant ${element.id}`);
        return false;
      } else if (element.lastName === "") {
        cogoToast.error(`Please input a last name for occupant ${element.id}`);
        return false;
      } else if (element.emailAddress === "") {
        cogoToast.error(
          `Please input a email address for occupant ${element.id}`
        );
        return false;
      } else if (element.phoneNumber === "") {
        cogoToast.error(
          `Please input a phone number for occupant ${element.id}`
        );
        return false;
      }
      return true;
    });
    if (formValidation === true) {
      if (
        props.room.roomId === null ||
        props.room === "" ||
        props.room.Id === "SELECT ROOM" ||
        props.room.category === null ||
        props.room.category === "" ||
        props.room.category === "SELECT ROOM"
      ) {
        cogoToast.error("Please select a room", {
          position: "bottom-right",
        });
      } else if (
        props.dates.checkinDate === "" ||
        props.dates.checkoutDate === "" ||
        props.dates.checkinDate === null ||
        props.dates.checkoutDate === null
      ) {
        cogoToast.error("Select a checkin date and checkout date", {
          position: "bottom-right",
        });
      } else {
        setShowServices(true);
        props.showServicesState(true);
      }
    }
  };

  const setOptions = (options) => {
    setOptionals(options);
  };
  const selectedTransportOption = (value) => {
    setTransport(value);
  };
  const HandleFormChange = (e, index) => {
    let data = [...forms];
    data[index][e.target.name] = e.target.value;
    setForms(data);
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-6">
          {
            showServices === false ? (
              <form onSubmit={submitForms}>
                {forms.map((data, index) => {
                  return (
                    <div className="contact-details-booking" key={index}>
                      <small>
                        <b>OCCUPANT {data.id}</b>
                      </small>

                      <input
                        type="text"
                        name="firstName"
                        value={forms.firstName}
                        onChange={(e) => HandleFormChange(e, index)}
                        id={"firstname" + data.id}
                        className="form-control"
                        placeholder="Firstname"
                      />
                      <br />
                      <small>
                        <b>Lastname</b>
                      </small>
                      <input
                        type="text"
                        name="lastName"
                        value={forms.lastName}
                        onChange={(e) => HandleFormChange(e, index)}
                        className="form-control"
                        placeholder="Lastname"
                      />
                      <br />
                      <small>
                        <b>Email Address</b>
                      </small>
                      <input
                        type="text"
                        name="emailAddress"
                        value={forms.emailAddress}
                        onChange={(e) => HandleFormChange(e, index)}
                        className="form-control"
                        placeholder="Email Address"
                      />
                      <br />
                      <small>
                        <b>Phone Number</b>
                      </small>
                      <input
                        type="text"
                        name="phoneNumber"
                        value={forms.phoneNumber}
                        onChange={(e) => HandleFormChange(e, index)}
                        className="form-control"
                        placeholder="Phone Number"
                      />

                      <br />
                    </div>
                  );
                })}
                <BookingSummary
                  summary={{
                    category: props.summary.category,
                    occupants: props.summary.occupants.value,
                    startDate:
                      props.summary.startDate !== null
                        ? props.summary.startDate.toLocaleString("en-US", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })
                        : null,
                    endDate:
                      props.summary.endDate !== null
                        ? props.summary.endDate.toDateString()
                        : null,
                    view: "sm",
                    startDate2:
                      props.summary.startDate !== null
                        ? props.summary.startDate.toLocaleTimeString("en-US")
                        : null,
                    price: props.summary.price,
                    options: optionals,
                    transport: transport,
                    period:props.summary.period
                  }}
                />

                <button className="small-button">Proceed</button>
              </form>
            ) : (
              // <div style={{"marginTop":"15px"}}>
              //          <i className="bi bi-arrow-left-square-fill" style={{"fontSize":"25px","cursor":"pointer"}} onClick={goBackToForm}></i>
              <OptionalServices
                summary={{
                  category: props.summary.category,
                  occupants: props.summary.occupants.value,
                  startDate:
                    props.summary.startDate !== null
                      ? props.summary.startDate.toLocaleString("en-US", {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })
                      : null,
                  endDate:
                    props.summary.endDate !== null
                      ? props.summary.endDate.toDateString()
                      : null,
                  view: "sm",
                  startDate2:
                    props.summary.startDate !== null
                      ? props.summary.startDate.toLocaleTimeString("en-US")
                      : null,
                  options: optionals,
                  selectedTransport: selectedTransportOption,
                  price: props.summary.price,
                  transport: transport,
                }}
                numberOfVisitors={forms}
                room={props.room}
                date={props.dates}
                options={setOptions}
                period={props.summary.period}
              />
            )
            // </div>
          }
        </div>
        <div className="col-md-6">
          <BookingSummary
            summary={{
              category: props.summary.category,
              occupants: props.summary.occupants.value,
              startDate:
                props.summary.startDate !== null
                  ? props.summary.startDate.toLocaleString("en-US", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  : null,
              endDate:
                props.summary.endDate !== null
                  ? props.summary.endDate.toDateString()
                  : null,
              view: "lg",
              startDate2:
                props.summary.startDate !== null
                  ? props.summary.startDate.toLocaleTimeString("en-US")
                  : null,
              price: props.summary.price,
              options: optionals,
              transport: transport,
            }}
            period={props.summary.period}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default GuestDetails;
