import NavBar from '../components/NavBar'
import Footer from '../components/footer'
import React,{ useEffect,useState,useRef } from 'react'
import cogoToast from 'cogo-toast'
import { getRequest, postRequest } from '../services/requests'
import DatePicker from "react-datepicker";
import { usePaystackPayment } from "react-paystack";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from "react-router-dom";

const Membership = () =>{
    
    const [country,setCountries] = useState(null)
    const [title, setTitle] = useState(null)
    const [DOB, setDOB] = useState(null);
    const [membershipPlans, setMembershipPlans] = useState(null);
    const [membershipInProgress,setMembershipInPorgress] = useState(null)
    const [paymentConfig,setPaymentConfig] = useState({
        reference: '',
        email: '', 
        amount: '', //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        publicKey: "pk_live_044580246ef8dd0a173b39730fbaca80b133a26b",
        currency:'USD'
    })
    const [gender,setGender] = useState(null)
    const loginRefLoader = useRef(null)
    const loginButtonRef = useRef(null)
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const membershipRef = useRef(null)
    const loaderRef = useRef(null)
    useEffect(()=>{
        window.scrollTo(0, 0)
      },[])

    useEffect(() =>{
        fetchTitles()
        fetchCountries()
        fetchMembership()
        fetchGender()
    },[])

    useEffect(()=>{
        initializePayment(onSuccess,onClose)
    },[paymentConfig])

    const fetchGender = async () =>{
        try {
         let gender = await getRequest('fetch-gender')
         if(gender.data.status){
             setGender(gender.data.gender)
         }else{
             cogoToast.error(gender.data.message)
         }
        } catch (error) {
         cogoToast.error(gender.data.message)
        }
       
     }
    const fetchTitles = async () => {
        try {
            const titles = await getRequest('get-titles')   
            if(titles.data.status == true){
                setTitle(titles.data.titles)
            }else{
                cogoToast.error('Unable to fetch titles')
            }
        } catch (error) {
            cogoToast.error('Unable to fetch titles')
        }
    }

    const fetchCountries = async () => {
        try {
            const countries = await getRequest('get-countries')
            if(countries.data.status === true){
                setCountries(countries.data.countries)
            }else{
                cogoToast.error('Unable to fetch countries')
            }
        } catch (error) {
            cogoToast.error('Unable to fetch countries')
        }
        
    }

    const fetchMembership = async () => {
        try {
            const countries = await getRequest('get-membership-plans')
            if(countries.data.status === true){
                setMembershipPlans(countries.data.plans)
            }else{
                cogoToast.error('Unable to fetch membership plans')
            }
        } catch (error) {
            cogoToast.error('Unable to fetch membership plans')
        }
        
    }
    const joinYolo = async (e) =>{
        e.preventDefault()
        let firstName = e.target.firstName.value
        let middleName = e.target.middleName.value
        let lastName = e.target.lastName.value
        let phoneNumber = e.target.phoneNumber.value
        let emailAddress = e.target.emailAddress.value
        let confirmEmail = e.target.confirmEmailAddress.value
        let password = e.target.password.value
        let confirmPassword = e.target.confirmPassword.value
        let dateOfBirth = DOB
        let Nationality = e.target.nationality.value
        let membershipPlan = e.target.plan.value
        let title = e.target.title.value
        let gender = e.target.gender.value

        if(firstName === null || firstName === ''){
            cogoToast.error('Enter your firstname')
        }else if(lastName === null || lastName === ''){
            cogoToast.error('Enter your lastname')
        }else if(phoneNumber === null || phoneNumber === ''){
            cogoToast.error('Enter your phone number')
        }else if(emailAddress === null || emailAddress === ''){
            cogoToast.error('Enter your email address')
        }else if(confirmEmail === null || confirmEmail === ''){
             cogoToast.error('Please Enter your email address to confirm')
        }else if(password === null || password === ''){
            cogoToast.error('Please create a password.')
        }else if(confirmPassword === null || confirmPassword === ''){
            cogoToast.error('Please confirm your password.')
        }else if(password !== confirmPassword){
            cogoToast.error("Passwords do not match")
        }else if(emailAddress !== confirmEmail){
            cogoToast.error('Email addresses do not match.')
        }else if(dateOfBirth === '' || dateOfBirth === null){
             cogoToast.error('Please select your date of birth')
        }else if(!Nationality || Nationality === "Select Nationality"){
            cogoToast.error('Please select a your nationality')
        }else if(!title || title === 'Select title'){
            cogoToast.error('Please select a title')
        }else if(!membershipPlan || membershipPlan === "Select Membership Plan"){   
            cogoToast.error('Please select a membership plan')
        }else if(!gender || gender === "Select gender"){
            cogoToast.error('Please select gender')
        }else{
            membershipRef.current.style.display = "none"
                        loaderRef.current.style.display = 'block'
            try {
               let member = await postRequest('become-member',{
                    'firstName':firstName,
                    'middleName':middleName,
                    'lastName':lastName,
                    'phoneNumber':phoneNumber,
                    'emailAddress':emailAddress,
                    'password':password,
                    'dateOfBirth':DOB,
                    'nationality':Nationality,
                    'membershipPlan':membershipPlan,
                    'title':title
                })
                if(member.data.status == true){
                    membershipRef.current.style.display = "block"
                    loaderRef.current.style.display = 'none'
                    setPaymentConfig(paymentConfig =>({
                        ...paymentConfig,
                        ...{'email': member.data.data.emailAddress,
                        'amount': member.data.paymentDetails.amount,'reference':member.data.paymentDetails.paymentReference   
                        }
                    }))
                    setMembershipInPorgress(member.data.data)
                }else{
                    membershipRef.current.style.display = "block"
                    loaderRef.current.style.display = 'none'
                        cogoToast.error(member.data.message)
                }
            } catch (error) {
                membershipRef.current.style.display = "block"
                loaderRef.current.style.display = 'none'
                cogoToast.error(error.response.data.message)
            }
            
        }
    }
    const initializePayment = usePaystackPayment(paymentConfig);

    const onClose = () => {
       console.log("closed");
    }

    const onSuccess = (reference) =>{
        membershipRef.current.style.display = "none"
        loaderRef.current.style.display = 'block'
        postRequest('finalize-membership-payment',{reference:reference,"membershipDetails":membershipInProgress}).then((response)=>{
            if(response.data.status === true){
                membershipRef.current.style.display = "block"
                        loaderRef.current.style.display = 'none'
                MySwal.fire({
                    icon: 'success',
                    title: response.data.message,
                    text: 'Your membership with Yolo has been confirmed, please check your email for further details.',
                  }).then((response)=>{
                      if(response.isConfirmed){
                        window.location.reload()
                      }
                  })
            }else{
                membershipRef.current.style.display = "block"
                loaderRef.current.style.display = 'none'
                cogoToast.error(response.data.message)
            }
        }).catch(()=>{
            membershipRef.current.style.display = "block"
                        loaderRef.current.style.display = 'none'
                        cogoToast.error('An error has occured')
        })
    }

    const login = async (e) =>{
        e.preventDefault()
        let emailAddress = e.target.emailAddress.value
        let password = e.target.password.value
        if(!emailAddress.trim()){
            cogoToast.error("Email address is required.")
        }else if(!password.trim()){
            cogoToast.error("Password is required.")
        }else{
            try {
                loginButtonRef.current.style.display = "none"
                        loginRefLoader.current.style.display = 'block'
              let response = await postRequest('membership-login',{
                    'emailAddress':emailAddress,
                    'password':password
              })   
              if(response.data.status === true){
                loginButtonRef.current.style.display = "block"
                loginRefLoader.current.style.display = 'none'
                    cogoToast.success(response.data.message)
                    setTimeout(()=>{
                       window.location.href = '/membership-dashboard'
                    },1000)
                }else{
                    loginButtonRef.current.style.display = "block"
                loginRefLoader.current.style.display = 'none'
                    cogoToast.error(response.data.message)
                }
            } catch (error) {
                loginButtonRef.current.style.display = "block"
                loginRefLoader.current.style.display = 'none'
                cogoToast.error(error.response.data.message)
            }
        }   
    }
    // let Naira = new Intl.NumberFormat('en-NG', {
    //     style: 'currency',
    //     currency: 'NGN',
    // });
    let Dollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return(
        <React.Fragment>
        <div className="container-fluid">
        <div className="row">
        <div className="col-md-12">
        <NavBar/>
        
      </div>
        </div>
        <div className='booking-div'>
            <div className='row'>
                    <div className='col-md-12'>
                    <div className='row'>
            <div className='col-md-12'>
            <br/>
                    <div className='membership-top' style={{'textAlign':'center'}}>
                    <h4>Yolo Membership Plans</h4>
                    <hr/>
                    <p>
                    YOLO Island grants you effortless access to nature's wonders and cultural treasures, making it the perfect getaway for your explorations. Becoming part of our exclusive YOLO Island community is within your grasp through our membership packages. Revel in unique privileges, exclusive discounts, and access to members-only events and areas.
                    </p>
                    <p style={{'fontWeight':'bold','textAlign':'center','color':'red'}}><i className='bi bi-info-circle-fill'></i> Please note that all membership plans are valid for 6 months only.</p>
                    </div>
                    <div style={{'display':'flex','margin':'auto','width':'300px'}}>
                        <div>
                        <button className='small-button' data-bs-toggle="modal" style={{'margin':'10px'}} data-bs-target="#signupModal"><i class="bi bi-person-add"></i> Join Yolo</button> 
                        </div>
                        <div>
                        <button className='small-button' data-bs-toggle="modal" style={{'margin':'10px'}} data-bs-target="#loginModal"><i class="bi bi-unlock-fill"></i> Login to Yolo</button>
                        </div>
                    </div>
                    <br/>
            </div>
        </div>
        <div className='row'>
                <div className='col-md-4'>
                    <div className='membership-plans d-none d-sm-none d-md-block d-lg-block d-xl-block' data-bs-toggle="modal" data-bs-target="#signupModal">
                        <h4>SOLO</h4>
                        <hr/>
                        <p><b>Registered Member</b></p>
                        <p>1</p>
                        <hr/>
                        <p><b>Access to unregistered guests</b></p>
                        <p>5</p>
                        <hr/>
                        <p><b>Discount on any spend</b></p>
                        <p>10%</p>
                        <hr/>
                        <p><b>Exclusive Access to Member Only Areas</b></p>
                        <p>Yes</p>
                        <hr/>
                        <p><b>Zero Corkage for members and guests</b></p>
                        <p>Yes</p>
                        <hr/>
                        <p><b>Duration</b></p>
                        <p>1 year</p>
                        <hr/>
                        <br/><br/>
                        <button className='small-button' style={{'width':'100%','border':'1px solid white'}} data-bs-toggle="modal" data-bs-target="#signupModal">Join</button>
                </div>
                
                </div>
                <div className='col-md-4'>
                <div className='membership-plans d-none d-sm-none d-md-block d-lg-block d-xl-block' data-bs-toggle="modal" data-bs-target="#signupModal">
                <h4>CREW</h4>
                <hr/>

                        <p><b>Registered Members</b></p>
                        <p>4</p>
                        <hr/>
                        <p><b>Access to unregistered guests</b></p>
                        <p>20</p>
                        <hr/>
                        <p><b>Discount on any spend</b></p>
                        <p>10%</p>
                        <hr/>
                        <p><b>Exclusive Access to Member Only Areas</b></p>
                        <p>Yes</p>
                        <hr/>
                        <p><b>Zero Corkage for members and guests</b></p>
                        <p>Yes</p>
                        <hr/>
                        <p><b>Duration</b></p>
                        <p>1 year</p>
                        <hr/>
                        <br/><br/>
                        <button className='small-button' style={{'width':'100%','border':'1px solid white'}} data-bs-toggle="modal" data-bs-target="#signupModal">Join</button>
                </div>
                </div>
                <div className='col-md-4'>
                <div className='membership-plans d-none d-sm-none d-md-block d-lg-block d-xl-block' data-bs-toggle="modal" data-bs-target="#signupModal">
                <h4>CORPORATE</h4>
                <hr/>

                        <p><b>Registered Members</b></p>
                        <p>10</p>
                        <hr/>
                        <p><b>Access to unregistered guests</b></p>
                        <p>50</p>
                        <hr/>
                        <p><b>Discount on any spend</b></p>
                        <p>10%</p>
                        <hr/>
                        <p><b>Exclusive Access to Member Only Areas</b></p>
                        <p>Yes</p>
                        <hr/>
                        <p><b>Zero Corkage for members and guests</b></p>
                        <p>Yes</p>
                        <hr/>
                        <p><b>Discount on entire facility</b></p>
                        <p>20%</p>
                        <hr/>
                        <p><b>Duration</b></p>
                        <p>1 year</p>
                        <hr/>
                        <button className='small-button' style={{'width':'100%','border':'1px solid white'}} data-bs-toggle="modal" data-bs-target="#signupModal">Join</button>
                </div>
                </div>
        </div>
                    </div>
            </div>

        <div className='d-block d-sm-block d-md-none d-lg-none d-xl-none'>
                                  <div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
        SOLO
      </button>
    </h2>
    <div>
        
    </div>
    <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">
      <h4>SOLO</h4>
      <hr/>
                        <p><b>Registered Member</b></p>
                        <p>1</p>
                        <hr/>
                        <p><b>Access to unregistered guests</b></p>
                        <p>5</p>
                        <hr/>
                        <p><b>Discount on any spend</b></p>
                        <p>10%</p>
                        <hr/>
                        <p><b>Exclusive Access to Member Only Areas</b></p>
                        <p>Yes</p>
                        <hr/>
                        <p><b>Zero Corkage for members and guests</b></p>
                        <p>Yes</p>
                        <hr/>
                        <p><b>Duration</b></p>
                        <p>1 year</p>
                        <hr/>
                        <br/><br/>
                        <button className='small-button' style={{'width':'100%'}} data-bs-toggle="modal" data-bs-target="#signupModal">Join</button>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
        CREW
      </button>
    </h2>
    <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">
      <h4>CREW</h4>
                <hr/>

            <p><b>Registered Members</b></p>
            <p>4</p>
            <hr/>
            <p><b>Access to unregistered guests</b></p>
            <p>20</p>
            <hr/>
            <p><b>Discount on any spend</b></p>
            <p>10%</p>
            <hr/>
            <p><b>Exclusive Access to Member Only Areas</b></p>
            <p>Yes</p>
            <hr/>
            <p><b>Zero Corkage for members and guests</b></p>
            <p>Yes</p>
            <hr/>
            <p><b>Duration</b></p>
            <p>1 year</p>
            <hr/>
            <br/><br/>
                        <button className='small-button' style={{'width':'100%'}} data-bs-toggle="modal" data-bs-target="#signupModal">Join</button>
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
        CORPORATE
      </button>
    </h2>
    <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">
      <h4>CORPORATE</h4>
                <hr/>

                        <p><b>Registered Members</b></p>
                        <p>10</p>
                        <hr/>
                        <p><b>Access to unregistered guests</b></p>
                        <p>50</p>
                        <hr/>
                        <p><b>Discount on any spend</b></p>
                        <p>10%</p>
                        <hr/>
                        <p><b>Exclusive Access to Member Only Areas</b></p>
                        <p>Yes</p>
                        <hr/>
                        <p><b>Zero Corkage for members and guests</b></p>
                        <p>Yes</p>
                        <hr/>
                        <p><b>Discount on entire facility</b></p>
                        <p>20%</p>
                        <hr/>
                        <p><b>Duration</b></p>
                        <p>1 year</p>
                        <hr/>
                        <button className='small-button' style={{'width':'100%','border':'1px solid white'}} data-bs-toggle="modal" data-bs-target="#signupModal">Join</button>
      </div>
    </div>
  </div>
</div>
                </div>
        </div>
        <br/><br/><br/>
        </div>
        {/* signup form modal open */}
            <div className="modal modal-lg fade" id="signupModal" tabindex="-1" aria-labelledby="signupModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="signupModalLabel">Become a member</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <form onSubmit={joinYolo}>
                    <div className='row'>
                        <div className='col-md-6'>
                        <input type='text' name='firstName' className='form-control' placeholder='Firstname'/><br/>
                        </div>
                        <div className='col-md-6'>
                        <input type='text' className='form-control' name='middleName' placeholder='Middlename'/><br/>
                        </div>
                        
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                        <input type='text' className='form-control' name='lastName' placeholder='Lastname'/><br/>
                        </div>
                        <div className='col-md-6'>
                        <input type='text' className='form-control' name='phoneNumber' placeholder='Phone Number'/><br/>
                        </div>
                        
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                        <input type='text' className='form-control' name='emailAddress' placeholder='Email Address'/><br/>
                        </div>
                        <div className='col-md-6'>
                        <input type='text' className='form-control' name='confirmEmailAddress' placeholder='Confirm Email Address'/><br/>
                        </div>
                        
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                        <input type='password' className='form-control' name='password' placeholder='Password'/><br/>
                        </div>
                        <div className='col-md-6'>
                        <input type='password' className='form-control' name='confirmPassword' placeholder='Confirm Password'/><br/>
                        </div>
                        
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                        <DatePicker
                                    className="form-control"
                                    dateFormat="MMMM d, yyyy"
                                    placeholderText="Select date of birth"
                                    selected={DOB}
                                    dropdownMode="select"
                                    showMonthDropdown 
                                    showYearDropdown
                                    onChange={(date) => setDOB(date)}
                                    />
                            <br/><br/>
                        </div>
                        <div className='col-md-6'>
                        <select className='form-select' name='title' >
                                    <option>Select title</option>
                                    {title !== null ? title.map((item)=>{
                                        return <option value={item.id}>{item.titel}</option>
                                    }) : ''}
                                </select>
                                <br/>
                        </div>
                        
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                        <select className='form-select' name='nationality'>
                                    <option>Select Nationality</option>
                                    {country !== null ? country.map((item)=>{
                                        return <option value={item.id}>{item.name}</option>
                                    }) : ''}
                                </select>
                                <br/>
                        </div>
                        <div className='col-md-6'>
                        <select className='form-select' name='plan'>
                                    <option>Select Membership Plan</option>
                                    {membershipPlans !== null ? membershipPlans.map((item)=>{
                                        return <option value={item.id}>{item.membershipPlan} - {Dollar.format(item.price)}</option>
                                    }) : ''}
                                </select>
                                <br/>
                        </div>
                        
                    </div>
                    <div className='row'>
                            <div className='col-md-6'>
                            <select className='form-select' name='gender'>
                                    <option>Select Gender</option>
                                    {gender !== null ? gender.map((item)=>{
                                        return <option value={item.id}>{item.gender}</option>
                                    }) : ''}
                                </select>
                                <br/>
                            </div>
                            <div className='col-md-6'>
                            
                            </div>
                    </div>
                    <div className='spinner-border' role='status' ref={loaderRef} style={{"display":"none"}}></div>
                                <button className='small-button' ref={membershipRef}>Join</button>
                            </form>
                </div>
                </div>
            </div>
            </div>
        {/* signup form modal close */}

        {/* Login form modal */}
        <div className="modal fade" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="loginModalLabel"><i className="bi bi-unlock-fill"></i> Login</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <form onSubmit={login}>
                                    <input type='text' placeholder='Email Address' name='emailAddress' className='form-control'/><br/>
                                    <input type='password' placeholder='Password' className='form-control' name='password'/><br/>
                                <button className='small-button' ref={loginButtonRef}>Login</button>
                                <div className='spinner-border' role='status' ref={loginRefLoader} style={{"display":"none"}}></div>
                                
                            </form>
                </div>
                </div>
            </div>
            </div>
        {/* Login form modal ends here */}
        <Footer/>
        </React.Fragment>
    )
}

export default Membership