import cogoToast from "cogo-toast";
import { useEffect, useRef, useState } from "react";
import { getRequest, postRequest } from "../services/requests";
import { usePaystackPayment } from "react-paystack";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export const OptionalServices = (props) => {
  const [transport, setTransport] = useState(null);
  const [services, setServices] = useState(null);
  const [finalCustomer, setFinalCustomer] = useState([]);
  const [optionalServiceId, setOptionalServiceId] = useState([]);
  const [selectedTransportation, setSelectedTransportation] = useState(null);
  const [specificUser, setSpecificUser] = useState(null);

  const navigate = useNavigate();
  const [paymentConfig, setPaymentConfig] = useState({
    reference: "",
    email: "",
    amount: "", //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: "pk_live_044580246ef8dd0a173b39730fbaca80b133a26b",
  });
  const MySwal = withReactContent(Swal);
  const bookRef = useRef(null);
  const bookLoader = useRef(null);

  const [bookingInprogress, setBookingInProgress] = useState(null);
  useEffect(() => {
    fetchServices();
    fetchTransport();
  }, []);
  useEffect(() => {
    let arr = [];

    props.numberOfVisitors.forEach((element) => {
      arr.push({
        customerDetails: element,
      });
    });
    setFinalCustomer(arr);
  }, []);

  useEffect(() => {
    props.options(optionalServiceId);
  }, [optionalServiceId]);

  useEffect(() => {
    initializePayment(onSuccess, onClose);
  }, [paymentConfig]);

  const fetchServices = async () => {
    try {
      let fetchedServices = await getRequest("fetch-services");
      if (fetchedServices.data.status === true) {
        setServices(fetchedServices.data.services);
      }
    } catch (error) {
      cogoToast.error(error.response.data.message, {
        position: "bottom-right",
      });
    }
  };

  const fetchTransport = async () => {
    try {
      let fetchedTransport = await getRequest("fetch-transportation");
      if (fetchedTransport.data.status === true) {
        setTransport(fetchedTransport.data.transport);
      }
    } catch (error) {
      cogoToast.error(error.response.data.message, {
        position: "bottom-right",
      });
    }
  };

  const optionalServicesFunction = (e) => {
    let service = e.target.checked;
    if (service === true) {
      let serviceId = e.target.value;
      let price = null;
      services.forEach((item) => {
        if (item.id === parseInt(serviceId)) {
          price = item.price;
        }
      });
      setOptionalServiceId([
        ...optionalServiceId,
        {
          serviceId: serviceId,
          userId: specificUser,
          price: price,
        },
      ]);
    } else {
      let serviceId = e.target.value;
      setOptionalServiceId(
        optionalServiceId.filter((item) => {
          return (
            item.userId !== parseInt(specificUser) &&
            serviceId !== item.serviceId
          );
        })
      );
    }
  }

  const transportationServiceFunction = (e) => {
    let selected = e.target.value;
    setSelectedTransportation(selected);
    transport.forEach((item) => {
      if (item.id == selected) {
        props.summary.selectedTransport({
          id: selected,
          name: item.name,
          price: item.price,
        });
      }
    });
  };

  const specificUserValue = (e) => {
    let specificUser = e.target.id;
    setSpecificUser(specificUser);
  };

  const calculatePrice = (
    roomPriceCal,
    roomPriceCal2,
    services,
    transportation,
    numberOfDays,
    startDate,
    endDate
  ) => {
    let calculatedPrice = 0

    while (startDate < endDate) {
      if(props.period && props.period == 1){
        calculatedPrice = parseInt(roomPriceCal) + parseInt(calculatedPrice)
      }else{
        calculatedPrice = parseInt(roomPriceCal2) + parseInt(calculatedPrice)
      }
      startDate.add(1, "days");
    }
    // if(calculatePrice === 0 && (startDate.day() === 6 || startDate.day() === 0) && (endDate.day() === startDate.add(1, "days"))){
    //   if (startDate.day() === 6 || startDate.day() === 0) {
    //     calculatedPrice = parseInt(roomPriceCal2) + parseInt(calculatedPrice) 
    //   }else{
    //     calculatedPrice = parseInt(roomPriceCal) + parseInt(calculatedPrice) 
    //   }
    // }
    // let roomPrice = roomPriceCal * numberOfDays;
    let roomPrice = calculatedPrice
    let optionServicesPrice = 0;
    services.forEach((element) => {
      optionServicesPrice =
        parseFloat(optionServicesPrice) + parseFloat(element.price);
    });
    let transportationPrice = 0;
    transport.forEach((item) => {
      if (item.id == transportation) {
        transportationPrice = parseFloat(item.price);
      }
    });
    let finalPrice =
      optionServicesPrice +
      parseFloat(roomPrice) +
      parseFloat(transportationPrice);
    return Naira.format(finalPrice);
  };

  const makeReservation = async () => {
    let finalInfo = {
      customer: finalCustomer,
      period: props.period,
      room: props.room,
      dates: props.date,
      optionalServices: optionalServiceId,
      transportation: selectedTransportation,
    };
    
    let date1 = moment(finalInfo.dates.checkinDate);
    let date2 = moment(finalInfo.dates.checkoutDate);
    let numberOfDays = date2.diff(date1, "days") + 1;

    let finalPrice = calculatePrice(
      props.summary.price.price1,
      props.summary.price.price2,
      finalInfo.optionalServices,
      finalInfo.transportation,
      numberOfDays,
      date1,
      date2
    );
    MySwal.fire({
      title: <p></p>,
      html: `<span><b>Room Name:</b>${
        props.summary.category
      }</span><br/><br/><span><b>Room Occupants:</b>${
        props.summary.occupants
      }</span><br/><br/><span><b>Checkin Date: </b>${
        props.summary.startDate + " | " + props.summary.startDate2
      }</span><br/><br/><span><b>Checkout Date: </b>${
        props.summary.endDate
      }</span><br/><br/><span><b>Optional Services: </b>${
        props.summary.options === null ? 0 : props.summary.options.length
      }</span><br/><br/><b>Total: </b>${finalPrice}<br/><br/>
      <span style='font-weight:bold;color:red;'>NOTE: The price of your reservation is calculated based on the price of the room on both week days and weekends.</span>`,
      showCancelButton: true,
      confirmButtonColor: "#1d4028",
      cancelButtonColor: "#d33",
      confirmButtonText: "Pay!",
      didOpen: () => {},
    }).then(async (result) => {
      if (result.isConfirmed) {
        bookLoader.current.style.display = "block";
        bookRef.current.style.display = "none";
        try {
          let makeReservation = await postRequest(
            "make-reservation",
            finalInfo
          );
          if (makeReservation.status === false) {
            cogoToast.error(makeReservation.message);
            bookRef.current.innerHTML = "Make Reservation";
            bookRef.current.disabled = false;
          } else {
            bookLoader.current.style.display = "none";
            bookRef.current.style.display = "block";
            if (makeReservation.data.status === true) {
              setPaymentConfig((paymentConfig) => ({
                ...paymentConfig,
                ...{
                  email:
                    makeReservation.data.data[0].customer.email !== undefined
                      ? makeReservation.data.data[0].customer.email
                      : "",
                  amount: makeReservation.data.finalPrice,
                  reference: makeReservation.data.payment.paymentReference,
                },
              }));
              setBookingInProgress(makeReservation.data.data);
            } else {
              bookLoader.current.style.display = "none";
              bookRef.current.style.display = "block";
              cogoToast.error(makeReservation.data.message);
            }
          }
        } catch (error) {
          bookLoader.current.style.display = "none";
          bookRef.current.style.display = "block";
          if (error.response.data.status === false) {
            cogoToast.error(error.response.data.message);
          }
        }
      }
    });
  };

  const onClose = () => {
    console.log("closed");
  };

  const onSuccess = (reference) => {
    bookLoader.current.style.display = "block";
    bookRef.current.style.display = "none";
    postRequest("finalize-reservation", {
      reference: reference,
      bookingDetails: bookingInprogress,
    })
      .then((response) => {
        bookLoader.current.style.display = "none";
        bookRef.current.style.display = "block";
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Reservation Complete",
            text: "Your reservation has been made successfully, please check your email address for more details.",
          }).then((response) => {
            if (response.isConfirmed) {
              navigate("/");
            }
          });

          // cogoToast.success(response.data.message,{
          //         position:"bottom-right",
          //         hideAfter:15,
          // })
        } else {
          bookLoader.current.style.display = "none";
          bookRef.current.style.display = "block";
          cogoToast.error(response.data.message, {
            position: "bottom-right",
          });
        }
      })
      .catch((error) => {
        bookLoader.current.style.display = "none";
        bookRef.current.style.display = "block";
        cogoToast.success(error.response.data.message, {
          position: "bottom-right",
        });
      });
  };

  const initializePayment = usePaystackPayment(paymentConfig);

  let Naira = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  });

  return (
    <div>
      <small>
        <b>Select other optional services you might be interested in.</b>
      </small>
      <br />
      <small>
        <b>Transportation</b>
      </small>
      <select
        onChange={transportationServiceFunction}
        className="form-select"
        aria-label="period selection"
      >
        <option>Select Transportation</option>
        {transport === null
          ? ""
          : transport.map((item, index) => {
              return (
                <option value={item.id} key={index}>
                  {item.name} - {Naira.format(item.price)}
                </option>
              );
            })}
      </select>
      <br />
      <div className="accordion" id="accordion1">
        {props.numberOfVisitors.map((item) => {
          return (
            <div>
              <small>
                <b>Select other optional services for:</b>
              </small>
              <div
                className="accordion-item"
                key={props.numberOfVisitors.indexOf(item)}
                style={{ marginBottom: "40px" }}
              >
                <h2
                  className="accordion-header"
                  id={"headingOne" + props.numberOfVisitors.indexOf(item)}
                >
                  <button
                    className="accordion-button"
                    onClick={specificUserValue}
                    id={item.id}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={
                      "#collapse" + props.numberOfVisitors.indexOf(item)
                    }
                    aria-controls="flush-collapse"
                  >
                    Select Services - {item.firstName + " " + item.lastName}
                  </button>
                </h2>
                <div
                  id={"collapse" + props.numberOfVisitors.indexOf(item)}
                  className="accordion-collapse collapse"
                  aria-labelledby={
                    "headingOne" + props.numberOfVisitors.indexOf(item)
                  }
                  data-bs-parent="#accordion1"
                >
                  <div className="accordion-body">
                    <br />

                    {services === null
                      ? ""
                      : services.map((item) => {
                          return (
                            <div
                              className="form-check form-check-inline"
                              key={item.id}
                              onChange={optionalServicesFunction}
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={item.id}
                                onChange={optionalServicesFunction}
                              />
                              <label className="form-check-label">
                                {item.name} - {Naira.format(item.price)}
                              </label>
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div
        className="spinner-border"
        role="status"
        ref={bookLoader}
        style={{ display: "none" }}
      ></div>
      <button className="book-button" onClick={makeReservation} ref={bookRef}>
        Make Reservation
      </button>
    </div>
  );
};
