import React, { useState } from "react"
import { postRequest,getRequest,imageUrl } from "../../services/requests"
import cogoToast from 'cogo-toast'
import { useEffect } from "react"
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom"

const Chalets = () =>{
    const [chalet,setChaletValues] = useState({
        'chaletName':'',
        'weekDayPrice':'',
        'weekendPrice':'',
        'weekDayTimePrice':'',
        'weekEndDayPrice':''
    })
    const navigate = useNavigate()
    const [image,setImage] = useState('')
    const [allChalets, setAllChallets] = useState(null)
    const [show, setShow] = useState(false);
    const [editId,setEditId] = useState(0)
    const [addChaletState, setAddChaletState] = useState(false)

    useEffect(()=>{
        getAuth()
        fetchAllRoomsOrChalets()
    },[])

    const getAuth = async () =>{
        try {
          let authUser = await getRequest("get-authenticated-user") 
            if(authUser.data.status === false){
                navigate('/admin/index/')
            }
        } catch (error) {
            navigate('/admin/index/')
        }
    }

    const handleClose = (e) => {
        setShow(false)
    };

    const handleShow = (e) => {
        setEditId(e.target.parentElement.id)
        setShow(true);
    }

    const handleCloseAddChalet = (e) => {
        setAddChaletState(false)
    };
    
    const handleShowAddChalet= (e) => {
        setAddChaletState(true);
    }
    
    const fetchAllRoomsOrChalets = () =>{
        getRequest('fetch-chalets-admin').then((response)=>{
            if(response.data.status === true){
                setAllChallets(response.data.rooms)
            }else{
                cogoToast.error(response.data.message,{
                    position:'bottom-right'
                             })
            }
        }).catch((error)=>{
            cogoToast.error(error.response.data.message,{
                position:'bottom-right'
                         });
            })
    }

    const handleChange = (e) =>{
        const {name,value} = e.target
        setChaletValues({...chalet,[name]:value})
    }

    const setPicture = (e) =>{
        setImage(e.target.files[0])
    }

    const addChalet = (e) =>{
        e.preventDefault();
        const formData = new FormData()
        if(chalet.chaletName === '' || chalet.chaletName === null){
            cogoToast.error("Chalet name is missing",{
                position:'bottom-right'
                         }); 
        }else if(chalet.chaletPrice === '' || chalet.chaletPrice === null){
            cogoToast.error("Challet price is missing",{
                position:'bottom-right'
                         }); 
        }else if(image === '' || image === null){
            cogoToast.error("Chalet image is missing",{
                position:'bottom-right'
                         }); 
        }else{

            formData.append("chaletName",chalet.chaletName)
            formData.append("weekDayPrice",chalet.weekDayPrice)
            formData.append("weekendPrice",chalet.weekendPrice)
            formData.append("weekDayTimePrice",chalet.weekDayTimePrice)
            formData.append("weekEndDayPrice",chalet.weekEndDayPrice)
            formData.append("image",image)

            postRequest('add-chalet',formData).then((response)=>{
                if(response.data.status === false){
                    cogoToast.error(response.data.message,{
                        position:'bottom-right'
                                 }); 
                }else{
                    cogoToast.success(response.data.message,{
                        position:'bottom-right'
                                 }); 
                }
            }).catch((error)=>{
                if(error.response.data.status === false){
                    cogoToast.error(error.response.data.message,{
                        position:'bottom-right'
                   }); 
                }
            })
        }
    }

    const editChaletName = (e) =>{
        e.preventDefault()
        if(chalet.chaletName.trim() === null || chalet.chaletName.trim() === ''){
            cogoToast.error("Input a challet name.",{
                position:'bottom-right'
            })
        }else{
            postRequest("update-chalet-name",{"chaletName":chalet.chaletName,"id":editId}).then((response)=>{
                if(response.data.status === true){
                    cogoToast.success(response.data.message,{
                        position:'bottom-right'
                    })
                }else{
                    cogoToast.error(response.data.message,{
                        position:'bottom-right'
                    })
                }
            }).catch((error)=>{
                cogoToast.error(error.response.data.message,{
                    position:'bottom-right'
                })
            })
        }
    }

    const editChaletPrice = (e) =>{
        e.preventDefault()
        if(chalet.chaletPrice.trim() === null || chalet.chaletPrice.trim() === ''){
            cogoToast.error("Input a price.",{
                position:'bottom-right'
            })
        }else{
            postRequest("update-chalet-price",{"chaletPrice":chalet.chaletPrice,"id":editId}).then((response)=>{
                if(response.data.status === true){
                    cogoToast.success(response.data.message,{
                        position:'bottom-right'
                    })
                }else{
                    cogoToast.error(response.data.message,{
                        position:'bottom-right'
                    })
                }
            }).catch((error)=>{
                cogoToast.error(error.response.data.message,{
                    position:'bottom-right'
                })
            })
        }
    }
    let Naira = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
      });
    return(
        <React.Fragment>
            <div className="admin-views-container">
                    <br/>
                    <i className="bi bi-plus-circle-fill add-challet-icon" onClick={handleShowAddChalet}>Add Chalet</i>
                    <hr/>
                        <div className="row" >
                            <div className="col-md-12">
                                <div className="row">
                                {allChalets === null ? '': allChalets.map((item,index)=>{
                                return(
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                        <div className='chalet-cards'>
                                        <div className="card">
                                      <div style={{"backgroundImage":`url(${imageUrl+item.imagePath})`,"width":"100%","backgroundSize":"cover","minHeight":"150px","backgroundPosition":"center"}}>

                                            </div>
                                    {/* <img src={url+item.imagePath} className="card-img-top" alt="..."/> */}
                                    <div className="card-body">
                                        <div><small>Name: {item.chaletName}</small></div>
                                        <div><small>Weekday(full): {Naira.format(item.dayTime)}</small></div>
                                        <div><small>Weekend day (full): {Naira.format(item.overNight)}</small></div>
                                
                                        <br/>
                                        <div style={{"display":"flex","justifyContent":"space-between"}}>
                                        <div id={item.id}><i className="bi bi-pencil-square chalet-icons" onClick={handleShow}></i></div>
                                        <div><i className="bi bi-trash3 chalet-icons" ></i></div>
                                        </div>
                                    </div>
                                    </div>
                                        </div>
                                    </div>
                                )
                        }) }
                                </div>
                            </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
            Edit Chalet
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={editChaletName}>
                <input type='text' placeholder="Chalet Name" name="chaletName" onChange={handleChange} className="form-control form-control-admin"/><br/>
                <button className="small-button">Update</button><br/>
                </form>
                <form onSubmit={editChaletPrice}>
                <input type='text' placeholder="Chalet Price" name="chaletPrice" onChange={handleChange} className="form-control form-control-admin"/><br/>
                <button className="small-button">Update</button><br/>
                </form>
                <form>
                <input type='file' className="form-control form-control-admin"/><br/>
                <button className="small-button">Update</button>
                </form>
        </Modal.Body>
      </Modal>

      <Modal show={addChaletState} onHide={handleCloseAddChalet} animation={false}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <form onSubmit={addChalet}>
                        <input type='text' className="form-control form-control-admin" name="chaletName" onChange={handleChange} placeholder="CHALET NAME"/><br/>
                        <input type='text' className="form-control form-control-admin" name="weekDayPrice" placeholder="WEEKDAY-FULLDAY-PRICE" onChange={handleChange}/><br/>
                        <input type='text' className="form-control form-control-admin" name="weekendPrice" placeholder="WEEKEND-FULLDAY-PRICE" onChange={handleChange}/><br/>
                        <input type='text' className="form-control form-control-admin" name="weekDayTimePrice" placeholder="WEEKDAY-DAYTIME-PRICE" onChange={handleChange}/><br/>
                        <input type='text' className="form-control form-control-admin" name="weekEndDayPrice" placeholder="WEEKEND-DAYTIME-PRICE" onChange={handleChange}/><br/>
                        <input type='file' className="form-control form-control-admin" onChange={setPicture} />
                        <br/>
                        <button className="small-button">Add Chalet</button>
                    </form>
        </Modal.Body>
      </Modal>
        </React.Fragment>
    )
}

export default Chalets