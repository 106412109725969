import React, { useEffect } from "react";
import NavBar from "../components/NavBar";
import DatePicker from "react-datepicker";
import { useState } from "react";
import cogoToast from "cogo-toast";
import { getRequest, postRequest } from "../services/requests";
import { usePaystackPayment } from "react-paystack";
import moment from 'moment/moment';
import { useRef } from "react";
import Footer from "../components/footer";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from "react-router-dom";

export const Eventspage = () =>{
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [space,setSpace] = useState(null)
    const [proceed,setProceed] = useState(false)
    const [fetchedSpaces,setFetchedSpaces] = useState(null)
    const [eventDetails,setEventDetails] = useState(null)
    const [eventAttendees,setEventAttendees] = useState(null)
    const [reservationDetails,setReservationDetails] = useState({
        "emailAddress":'',
        "phoneNumber":'',
        "eventName":'',
        "compulsoryFee":"",
    })
    const MySwal = withReactContent(Swal)
    const bookRef = useRef(null)
    const bookLoader = useRef(null)
    const bookLoader2 = useRef(null)
    const detailsLoader = useRef(null)
    const detailsRef = useRef(null)
    const proceedRef = useRef(null)
    const rsvpLoader = useRef(null)
    const rsvButton = useRef(null)
    const eventFee = useRef(null)
    const compulsoryFeeRef = useRef(null)
    const optionalFeeRef = useRef(null)
    const corkageFee = useRef(null)
    const [spaceDetails,setSpaceDetails] = useState(null)
    const [reservationInProgress,setReservationInProgress] = useState(null)
    const availabilityButton = useRef(0)
    const navigate = useNavigate();

    const [enablePayment,setEnablePayment] = useState()
    useEffect(()=>{
        window.scrollTo(0, 0)
      },[])
    const [paymentConfig,setPaymentConfig] = useState({
        reference: '',
        email: '', 
        amount: '', //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        publicKey: "pk_live_044580246ef8dd0a173b39730fbaca80b133a26b",
      })

    useEffect(()=>{
        fetchEventSpaces()
    },[])

    useEffect(()=>{
        if(proceed === true){
            proceedRef.current.style.display = 'none'
        }else{  
            proceedRef.current.style.display = 'block'
        }
    },[proceed])

    // useEffect(()=>{
    //     if(eventFee.current){
    //         if(reservationDetails.compulsoryFee === "2"){
    //             eventFee.current.style.display = "block"
    //             eventFee.current.value = ""
    //             console.log(reservationDetails)
    //            }else if(reservationDetails.compulsoryFee !== "2"){
    //             eventFee.current.style.display = "none"
    //             eventFee.current.value = "2"
    //             console.log(reservationDetails)
    //            }
    //     }   
    // },[reservationDetails,eventFee.current])

    useEffect(()=>{
        initializePayment(onSuccess,onClose)
    },[paymentConfig])

    const feesCheck = () =>{
        if(compulsoryFeeRef.current.checked == true){
            eventFee.current.style.display = "block"
        }else{
            eventFee.current.style.display = "none"
            eventFee.current.value = ""
        }
    }
    const fetchEventSpaces = async() =>{
        try {
            let eventSpacesFetched = await getRequest('fetch-event-spaces')
            if(eventSpacesFetched.data.status === true){
                setFetchedSpaces(eventSpacesFetched.data.eventSpaces)
            }
        } catch (error) {
            
        }
       
    }
    const selectSpace = (e) =>{
        let selected = e.target.value
        setSpace(selected)
        setProceed(false)
        availabilityButton.current.disabled = false
        fetchedSpaces.forEach(element => {
            if(element.id == selected){
                setSpaceDetails(element)
            }
        });
    }
    const submitCheckForm = async (e) =>{
        e.preventDefault()
       if(startDate === null || startDate === ''){
        cogoToast.error("Select A date for your event");
       }else if(space === null || space === ''){
        cogoToast.error("Select one of our event spaces");
       }else{
        let data = {
            "space":space,
            "date":startDate
        }
        bookLoader.current.style.display = "block"
        availabilityButton.current.style.display = 'none'
        try {
           let check = await postRequest('check-event-space-availability',data)
           if(check.data.status === true){
                setProceed(true)
                bookLoader.current.style.display = "none"
                availabilityButton.current.style.display = 'block'
                cogoToast.success(check.data.message,{
                    "hideAfter":10
                })
           }else{
            bookLoader.current.style.display = "none"
                availabilityButton.current.style.display = 'block'
                setProceed(false)
                cogoToast.error(check.data.message)
           }
        } catch (error) {
            bookLoader.current.style.display = "none"
                availabilityButton.current.style.display = 'block'
            cogoToast.error(error.response.data.message,{
                hideAfter:15
        })
        }
       
       }
    }
    const handleReservationChange = (e) =>{
        setReservationDetails({...reservationDetails,[e.target.name]:e.target.value})
        
    }
    const makeReservation = async (e) =>{
        e.preventDefault()
        if(reservationDetails.emailAddress === null || reservationDetails.emailAddress === ''){
            cogoToast.error("Please input an email address");
        }else if(reservationDetails.phoneNumber === null || reservationDetails.phoneNumber === ''){
            cogoToast.error("Please inout a phone number");
        }else if(reservationDetails.eventName === null || reservationDetails.eventName === ''){
            cogoToast.error("Please input the name of your event");
        }else if(space === null || space === ''){
            cogoToast.error("Please selct one of our event spaces");
        }else if(startDate === null || startDate === ''){
            cogoToast.error("Please select a date for your event");
        }else{
            let corkageChoice = null
            let optionalFee = null
            let compulsoryFee = null
            if(corkageFee.current.checked === true){
                corkageChoice = 1
            }

            if(optionalFeeRef.current.checked === true){
                optionalFee = 2
            }

            if(compulsoryFeeRef.current.checked === true){
                compulsoryFee = reservationDetails.compulsoryFee
            }else{
                compulsoryFee = null
            }

            if(optionalFeeRef.current.checked === true && compulsoryFeeRef.current.checked === true){
                cogoToast.error("You cannot set a compulsory and optional fee for one event.")
            }else{
                let data = {
                    "emailAddress":reservationDetails.emailAddress,
                    "phoneNumber":reservationDetails.phoneNumber,
                    "eventName":reservationDetails.eventName,
                    "eventDate":startDate,
                    "eventSpace":space,
                    "compulsoryFee":compulsoryFee,
                    "corkage":corkageChoice,
                    "optionalFee":optionalFee,
                }

                fetchedSpaces.forEach(element => {
                    if(element.id == data.eventSpace){
                        data.eventSpaceName = element.space
                        data.price = element.cost
                    }
                });
                MySwal.fire({
                    title: <p></p>,
                    html:`<span><b>Event Space:</b>${data.eventSpaceName}</span><br/><br/><span><b>Event Name:</b>${data.eventName}</span><br/><br/><span><b>Event Date: </b>${data.eventDate}</span><br/><br/><span><b>Email Address: </b>${data.emailAddress}</span><br/><br/><span><b>phone Number: </b>${data.phoneNumber}</span><br/><br/><b>Total: </b>${Naira.format(data.price)}`,
                    showCancelButton: true,
                    confirmButtonColor: '#1d4028',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Pay!',
                    didOpen: () => {
                    
                    
                    },
                  }).then(async (response)=>{
                    if(response.isConfirmed){
                            try {
                    bookLoader2.current.style.display = "block"
                     bookRef.current.style.display = 'none'
                    let reservation = await postRequest("make-event-reservation",data)   
                    if(reservation.data.status === true){
                        bookLoader2.current.style.display = "none"
                        bookRef.current.style.display = 'block'
                        setPaymentConfig(paymentConfig =>({
                            ...paymentConfig,
                            ...{'email': reservation.data.data.emailAddress,
                            'amount': reservation.data.finalPrice,'reference':reservation.data.paymentReference   
                            }
                        }))
                    setReservationInProgress(reservation.data.data)
                    }else{
                        bookLoader2.current.style.display = "none"
                        bookRef.current.style.display = 'block'
                        cogoToast.error(response.data.message,{
                            hideAfter:10
                        })
                    }
                } catch (error) {
                    bookLoader2.current.style.display = "none"
                    bookRef.current.style.display = 'block'
                    cogoToast.error(error.response.data.message,{
                        hideAfter:10
                    })
                }
                    }
                  }) 
            }
              
         }
    }
    const initializePayment = usePaystackPayment(paymentConfig);
    const onClose = () => {
       console.log("closed");
    }
    const onSuccess = (reference) =>{
        bookLoader2.current.style.display = "block"
        bookRef.current.style.display = 'none'
        postRequest('finalize-event-reservation',{reference:reference,"bookingDetails":reservationInProgress}).then((response)=>{
            if(response.data.status === true){
                bookLoader2.current.style.display = "none"
                bookRef.current.style.display = 'block'
                    // cogoToast.success(response.data.message,{
                    //         hideAfter:15
                    // })
                    Swal.fire({
                        icon: 'success',
                        title: 'Reservation Complete',
                        text: 'Your reservation has been made successfully, please check your email address for more details.',
                      }).then((response)=>{
                          if(response.isConfirmed){
                            navigate("/")
                          }
                      })
            }else{
                bookLoader2.current.style.display = "none"
                bookRef.current.style.display = 'block'
                    cogoToast.error(response.data.message,{
                        hideAfter:15
                    })
            }
    }).catch((error)=>{
        bookLoader2.current.style.display = "none"
        bookRef.current.style.display = 'block'
            cogoToast.success(error.response.data.message,{
                    position:"bottom-right"
            })
    })
    }
    const fetchEventDetails = async(e) =>{
        e.preventDefault()
        let eventId = e.target.eventId.value
        if(eventId !== null && eventId !== ''){
            detailsLoader.current.style.display = "block"
            detailsRef.current.style.display = 'none'
            try {
                let details = await postRequest('fetch-event-details',{
                    eventId:eventId
                })
                detailsLoader.current.style.display = "none"
            detailsRef.current.style.display = 'block'
                if(details.data.status === true){
                    setEventDetails(details.data.eventData)
                    setEventAttendees(details.data.attendeeData)
                }else{
                    cogoToast.error(details.data.message)
                }
            } catch (error) {
                detailsLoader.current.style.display = "none"
                detailsRef.current.style.display = 'block'
                cogoToast.error(error.response.data.message)
            }
        }else{
            cogoToast.error('Please input an event ID')
        }
    }
    let Naira = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
      });

    const resetBooking = () =>{
        setProceed(false)
    }
    const submitRSVP = async (e) =>{
        e.preventDefault()
        let eventName = e.target.eventName.value
        let startDate1 = startDate
        let endDate1 = endDate
        let emailAddress = e.target.emailAddress.value
        let confirmEmailAddress = e.target.confirmEmailAddress.value
        let phoneNumber = e.target.phoneNumber.value

        if(!eventName){
            cogoToast.error('Event name is required')
        }else if(!startDate1){
            cogoToast.error('Select event start date')
        }else if(!endDate1){
            cogoToast.error('Select event end date')
        }else if(!emailAddress.trim()){
            cogoToast.error('Email address is required')
        }else if(!confirmEmailAddress.trim()){
            cogoToast.error('Confirm Email Address')
        }else if(emailAddress.trim() !== confirmEmailAddress.trim()){
            cogoToast.error('Email Addresses do not match')
        }else if(!phoneNumber.trim()){
            cogoToast.error('Phone number is required')
        }else{
            let finalData = {
                'eventName':eventName,
                'startDate':startDate1,
                'endDate':endDate1,
                'emailAddress':emailAddress,
                'emailConfirmation':confirmEmailAddress,
                'phoneNumber':phoneNumber
            }

            MySwal.fire({
                title: <p>Proceed to create Event?</p>,
                html:`<span><b>Event Name:</b>${finalData.eventName}</span><br/><br/><span><b>Event Start Date:</b>${moment(finalData.startDate).format("DD/MM/YYYY")}</span><br/><br/><span><b>Event End Date: </b>${moment(finalData.endDate).format("DD/MM/YYYY")}</span><br/><br/><span><b>Email Address: </b>${finalData.emailAddress}</span><br/><br/><span><b>phone Number: </b>${finalData.phoneNumber}</span><br/>`,
                showCancelButton: true,
                confirmButtonColor: '#1d4028',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Create!',
                didOpen: () => {
                    
                
                },
              }).then(async (response)=>{
                if(response.isConfirmed){
                try {
                    rsvButton.current.style.display = "none"
                    rsvpLoader.current.style.display = "block"
                let createdEvent = await postRequest('create-rsvp-event',finalData)
                if(createdEvent.data.status){
                    rsvButton.current.style.display = "block"
                    rsvpLoader.current.style.display = "none"
                    Swal.fire({
                        icon: 'success',
                        title: 'Event Created',
                        text: 'Your reservation has been made successfully, please check your email for more details.',
                      }).then((response)=>{
                          if(response.isConfirmed){
                            navigate("/")
                          }
                      })
                }else{
                    rsvButton.current.style.display = "block"
                    rsvpLoader.current.style.display = "none"
                    cogoToast.error({'stats':false,'message':createdEvent.data.message})
                }
                } catch (error) {
                    rsvButton.current.style.display = "block"
                    rsvpLoader.current.style.display = "none"
                    cogoToast.error(error.response.data.message)
                }
                }
              }) 
            
        }       
    }
    return(
         <React.Fragment>
        <div className='container-fluid'>
            <div className='row'>
            <div className="col-md-12">
                <NavBar/>
            </div>
            </div>
            <div className="row g-0">
                <div className='col-md-1'>

                </div>
                <div className='col-md-10'>
                    <div className='booking-div'>

                    <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Events</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link" id="home-tab" data-bs-toggle="tab" data-bs-target="#rsvp" type="button" role="tab" aria-controls="home" aria-selected="true">RSVP</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Manage</button>
            </li>
            </ul>
            <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            {/* <h4 style={{"textAlign":"left"}}>Events</h4> */}
                        <hr/>
                        <div className='row'>
                        <div className='col-md-4'>
                        <div className="event-page-space d-none d-sm-block">

                        </div>
                        </div>
                        <div className='col-md-4'>
                            <div ref={proceedRef}>
                            <h5>Reservation</h5>
                            <form onSubmit={submitCheckForm}>
                                <br/>
                            <select className="form-select"
                              aria-label="period selection" name="space" onChange={selectSpace}>
                                <option>Select Event Space</option>
                                {fetchedSpaces !== null ? fetchedSpaces.map((item)=>{
                                    return <option value={item.id} key={item.id}>{item.space} - {Naira.format(item.cost)}</option>
                                }):''}
                            </select>
                            <br/>
                            <DatePicker
                      selected={startDate}
                      className="form-control"
                      onChange={(date) => {
                        setProceed(false)
                        setStartDate(date)
                        availabilityButton.current.disabled = false
                      }}
                      dateFormat="d MMMM, yyyy"
                      minDate={Date.now()}
                      
                    />
                    <br/> <br/>
                    <div className="d-block d-sm-none d-md-none d-lg-none">
                            <h5>Reservation Summary</h5>
                                <br/>
                                <span><b>Event Space: </b></span> {spaceDetails == null ? '' : spaceDetails.space}<br/><br/>
                                <span><b>Price:</b></span> {spaceDetails == null ? '' : Naira.format(spaceDetails.cost) }<br/><br/>
                                <span><b>Max Capacity:</b> </span>{spaceDetails == null ? '' : spaceDetails.capacity}<br/><br/>
                                <span><b>Corkage:</b> </span>{spaceDetails == null ? '' :Naira.format(spaceDetails.corkage)  }<br/>
                            </div> 
                            <br/>
                    <div className='spinner-border' role='status' ref={bookLoader} style={{"display":"none"}}></div>
                    <button className="book-button" style={{"margin":0}} ref={availabilityButton}>Check availability</button>
                    <br/>
                            </form>
                            </div>
                            {proceed === true ? <div>
                                <i className="bi bi-arrow-left-square-fill" style={{"cursor":"pointer","font-size":"1.5rem"}} onClick={resetBooking}></i>
                                <h5>Reservation Details</h5> <form onSubmit={makeReservation}>
                                <input type='text' name='emailAddress' onChange={handleReservationChange} className='form-control' placeholder="Email Address"/><br/>
                                <input type='text' name='phoneNumber' onChange={handleReservationChange} className='form-control' placeholder="Phone Number"/><br/>
                                <input type='text' name='eventName' onChange={handleReservationChange} className='form-control' placeholder="Event Name"/><br/>
                                <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="optionalFee" ref={optionalFeeRef} onChange={handleReservationChange} id="flexCheckDefault"/>
                                <label className="form-check-label" for="flexCheckDefault">
                                    Enable Optional Fee <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip" data-bs-placement="top" title="This option enables you as an event organizer to enable your event guests to either pay a compulsory attendance fee or for them to make optional payments."></i>
                                </label>
                                </div>
                                <div className="form-check">
                                <input className="form-check-input" type="checkbox"  ref={compulsoryFeeRef} name="compulsoryFee" onChange={feesCheck} id="flexCheckDefault2"/>
                                <label className="form-check-label" for="flexCheckDefault2">
                                    Enable Compulsory Payment <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip" data-bs-placement="top" title="This option enables you as an event organizer to enable your event guests to either pay a compulsory attendance fee or for them to make optional payments."></i>
                                </label>
                                </div>
                                <div className="form-check">
                                <input className="form-check-input" type="checkbox" ref={corkageFee} name="corkage" onChange={handleReservationChange} id="flexCheckDefault3"/>
                                <label className="form-check-label" for="flexCheckDefault3">
                                    Enable Corkage <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip" data-bs-placement="top" title="This option enables you as an event organizer to enable your event guests to either pay a compulsory attendance fee or for them to make optional payments."></i>
                                </label>
                                </div>
                                <br/>
                                <input type='text' name='compulsoryFee' ref={eventFee} onChange={handleReservationChange} style={{'display':'none','marginBottom':'20px'}} className='form-control' placeholder="Event Fee"/>
                                <div className='spinner-border' role='status' ref={bookLoader2} style={{"display":"none"}}></div>
                                <button className="book-button" style={{"margin":"0"}} ref={bookRef}>Make Reservation</button>
                            </form>
                            </div>  : ''}
                        </div>
                        <div className='col-md-4'>
                            <div className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            <h5>Reservation Summary</h5>
                                <br/>
                                <span><b>Event Space: </b></span> {spaceDetails == null ? '' : spaceDetails.space}<br/><br/>
                                <span><b>Price:</b></span> {spaceDetails == null ? '' : Naira.format(spaceDetails.cost) }<br/><br/>
                                <span><b>Max Capacity:</b> </span>{spaceDetails == null ? '' : spaceDetails.capacity}<br/><br/>
                                <span><b>Corkage:</b> </span> Terms and conditions apply
                            </div> 
                        </div>
                    </div>
            </div>
            <div className="tab-pane fade" id="rsvp" role="tabpanel" aria-labelledby="profile-tab">
                <div className="row">
                    <div className="col-md-4">
                        <br/>
                    <div className="event-page-space2 d-none d-sm-block">

                    </div>
                    </div>
                    <div className="col-md-4">
                        <br/>
                    <form onSubmit={submitRSVP}>
                    <input type="text" name="eventName" className="form-control" placeholder="Event Name"/><br/>
                    <small>Event Start Date</small>
                    <DatePicker
                      selected={startDate}
                      name="eventStartDate"
                      className="form-control"
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={60}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy h:mm aa"
                      onChange={(date) => {
                        setStartDate(date)
                        availabilityButton.current.disabled = false
                      }}
                      minDate={Date.now()}
                      
                    /><br/><br/>
                    <small>Event End Date</small>
                           <DatePicker
                      selected={endDate}
                      className="form-control"
                      showTimeSelect
                      timeFormat="HH:mm"
      timeIntervals={60}
      timeCaption="time"
      dateFormat="MMMM d, yyyy h:mm aa"
                      name="eventEndDate"
                      onChange={(date) => {
                        setEndDate(date)
                        availabilityButton.current.disabled = false
                      }}
                      minDate={Date.now()}
                      
                    /><br/><br/>
                    <input type="text" name="emailAddress" className="form-control" placeholder="Email Address"/><br/>
                    <input type="text" name="confirmEmailAddress" className="form-control" placeholder="Confirm Email Address"/><br/>
                    <input type="text" name="phoneNumber" className="form-control" placeholder="phoneNumber"/><br/>
                    {/* <i className="bi bi-plus-square" id='add-access-levels' data-bs-toggle="modal" data-bs-target="#access-levels-backdrop"> Add access levels</i><br/> */}
                    <button className="book-button" ref={rsvButton} style={{"marginLeft":"0"}}>Proceed</button>
                    <div className='spinner-border' role='status' ref={rsvpLoader} style={{"display":"none"}}></div>
                                </form>
                    </div>
                    <div className="col-md-4">
                      <div className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                        <br/>
                        <p>
                            <h3>RSVP</h3><br/>
                            1. Input the required details<br/><br/>
                            2. Proceed to create event<br/><br/>
                            3. Check your email<br/><br/>
                            4. Share invite link to attendees <br/><br/>
                            5. Visit the manage events tab to view information about your event.
                        </p>
                      </div>
                    </div>
                </div>
                              
            </div>
            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <br/>
            <h4 style={{"textAlign":"left"}}>Manage Events</h4>
            <br/>
            <form onSubmit={fetchEventDetails}>
                <small>Fetch Event Details</small>
            <input type="text" name="eventId" className="form-control" placeholder="Enter your event unique ID"/>
            <br/>
            <div className='spinner-border' role='status' ref={detailsLoader} style={{"display":"none"}}></div>
            <button className="small-button" ref={detailsRef}>Fetch</button>                
            </form>
            <br/>
            <div className="row">
                <div className="col-md-3">
                    <div style={{"marginBottom":"40px"}}>
                    {eventDetails !== null ? <div>
                        <h4>Event Details</h4>
                        <hr/>
                    <h5>Event Name:</h5> {eventDetails.eventName}<br/><br/>
                    <h5>Event organizer:</h5> {eventDetails.emailAddress}<br/><br/>
                    <h5>Event Status:</h5> {eventDetails.eventStatus === 1 ? 'Active' : 'Inactive'}<br/><br/>
                    <h5>Event Id: </h5>{eventDetails.eventId}<br/><br/>
                    <h5>Event Date: </h5>{moment(eventDetails.eventDate).format("DD/MM/YYYY")}<br/><br/>
                    <h5>Event Hall: </h5>{eventDetails.EventSpace !== null ? eventDetails.EventSpace.space : 'Not Yet Allocated' }
             </div> : ''}
                    </div>
                </div>
                <div className="col-md-9">
                                <div>
                                    {eventAttendees !== null ? <div style={{"overflowX":"auto"}}>
                                        <h4>Event Attendees</h4>
                                        <hr/>
                                        <table className="table">
                                    <thead>
    <tr>
    <th scope="col">SN</th>  
      <th scope="col">First Name</th>
      <th scope="col">Last Name</th>
      <th scope="col">Email Address</th>
      <th scope="col">Phone Number</th>
      <th scope="col">Option Selected</th>
      <th scope="col">Status</th>
    </tr>
  </thead>
  <tbody>
    {eventAttendees !== null ? eventAttendees.map((item,key)=>{
        return <tr key={item.id}>
            <td>{key + 1}</td>
            <td>{item.firstName}</td>
            <td>{item.lastName}</td>
            <td>{item.emailAddress}</td>
            <td>{item.phoneNumber}</td>
            <td>{item.EventAccess.eventAccess}</td>
            <td>{item.status === 1 ? 'Approved' : 'Pending/Rejected'}</td>
        </tr>
    }) : ''}
  </tbody>
                                    </table>
                                        </div>  : ''}
    
                                </div>
                </div>
            </div>
            </div>
            </div>

                    </div>
                
                </div>
                <div className='col-md-1'>

                </div>
            </div>
            <br/><br/>
            <Footer />
        </div>
            <div className="modal fade" id="access-levels-backdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">Modal title</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    ...
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Understood</button>
                </div>
                </div>
            </div>
            </div>
    </React.Fragment>
    )
}