const SideNav = (props) =>{
    return (
        <div className="admin-side-nav d-none d-sm-none d-md-none d-lg-block d-xl-block">
            <br/><br/>
            <div className="admin-side-options" onClick={props.switch.home}><i className="bi bi-house"></i> Home</div>
            <div className="admin-side-options" onClick={props.switch.chalets}><i className="bi bi-houses"></i> Chalets</div>
            <div className="admin-side-options" onClick={props.switch.reservations}><i className="bi bi-journals"></i> Reservations</div>
            <div className="admin-side-options" onClick={props.switch.payments}><i className="bi bi-credit-card-fill"></i> Payments</div>
            <div className="admin-side-options" onClick={props.switch.events}><i className="bi bi-calendar-event"></i> Events</div>
            <div className="admin-side-options" onClick={props.switch.food}><i className="bi bi-cup-straw"></i> Food Menu</div>
            <div className="admin-side-options" onClick={props.switch.mealOrder}><i className="bi bi-list"></i> Meal Orders</div>
            <div className="admin-side-options" onClick={props.switch.visit}><i className="bi bi-list-stars"></i> Visit Reservations</div>
            <div className="admin-side-options"><i className="bi bi-scooter"></i> Activities</div>
            <div className="admin-side-options"><i className="bi bi-gear"></i> Settings</div>
            <div className="admin-side-options" onClick={props.logout}><i className="bi bi-power" ></i> Sign Out</div>
        </div>
    )
}

export default SideNav     