import React from "react"
import NavBar from "../components/NavBar"
import Footer from "../components/footer"
import { useEffect } from "react"
export const ContactUs = () =>{
    useEffect(()=>{
        window.scrollTo(0, 0)
      },[])
    return (
        <React.Fragment>
                  <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <NavBar/> 
        </div>
        </div>
        </div>
        <div className="container-fluid">
        <div className="row g-0">
        <div className="col-md-8">
            <div className="contact-container">
                <div className="row">
                        {/* <div className="col-md-6"> 
                        <div className="contact-360">
                        <br/>
                            <div style={{"textAlign":"center"}}>
                            <i className="bi bi-telephone-fill contact-icons" ></i>
                            </div>
                            <div className="contact-details">
                            08038279464
                        </div>
                        </div>
                        </div> */}
                        <div className="col-md-12">
                            <div className="contact-360">
                            <br/>
                            <div style={{"textAlign":"center"}}>
                            <i className="bi bi-envelope-at-fill contact-icons"></i>
                            </div>
                            <div className="contact-details">
                            tech@yolo.ng
                        </div>
                            </div>  
                        </div>
                    
                    </div>
                    <br/><br/><br/><br/><br/>  <br/>
                    <div className="row">
                    <div className="col-md-12">
                             <div className="contact-360">
                             <br/>
                            <div style={{"textAlign":"center"}}>
                            <i className="bi bi-pin-map-fill contact-icons"></i>
                            </div>
                            <div className="contact-details">
                            Ibeshe Private Island Lagos.
                        </div>
                             </div>
                        </div>
                    </div>
            </div>
            </div>
            <div className="col-md-4">
                
                <div className="contact-us-right">
                <div className="contact-us-right-inner">
                    <h4>Contact us for support</h4>
                <form>
                        <input placeholder="Firstname" className="form-control contact-us-input"/><br/>
                        <input placeholder="Lastname" className="form-control contact-us-input"/><br/>
                        <input placeholder="Email Address" className="form-control contact-us-input"/><br/>
                        <input placeholder="Subject" className="form-control contact-us-input"/><br/>
                        <textarea className="form-control contact-us-input" style={{"height":"200px"}} rows="3"></textarea>
                        <br/>
                        <button className="small-button">Submit</button>
                    </form>
                </div>
                </div>
                <br/><br/>
            </div>
        </div>
        </div>
<Footer/>
    
    </React.Fragment>
    ) 
}