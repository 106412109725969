import React, { useState, useEffect } from "react";
import { useLocation} from "react-router-dom";
import Footer from "../components/footer";
import NavBar from "../components/NavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusSquare,faMinusSquare
} from "@fortawesome/free-solid-svg-icons";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import cogoToast from "cogo-toast"
import room1 from "../images/room1.jpg";
import room2 from "../images/room2.jpg";
import room3 from "../images/room3.jpg";
import room4 from "../images/room4.jpg";
import room5 from "../images/room5.jpg";
import room6 from "../images/room6.jpg";
import GuestDetails from "../components/guestDetails";
import { postRequest } from "../services/requests";
import DatePicker from "react-datepicker";
import { useRef } from "react";


const ReservationsPage = () => {
  const location = useLocation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [period,setPeriod] = useState(null)
  const [minCheckoutDateState,setMinCheckoutDateState] = useState(null);
  const [roomCategory, setRoomCategory] = useState({
    "roomId":null,
    "category":null
  })
  const [showServices,setShowServices] = useState(false)

  const [price,setPrice] = useState({
    "price1":null,
    "price2":null
  })

  const [priceTracker,setPricetracker] = useState({
    "price1":null,
    "price2":null
  })

  const [rooms,setRooms] = useState([])

  const [occupants, setOccupants] = useState({
  'action':"i",
  'value':1
  })
 
  const availabilityRef = useRef(null)


  useEffect(() => {
    window.scrollTo(0, 0)
    if (location.state !== null) {
      setStartDate(location.state.startDate);
      setEndDate(location.state.endDate);
    }
    minCheckoutDate()
  }, []);

  useEffect(()=>{
    checkAvailability()
  },[startDate,endDate])

  

  const increase = () =>{
    let currentValue = occupants.value
    // let currentPrice = price
    // let trackedPrice = priceTracker
   // setPricetracker(parseInt(trackedPrice,10) + parseInt(currentPrice,10))
    if(currentValue < 2 && showServices === false){
        let nextValue = ++currentValue
        setOccupants({'action':'i','value':nextValue})
    }
  }

  const decrease = () =>{
    let currentValue = occupants.value
    // let currentPrice = price
    // let trackedPrice = priceTracker
    // if(trackedPrice > currentPrice){
    //   setPricetracker(parseInt(trackedPrice,10) - parseInt(currentPrice,10))
    // }
    if(currentValue > 1 && showServices === false){
        let nextValue = --currentValue
        setOccupants({'action':'d','value':nextValue})
    }
  }
  const setRoomCategoryFunction = (e) =>{
    let roomId = e.target.options[e.target.selectedIndex].text
    let price = e.target.options[e.target.selectedIndex].getAttribute('dayTimePrice')
    let price2 = e.target.options[e.target.selectedIndex].getAttribute('overNightPrice')
   
    setPrice({
      "price1":parseInt(price,10),
      "price2":price2
    })

    setPricetracker({
      "price1":parseInt(price,10),
      "price2":parseInt(price2,10)
    })

     setRoomCategory({
      "roomId":e.target.value,
      "category":roomId
     })
  }

  let Naira = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
  });

  const minCheckoutDate = () => {
    let date = new Date()
    let minCheckoutDateVal = date.setDate(date.getDate() + 1)
    setMinCheckoutDateState(minCheckoutDateVal)
    //setEndDate(minCheckoutDateVal)
  }

  const checkAvailability = async () =>{
    let date1 = startDate
    let date2 = endDate
    //setRooms(null)
    try {
      if(date1 !== null && date2 !== null){
        availabilityRef.current.innerText = "Fetching Available Chalets..........."
        availabilityRef.current.style.display = 'block'
        availabilityRef.current.style.color = 'darkblue'
        let ac = await postRequest('fetch-available-chalets-conditions',{
          'startDate':date1,
          'endDate':date2
        })
        if(ac.data.status === true){
          availabilityRef.current.innerText = "Done!"
          availabilityRef.current.style.color = 'darkblue'
          setTimeout(() => {
            availabilityRef.current.style.display = 'none'
          }, 3000);
          setRooms(ac.data.chalets)
        }else{
          setTimeout(() => {
            availabilityRef.current.style.display = 'none'
          }, 3000);
          cogoToast.error("Error finding available chalets")
        }
      }
    } catch (error) {
      setTimeout(() => {
        availabilityRef.current.style.display = 'none'
      }, 3000);
      cogoToast.error(error.response.data.message)
    }
   
  }

  const periodChange = (e) =>{
    setPeriod(e.target.checked ? 1 : 0)
}
const isWeekend = (date) => {
  const day = date.getDay();
  return day === 4 || day === 5 || day === 6 || day === 0 ; // 4 for Thursday and 5 for Friday
};
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <NavBar />
          </div>
          <div className="row g-0">
            <div className="col-md-1">

            </div>
            <div className="col-md-10">
              <div className="booking-div">
                <h4>Make Reservations</h4>
                <br />
                <OwlCarousel className="owl-theme" loop margin={10} nav responsive={{
            0: {
                items: 1,
            },
            450: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 4,
            },
        }}>
        
                  <div className="item">
                    <div className="chalet-boxes-reservations">
                      <img src={room1} alt="room" className="booking-page-images"/>
                      <h5>CANCUN</h5>
                    </div>
                  </div>
                  <div className="item">
                    <div className="chalet-boxes-reservations">
                      <img src={room2} alt="room" className="booking-page-images"/>
                      <h5>IBIZA</h5>
                    </div>
                  </div>
                  <div className="item">
                    <div className="chalet-boxes-reservations">
                      <img src={room3} alt="room" className="booking-page-images"/>
                      <h5>SANTORINI</h5>
                    </div>
                  </div>
                  <div className="item">
                    <div className="chalet-boxes-reservations">
                      <img src={room4} alt="room" className="booking-page-images"/>
                      <h5>FIJI</h5>
                    </div>
                  </div>
                  <div className="item">
                    <div className="chalet-boxes-reservations">
                      <img src={room5} alt="room" className="booking-page-images"/>
                      <h5>CANCUN</h5>
                    </div>
                  </div>
                  <div className="item">
                    <div className="chalet-boxes-reservations">
                      <img src={room6} alt="room" className="booking-page-images"/>
                      <h5>MADAGASCAR</h5>
                    </div>
                  </div>
                </OwlCarousel>
                <div className="row">
                    <div className="col-md-4">
                    <div className="booking-sub-section">
                    <div>
                      <small><b>Select Checkin Date</b></small>
                      <DatePicker
                     selected={startDate}
                      className="form-control"
                      onChange={(date) => {
                        setStartDate(date)
                        checkAvailability()
                      }}
                      filterDate={isWeekend}
                      showTimeSelect
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeFormat="HH:mm"
                      minDate={Date.now()}
                      placeholderText="Select check-in date"
                    />
                    <br/><br/>
                    <small><b>Select Checkout Date</b></small>
                     <DatePicker
                     showTimeSelect
                     timeIntervals={15}
                      selected={endDate}
                      className="form-control"
                      filterDate={isWeekend}
                      onChange={(date) => {
                        setEndDate(date)
                        checkAvailability()
                      }}
                      dateFormat="MMMM d, yyyy h:mm aa" 
                      minDate={minCheckoutDateState}
                      placeholderText="Select check-out date"
                    />
                    <br/><br/>
                    </div>
                      <div id="availability-notification" ref={availabilityRef} style={{'display':'none','fontWeight':'bold','marginBottom':'10px'}}>

                      </div>
                    {/* <small><b>Period of day & week</b></small>
                            <select  className="form-select"
                              aria-label="period selection"  onChange = {selectPeriod}  ref={selectedPeriodValue}>
                                <option>Select Day & Period</option>
                                {
                                periods === null ? '' : periods.map((item,index) => {
                                    return (
                                      <option value={item.id} key={index}>{item.time}</option>
                                    )
                                })
                                }
                            </select>
                            <br/>
                       */}
                    <small><b>Select Room Category</b></small>
                            <select className="form-select"
                              aria-label="Select room category" onChange={setRoomCategoryFunction}>
                                <option>SELECT ROOM</option>
                                {rooms.length > 0 ? rooms.map((item,index) => {
                                    return (
                                      <option value={item.id} overNightPrice={item.overNight} dayTimePrice={item.dayTime} key={index}>{item.chaletName}</option>
                                      
                                    )
                                }) :''
                                }
                            </select>
                            <br/>
                            <div className="row">
                                  <div className="col-6">
                                  <small>Number of occupants</small><br/>
                            <span className='number-counters'><FontAwesomeIcon onClick={decrease
                            } icon={faMinusSquare}/></span><span className="occupants-value">{occupants.value}</span> <span className='number-counters'><FontAwesomeIcon icon={faPlusSquare} onClick={increase} /></span>
                                  </div>
                                  <div className="col-6">
                                    
                                  </div>
                            </div> 
                            <br/>
                            <small>Specify if you're reserving this for daytime</small>
                            <hr/>
<div className="form-check">
                <input className="form-check-input" type="checkbox" onChange={periodChange} value="1" name="period" id="flexCheckChecked"/>
                <label className="form-check-label">
                    Daytime Stay?
                </label>
                </div>
                      <br/>

                </div>   
                    </div>
                    <div className="col-md-8">
                    <div className="booking-sub-section">
                      
                      <GuestDetails occupants={{'value':occupants.value,'action':occupants.action}} summary={{"category":roomCategory.category,"occupants":occupants,"startDate":startDate,"endDate":endDate,"price":priceTracker,'period':period}} room={roomCategory} dates={{'checkinDate':startDate,'checkoutDate':endDate}} showServicesState={setShowServices}/>
                      <br/>
                  </div>
                    </div>
               
          </div>
              </div>
            </div>
            <div className="col-md-1">
              
            </div>
          </div>
        </div>
        
        <br />
        <br />
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default ReservationsPage;
