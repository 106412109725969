import { useEffect, useRef, useState } from "react"
import { getRequest } from "../../services/requests"
import cogoToast from "cogo-toast"
import moment from 'moment/moment';

const VisitReservations = () =>{
    const loadButton = useRef()
    const [visits,setVisits] = useState(null)
    const [lastRecord,setLastRecord] = useState(null)

    useEffect(() =>{
        fetchVisitReservations()
    },[])
    const fetchVisitReservations = async () =>{

        try {
            let response = await getRequest('fetch-visit-reservations')
            if(response.data.status){
                setVisits(response.data.reservations)
                let responseData = response.data.reservations
                let lastElement = responseData[responseData.length -1]
                setLastRecord(lastElement.id) 
            }else{
                cogoToast.error(response.data.message)
            }
        } catch (error) {
            console.log(error)
            cogoToast.error(error.response.data.message)
        }
       
    }
    const loadMore = () =>{
        if(lastRecord > 1){
            
            loadButton.current.childNodes[0].style.display = "none"
            loadButton.current.childNodes[1].style.display = "block"

            getRequest('load-more-visits',lastRecord).then((response)=>{
                if(response.data.status === true){
                    loadButton.current.childNodes[0].style.display = "block"
                    loadButton.current.childNodes[1].style.display = "none"
                    setVisits([...visits,...response.data.visits])

                }else{
                    loadButton.current.childNodes[0].style.display = "block"
                    loadButton.current.childNodes[1].style.display = "none"
                }
            }).catch(()=>{
                loadButton.current.childNodes[0].style.display = "block"
                loadButton.current.childNodes[1].style.display = "none"
            })
        }
    }
    let Naira = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
      });
    return(
        <div className="admin-views-container">
        <div className="row">
            <div className="col-md-12">
                    <div className="inner-admin-container">
                    <br/>
                    <h3>Visit Reservations</h3>
                    <hr/>
                    <input type="text" placeholder="search"/>
                    <div className="admin-tables-box">
                    <table className="table table-striped table-hover">
                    <thead>
                                <tr>
                                    {/* <th>SN</th> */}
                                <th>Firstname</th>
                                <th>Lastname</th>
                                <th>Phone Number</th>
                                <th>Email Address</th>
                                <th>Number of Access</th>
                                <th>Extra Food</th>
                                <th>Amount Paid</th>
                                <th>Payment Reference</th>
                                <th>Payment Status</th>
                                <th>Visit Package</th>
                                <th>Visit Date</th>
                                </tr>
                            </thead>
                            <tbody>
                            {visits !== null ? visits.map((item) =>{
                                    return <tr key={item.id}>
                                        <td>{item.firstName}</td>
                                        <td>{item.lastName}</td>
                                        <td>{item.phoneNumber}</td>
                                        <td>{item.emailAddress}</td>
                                        <td>{item.numberOfAccess}</td>
                                        <td>{item.extraFood == 1 ? "YES" : "NO"}</td>
                                        <td>{Naira.format(item.Payment.amount)}</td>
                                        <td><span style={{"color":"#41176e","fontWeight":"bold"}}>{item.Payment.paymentReference}</span></td>
                                        <td>
                                        {item.Payment.status === "completed" ? <i className="bi bi-check-circle" style={{"color":"green","fontWeight":"bold"}}> {item.Payment.status}</i>: <span><i className="bi bi-clock" style={{"color":"#574904","fontWeight":"bold"}}> {item.Payment.status}</i></span>}
                                    </td>
                                        <td>{item.VisitPackage.package}</td>
                                        <td>{moment(item.visitDate).format("dddd, MMMM Do YYYY, h:mm:ss a")}</td>
                                    </tr>
                                }) : ''}
                            </tbody>
                        </table>
                        </div>
                        <div ref={loadButton}>
                        <i className="bi bi-arrow-clockwise load-more-button" onClick={loadMore} id="reservationsLoadMore">Load More</i>
                        <div className="spinner-border" role="status" style={{"display":"none"}}><span className="visually-hidden">Loading...</span></div>
                        </div>
                    <br/>
                    <br/>
                    </div>
                    </div>
                    </div>
                    </div>
    )
}


export default VisitReservations