import Modal from 'react-bootstrap/Modal';
import { useState } from "react"

const EventPage = () =>{
    const [showNewEventForm,setShowNewEventForm] = useState(false)

    const showEventModal = () =>{
        setShowNewEventForm(true)
    }
    const hideEventModal = () =>{
        setShowNewEventForm(false)
    }
    return (
        <div className="admin-views-container">
        <div className="row">
            <div className="col-md-12">
            <div className="inner-admin-container">
                <h3>Events Page</h3><hr/>
                <i className="bi bi-plus-circle-fill add-challet-icon" onClick={showEventModal}>New Reservation</i>
                <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    {/* <th>SN</th> */}
                                <th>Event Name</th>
                                <th>Event Data</th>
                                <th>Event Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                </tbody>
                                </table>
                                
            </div>
            </div>
            </div>
            <Modal show={showNewEventForm} onHide={hideEventModal} animation={false}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
                <form>
                    <input type='text' className='form-control' placeholder='Event Name'/>
                </form>
        </Modal.Body>
      </Modal>
            </div>
    )
}

export default EventPage