import { useEffect, useState,useRef } from "react"
import { getRequest } from "../../services/requests"
import cogoToast from "cogo-toast"
import moment from 'moment/moment';
const MealOrder = () =>{

    const loadButton = useRef()
    const [orders,setOrders] = useState(null)
    const [lastRecord,setLastRecord] = useState(null)

    useEffect(() =>{
        fetchOrders()
    },[])
    const fetchOrders = async () =>{

        try {
            let response = await getRequest('fetch-meal-orders')
            if(response.data.status){
                setOrders(response.data.orders)
                let responseData = response.data.orders
                let lastElement = responseData[responseData.length -1]
                setLastRecord(lastElement.id) 
            }else{
                cogoToast.error(response.data.message)
            }
        } catch (error) {
            cogoToast.error(error.response.data.message)
        }
       
    }
    const loadMore = () =>{
        if(lastRecord > 1){
            
            loadButton.current.childNodes[0].style.display = "none"
            loadButton.current.childNodes[1].style.display = "block"

            getRequest('load-more-meal-orders',lastRecord).then((response)=>{
                if(response.data.status === true){
                    loadButton.current.childNodes[0].style.display = "block"
                    loadButton.current.childNodes[1].style.display = "none"
                    setOrders([...orders,...response.data.orders])

                }else{
                    loadButton.current.childNodes[0].style.display = "block"
                    loadButton.current.childNodes[1].style.display = "none"
                }
            }).catch(()=>{
                loadButton.current.childNodes[0].style.display = "block"
                loadButton.current.childNodes[1].style.display = "none"
            })
        }
    }
    let Naira = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
      });
    return (
        <div className="admin-views-container">
        <div className="row">
            <div className="col-md-12">
            <div className="inner-admin-container">
            <br/>
                    <h3>Meal Orders</h3>
                    <hr/>
                    <input type="text" placeholder="search"/>
                    <div className="admin-tables-box">
                    <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    {/* <th>SN</th> */}
                                <th>Item Ordered</th>
                                <th>Delivery Address</th>
                                <th>Order Status</th>
                                <th>Phone Number</th>
                                <th>Payment Reference</th>
                                <th>Amount</th>
                                <th>Date Inititated</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders !== null ? orders.map((item) =>{
                                    return <tr>
                                        <td>{item.FoodMenu.title}</td>
                                        <td>{item.deliveryAddress}</td>
                                        <td>{item.orderStatus == 0 ? <span style={{"fontWeight":"bold","color":"blue"}}>INCOMPLETE</span> : <span style={{"fontWeight":"bold","color":"green"}}>COMPLETED</span>}</td>
                                        <td>{item.contactPhoneNumber}</td>
                                        <td>{item.Payment.paymentReference}</td>
                                        <td>{Naira.format(item.Payment.amount)}</td>
                                        <td>{moment(item.createdAt).format("dddd, MMMM Do YYYY, h:mm:ss a")}</td>
                                    </tr>
                                }) : ''}
                                </tbody>
                                </table>
                        </div>
                        <div ref={loadButton}>
                        <i className="bi bi-arrow-clockwise load-more-button" onClick={loadMore} id="reservationsLoadMore">Load More</i>
                        <div className="spinner-border" role="status" style={{"display":"none"}}><span className="visually-hidden">Loading...</span></div>
                        </div>
                </div>
                </div>
                </div>
                </div>
    )
}

export default MealOrder