import React from "react"
import { useState,useRef } from "react"
import logo from '../../images/logo.png'
import { postRequest } from "../../services/requests"
import cogoToast from "cogo-toast"
import { useNavigate } from "react-router-dom"

const AdminIndex = () =>{
    const navigate = useNavigate()
    const buttonRef = useRef()
    const [formData,setFormData] = useState({
        "emailAddress":"",
        "password":""
    })

    const handleChange = (e) =>{
        const value = e.target.value
        setFormData({
            ...formData,
            [e.target.name]:value
        })
    }

    const login = async (e) =>{
        e.preventDefault()
       
        if(formData.emailAddress === null || formData.emailAddress === ""){
            cogoToast.error("Please enter your email address.",{
                position:'bottom-right'
                         }); 
        }else if(formData.password === null || formData.password === ""){
            cogoToast.error("Please enter your password.",{
                position:'bottom-right'
                         }); 
        }else{
            buttonRef.current.innerHTML = '<div className="spinner-border-sm spinner-border" role="status"><span className="visually-hidden">Loading...</span></div>'
            buttonRef.current.setAttribute('disabled',true)
            try {
                let login = await postRequest('admin-login',formData)
                if(login.data.status === true){
                    cogoToast.success(login.data.message,{
                        position:'bottom-right'
                                 }); 
    
                                 setTimeout(()=>{
                                    navigate("/admin/dashboard/")
                                },3000)
                }else{
                    cogoToast.error(login.data.message,{
                        position:'bottom-right'
                                 });
                                 buttonRef.current.innerHTML = "Login"
                                 buttonRef.current.removeAttribute('disabled')
                }
             
            } catch (error) {
                cogoToast.error("An Error has occured",{
                    position:'bottom-right'
                             });
                buttonRef.current.innerHTML = "Login"
                buttonRef.current.removeAttribute('disabled')
            }
        }
       
    }
    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                            <div className="admin-landing-page">
                            <div className="row">
                        <div className="col-md-4">

                        </div>
                    <div className="col-md-4">
                        <img src={logo} alt="logo" id="admin-landing-logo"/>
                        <div style={{"width":"100%"}}>
                        <div className="admin-login-div">
                            <form onSubmit={login}>
                                <input type='text' name="emailAddress" onChange={handleChange} className="form-control" placeholder="Email Address"/>
                                <br/>
                                <input type='password' name="password" onChange={handleChange} className="form-control" placeholder="Password"/>
                                <br/>
                                <button className="small-button" ref={buttonRef}>Login</button>
                            </form>
                        </div>
                        </div>
                    </div>
                        <div className="col-md-4">

                        </div>
                </div>
                            </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AdminIndex